import React, { useEffect, useState } from "react";
import theme from "../theme";
import { Theme, Text, Section, Box, Image, LinkBox } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "../global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
// import Nav from "../components/Nav";
import Footer from "../components/Footer";
import Header from "../components/Header";

import { useDispatch, useSelector } from "react-redux/es/exports";
import { useParams } from "react-router-dom";
import { getAllPortfolio } from "../redux/Portfolio/portfolio-action";
//CMS
import ContentUpdate from "../components/CMS/ContentUpdate";
import { setSelectedData } from "../redux/CMS/cms-actions";
import {
  getSubCategory,
  selectedPortfolio,
} from "../redux/Product/product-action";
export default () => {
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const [mounted, setMounted] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [selectedData, updateSelectedData] = useState("update");
  let data = useSelector((state) => state.cms.pages["Product Listing"]);

  // let portfolioData = useSelector((state) => state.portfolio.Portfolio);
  let categoryData = useSelector((state) => state.product.addSingleSubCategory);

  // function showPortfolio(data) {
  //   dispatch(addSingleProduct(data));
  //   console.log(data, "SINGLE");
  // }
  const { token } = useParams();

  if (token) {
    let cmsStyle = document.getElementsByClassName("hoverText");

    for (let i = 0; i < cmsStyle.length; i++) {
      cmsStyle[i].onClick = function () {
        return false;
      };
      cmsStyle[i].classList.add("hoverText1");
    }
  }

  async function isSideBarOpen(id) {
    if (token) {
      await updateSelectedData(id);
      // selectedData = id;
      setIsShow(true);

      console.log(isShow, selectedData, id, "show");
    }
  }

  function closeSidebar(value) {
    setIsShow(value);
    updateSelectedData("");
    // selectedData = "";
    dispatch(setSelectedData({}));
    console.log(selectedData, "show");
  }

  useEffect(() => {
    setMounted(true);
    dispatch(getAllPortfolio());
    dispatch(getSubCategory());
  }, [dispatch, setMounted]);

  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"product-list"} />
      <Helmet>
        <title>SCV-Engineering</title>
        <meta
          name={"description"}
          content={"Web site created using quarkly.io"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://uploads.quarkly.io/readme/cra/favicon-32x32.ico"}
          type={"image/x-icon"}
        />
      </Helmet>
      <Header />
      {/* <Nav>
        <Override
          slot="text2"
          color="--primary"
          font="700 16px --fontFamily-googlePoppins"
        />
        <Override
          slot="text"
          color="--grey"
          font="500 16px --fontFamily-googlePoppins"
        />
      </Nav> */}
      <Section
        padding="0px 0 0px 0"
        background="linear-gradient(0deg,rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%),rgba(0, 0, 0, 0) url(https://images.unsplash.com/photo-1455165814004-1126a7199f9b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000) 0% 0% /auto repeat scroll padding-box"
        height="350px"
        sm-height="300px"
      >
        <Override
          slot="SectionContent"
          width="100%"
          margin="0px 0px 0px 0px"
          max-width="100%"
          justify-content="center"
          align-items="center"
          background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Frame%201000004059.png?v=2023-08-11T08:10:12.902Z) 0% 0% /100% repeat scroll padding-box"
          id="2800259478"
        />
        <Text
          font="--headline1"
          color="--white"
          text-align="center"
          sm-font="--headline2"
          id="8707874733"
          className="hoverText"
          onClick={() => isSideBarOpen("1178017474")}
        >
          {data ? data["1178017474"] : "Products"}
        </Text>
      </Section>
      <Section
        quarkly-title="Product"
        align-items="center"
        justify-content="center"
        position="static"
        md-align-items="flex-start"
        md-justify-content="flex-start"
        md-height="fit-content"
      >
        <Override
          slot="SectionContent"
          display="block"
          sm-padding="14px 14px 14px 14px"
          lg-padding="0px 0px 0px 0px"
          md-padding="24px 24px 24px 24px"
          md-margin="60px auto 60px auto"
          sm-margin="0px 0px 0px 0px"
          md-display="flex"
          md-align-items="flex-start"
          md-height="fit-content"
          lg-margin="60px auto 0px auto"
          width="100%"
        />
        <Box
          margin="0px 0px 0px 0px"
          sm-height="fit-content"
          sm-width="fit-content"
          lg-width="100%"
          quarkly-title="Headphones For You"
          md-width="fit-content"
          md-display="flex"
          md-flex-direction="column"
          md-height="fit-content"
        >
          <Box
            display="flex"
            justify-content="space-between"
            md-flex-direction="column"
            md-margin="0px 0px 50px 0px"
          >
            <Box
              min-width="100px"
              lg-min-height="100px"
              md-min-height="100px"
              sm-min-height="50px"
            >
              <Text
                margin="0px 0px 2px 0px"
                font="normal 600 18px/26px --fontFamily-googlePoppins"
                sm-font="normal 600 14px/26px --fontFamily-googlePoppins"
                color="--darkGrey"
                text-align="left"
                id="6680949123"
                className="hoverText"
                onClick={() => isSideBarOpen("6680949123")}
              >
                {data ? data["6680949123"] : "Products"}
              </Text>
              <Text
                margin="0px 0px 50px 0px"
                sm-margin="0px 0px 20px 0px"
                font="normal 600 36px/130% --fontFamily-googlePoppins"
                sm-font-size="24px"
                color="--primary"
                text-align="left"
                md-margin="0px 0px 20px 0px"
                id="7391851525"
                className="hoverText"
                onClick={() => isSideBarOpen("7391851525")}
              >
                {data ? data["7391851525"] : "Expert Solutions with Experties"}
              </Text>
            </Box>

            {/* <Box
              display="flex"
              align-items="center"
              padding="10px 14px 10px 14px"
              height="50px"
              md-width="100%"
              md-margin="0px 20px 0px 0px"
              background="#e1e1e1"
              width="257px"
              sm-width="95%"
            >
              <Icon
                category="io"
                icon={IoIosSearch}
                color="#b5b5b5"
                size="28px"
              />
              <Input
                display="block"
                placeholder-color="#b5b5b5"
                border-style="none"
                placeholder="Search"
                padding="6px 10px 6px 10px"
                background="#e1e1e1"
                font="normal 500 18px/26px --fontFamily-googlePoppins"
                placeholder-font="normal 500 18px/26px --fontFamily-googlePoppins"
                width="100%"
                id="2757090879"
              />
            </Box> */}
          </Box>
          <Box
            display="grid"
            align-items="start"
            grid-gap="24px"
            grid-template-columns="repeat(3, 1fr)"
            sm-grid-template-rows="repeat(1, 1fr)"
            sm-grid-template-columns="repeat(1, 1fr)"
            md-grid-gap="16px"
            md-grid-template-columns="repeat(2, 1fr)"
            lg-width="100%"
            lg-grid-template-rows="repeat(2, 1fr)"
            lg-grid-template-columns="repeat(2, 1fr)"
            align-content="start"
            md-display="grid"
            md-justify-content="flex-start"
            md-width="fit-content"
            sm-width="100%"
          >
            {categoryData.result && categoryData.result.length > 0
              ? categoryData.result.map((item, i) => {
                  return (
                    <LinkBox
                      display="block"
                      key={i}
                      quarkly-title="Product Card"
                      padding="16px 16px 16px 16px"
                      width="100%"
                      overflow-y="hidden"
                      border-width="1px"
                      border-style="solid"
                      border-color="--color-grey"
                      overflow-x="hidden"
                      href="/product-overview"
                      onClick={() => dispatch(selectedPortfolio(item))}
                    >
                      <Image
                        src={
                          item.image
                            ? process.env.REACT_APP_STORAGE_URL_2 + item.image
                            : "https://uploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Group%205565.png?v=2023-08-31T07:43:44.271Z"
                        }
                        alt="image"
                        display="block"
                        margin="0px 60px 0px 0px"
                        padding="0px 0px 0px 0px"
                        height="305px"
                        sm-height="215px"
                        sm-width="100%"
                        sm-margin="0px 60px 0px 0px"
                        md-width="100%"
                        md-margin="0px 60px 0px 0px"
                        width="100%"
                        object-fit="cover"
                        sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
                      />
                      <Box
                        display="flex"
                        align-items="flex-start"
                        flex-direction="column"
                        justify-content="flex-end"
                        padding="16px 0px 0px 0px"
                      >
                        <Text
                          margin="0px 0px 4px 0px"
                          color="--darkL1"
                          font="--headline4"
                          height="fit-content"
                          width="100%"
                        >
                          {item.name}
                        </Text>
                        <Text
                          margin="0px 0px 4px 0px"
                          color="--darkL1"
                          font="--descirption3"
                          height="fit-content"
                          width="100%"
                        >
                          {item.description}
                        </Text>
                      </Box>
                    </LinkBox>
                  );
                })
              : "no-data"}
            {/* <LinkBox
              display="block"
              quarkly-title="Product Card"
              padding="16px 16px 16px 16px"
              width="100%"
              overflow-y="hidden"
              border-width="1px"
              border-style="solid"
              border-color="--color-grey"
              overflow-x="hidden"
              href="/product-overview"
            >
              <Image
                src="https://uploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Group%205565.png?v=2023-08-31T07:43:44.271Z"
                display="block"
                margin="0px 60px 0px 0px"
                padding="0px 0px 0px 0px"
                height="305px"
                sm-width="100%"
                sm-margin="0px 60px 0px 0px"
                md-width="100%"
                md-margin="0px 60px 0px 0px"
                width="100%"
                object-fit="contain"
                srcSet="https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Group%205565.png?v=2023-08-31T07%3A43%3A44.271Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Group%205565.png?v=2023-08-31T07%3A43%3A44.271Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Group%205565.png?v=2023-08-31T07%3A43%3A44.271Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Group%205565.png?v=2023-08-31T07%3A43%3A44.271Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Group%205565.png?v=2023-08-31T07%3A43%3A44.271Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Group%205565.png?v=2023-08-31T07%3A43%3A44.271Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Group%205565.png?v=2023-08-31T07%3A43%3A44.271Z&quality=85&w=3200 3200w"
                sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
              />
              <Box
                display="flex"
                align-items="flex-start"
                flex-direction="column"
                justify-content="flex-end"
                padding="16px 0px 0px 0px"
              >
                <Text
                  margin="0px 0px 4px 0px"
                  color="--darkL1"
                  font="--headline4"
                  height="fit-content"
                  width="100%"
                >
                  Filtration Product{"\n\n"}
                </Text>
                <Text
                  margin="0px 0px 4px 0px"
                  color="--darkL1"
                  font="--descirption3"
                  height="fit-content"
                  width="100%"
                >
                  Introducing our OTR & Mining product – built for extreme
                  durability and high performance. Designed to excel in the
                  toughest conditions, it prioritizes safety and efficiency.
                </Text>
              </Box>
            </LinkBox> */}
            {/* <Box
              display="block"
              quarkly-title="Product Card"
              padding="16px 16px 16px 16px"
              width="100%"
              overflow-y="hidden"
              border-width="1px"
              border-style="solid"
              border-color="--color-grey"
              overflow-x="hidden"
            >
              <Image
                src="https://uploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group-1.png?v=2023-08-31T07:43:33.181Z"
                display="block"
                margin="0px 60px 0px 0px"
                padding="0px 0px 0px 0px"
                height="305px"
                sm-width="100%"
                sm-margin="0px 60px 0px 0px"
                md-width="100%"
                md-margin="0px 60px 0px 0px"
                width="100%"
                object-fit="cover"
                srcSet="https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group-1.png?v=2023-08-31T07%3A43%3A33.181Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group-1.png?v=2023-08-31T07%3A43%3A33.181Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group-1.png?v=2023-08-31T07%3A43%3A33.181Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group-1.png?v=2023-08-31T07%3A43%3A33.181Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group-1.png?v=2023-08-31T07%3A43%3A33.181Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group-1.png?v=2023-08-31T07%3A43%3A33.181Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group-1.png?v=2023-08-31T07%3A43%3A33.181Z&quality=85&w=3200 3200w"
                sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
              />
              <Box
                display="flex"
                align-items="flex-start"
                flex-direction="column"
                justify-content="flex-end"
                padding="16px 0px 0px 0px"
              >
                <Text
                  margin="0px 0px 4px 0px"
                  color="--darkL1"
                  font="--headline4"
                  height="fit-content"
                  width="100%"
                >
                  Pipe Fitting{"\n\n"}
                </Text>
                <Text
                  margin="0px 0px 4px 0px"
                  color="--darkL1"
                  font="--descirption3"
                  height="fit-content"
                  width="100%"
                >
                  Introducing our OTR & Mining product – built for extreme
                  durability and high performance. Designed to excel in the
                  toughest conditions, it prioritizes safety and efficiency.
                </Text>
              </Box>
            </Box>
            <Box
              display="block"
              quarkly-title="Product Card"
              padding="16px 16px 16px 16px"
              width="100%"
              overflow-y="hidden"
              border-width="1px"
              border-style="solid"
              border-color="--color-grey"
              overflow-x="hidden"
            >
              <Image
                src="https://uploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group-3.png?v=2023-08-31T07:51:35.567Z"
                display="block"
                margin="0px 60px 0px 0px"
                padding="0px 0px 0px 0px"
                height="305px"
                sm-width="100%"
                sm-margin="0px 60px 0px 0px"
                md-width="100%"
                md-margin="0px 60px 0px 0px"
                width="100%"
                object-fit="cover"
                srcSet="https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group-3.png?v=2023-08-31T07%3A51%3A35.567Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group-3.png?v=2023-08-31T07%3A51%3A35.567Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group-3.png?v=2023-08-31T07%3A51%3A35.567Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group-3.png?v=2023-08-31T07%3A51%3A35.567Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group-3.png?v=2023-08-31T07%3A51%3A35.567Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group-3.png?v=2023-08-31T07%3A51%3A35.567Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group-3.png?v=2023-08-31T07%3A51%3A35.567Z&quality=85&w=3200 3200w"
                sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
              />
              <Box
                display="flex"
                align-items="flex-start"
                flex-direction="column"
                justify-content="flex-end"
                padding="16px 0px 0px 0px"
              >
                <Text
                  margin="0px 0px 4px 0px"
                  color="--darkL1"
                  font="--headline4"
                  height="fit-content"
                  width="100%"
                >
                  Hydraulic Components{"\n\n"}
                </Text>
                <Text
                  margin="0px 0px 4px 0px"
                  color="--darkL1"
                  font="--descirption3"
                  height="fit-content"
                  width="100%"
                >
                  Introducing our OTR & Mining product – built for extreme
                  durability and high performance. Designed to excel in the
                  toughest conditions, it prioritizes safety and efficiency.
                </Text>
              </Box>
            </Box>
            <Box
              display="block"
              quarkly-title="Product Card"
              padding="16px 16px 16px 16px"
              width="100%"
              overflow-y="hidden"
              border-width="1px"
              border-style="solid"
              border-color="--color-grey"
              overflow-x="hidden"
            >
              <Image
                src="https://uploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group.png?v=2023-08-31T07:43:21.077Z"
                display="block"
                margin="0px 60px 0px 0px"
                padding="0px 0px 0px 0px"
                height="305px"
                sm-width="100%"
                sm-margin="0px 60px 0px 0px"
                md-width="100%"
                md-margin="0px 60px 0px 0px"
                width="100%"
                object-fit="contain"
                srcSet="https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group.png?v=2023-08-31T07%3A43%3A21.077Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group.png?v=2023-08-31T07%3A43%3A21.077Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group.png?v=2023-08-31T07%3A43%3A21.077Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group.png?v=2023-08-31T07%3A43%3A21.077Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group.png?v=2023-08-31T07%3A43%3A21.077Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group.png?v=2023-08-31T07%3A43%3A21.077Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group.png?v=2023-08-31T07%3A43%3A21.077Z&quality=85&w=3200 3200w"
                sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
              />
              <Box
                display="flex"
                align-items="flex-start"
                flex-direction="column"
                justify-content="flex-end"
                padding="16px 0px 0px 0px"
              >
                <Text
                  margin="0px 0px 4px 0px"
                  color="--darkL1"
                  font="--headline4"
                  height="fit-content"
                  width="100%"
                >
                  Oil condition monitor
                </Text>
                <Text
                  margin="0px 0px 4px 0px"
                  color="--darkL1"
                  font="--descirption3"
                  height="fit-content"
                  width="100%"
                >
                  Introducing our OTR & Mining product – built for extreme
                  durability and high performance. Designed to excel in the
                  toughest conditions, it prioritizes safety and efficiency.
                </Text>
              </Box>
            </Box>
            <Box
              display="block"
              quarkly-title="Product Card"
              padding="16px 16px 16px 16px"
              width="100%"
              overflow-y="hidden"
              border-width="1px"
              border-style="solid"
              border-color="--color-grey"
              overflow-x="hidden"
            >
              <Image
                src="https://uploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Group%205564.png?v=2023-08-31T07:43:44.272Z"
                display="block"
                margin="0px 60px 0px 0px"
                padding="0px 0px 0px 0px"
                height="305px"
                sm-width="100%"
                sm-margin="0px 60px 0px 0px"
                md-width="100%"
                md-margin="0px 60px 0px 0px"
                width="100%"
                object-fit="cover"
                srcSet="https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Group%205564.png?v=2023-08-31T07%3A43%3A44.272Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Group%205564.png?v=2023-08-31T07%3A43%3A44.272Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Group%205564.png?v=2023-08-31T07%3A43%3A44.272Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Group%205564.png?v=2023-08-31T07%3A43%3A44.272Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Group%205564.png?v=2023-08-31T07%3A43%3A44.272Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Group%205564.png?v=2023-08-31T07%3A43%3A44.272Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Group%205564.png?v=2023-08-31T07%3A43%3A44.272Z&quality=85&w=3200 3200w"
                sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
              />
              <Box
                display="flex"
                align-items="flex-start"
                flex-direction="column"
                justify-content="flex-end"
                padding="16px 0px 0px 0px"
              >
                <Text
                  margin="0px 0px 4px 0px"
                  color="--darkL1"
                  font="--headline4"
                  height="fit-content"
                  width="100%"
                >
                  Tube fittings &Valves
                </Text>
                <Text
                  margin="0px 0px 4px 0px"
                  color="--darkL1"
                  font="--descirption3"
                  height="fit-content"
                  width="100%"
                >
                  Introducing our OTR & Mining product – built for extreme
                  durability and high performance. Designed to excel in the
                  toughest conditions, it prioritizes safety and efficiency.
                </Text>
              </Box>
            </Box>
            <Box
              display="block"
              quarkly-title="Product Card"
              padding="16px 16px 16px 16px"
              width="100%"
              overflow-y="hidden"
              border-width="1px"
              border-style="solid"
              border-color="--color-grey"
              overflow-x="hidden"
            >
              <Image
                src="https://uploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group-2.png?v=2023-08-31T07:43:33.192Z"
                display="block"
                margin="0px 60px 0px 0px"
                padding="0px 0px 0px 0px"
                height="305px"
                sm-width="100%"
                sm-margin="0px 60px 0px 0px"
                md-width="100%"
                md-margin="0px 60px 0px 0px"
                width="100%"
                object-fit="cover"
                srcSet="https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group-2.png?v=2023-08-31T07%3A43%3A33.192Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group-2.png?v=2023-08-31T07%3A43%3A33.192Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group-2.png?v=2023-08-31T07%3A43%3A33.192Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group-2.png?v=2023-08-31T07%3A43%3A33.192Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group-2.png?v=2023-08-31T07%3A43%3A33.192Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group-2.png?v=2023-08-31T07%3A43%3A33.192Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group-2.png?v=2023-08-31T07%3A43%3A33.192Z&quality=85&w=3200 3200w"
                sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
              />
              <Box
                display="flex"
                align-items="flex-start"
                flex-direction="column"
                justify-content="flex-end"
                padding="16px 0px 0px 0px"
              >
                <Text
                  margin="0px 0px 4px 0px"
                  color="--darkL1"
                  font="--headline4"
                  height="fit-content"
                  width="100%"
                >
                  Pneumatic Components
                </Text>
                <Text
                  margin="0px 0px 4px 0px"
                  color="--darkL1"
                  font="--descirption3"
                  height="fit-content"
                  width="100%"
                >
                  Introducing our OTR & Mining product – built for extreme
                  durability and high performance. Designed to excel in the
                  toughest conditions, it prioritizes safety and efficiency.
                </Text>
              </Box>
            </Box> */}
          </Box>
        </Box>
      </Section>
      <Footer>
        <Override slot="SectionContent" flex-direction="column" />
      </Footer>
      {isShow ? (
        <ContentUpdate
          selectedElement={selectedData}
          isShow={isShow}
          closeSidebar={closeSidebar}
        />
      ) : (
        ""
      )}
      <RawHtml>
        <style place={"endOfHead"} rawKey={"64d4bf2f6efcea001ae4fe4d"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  );
};
