import React, { useEffect, useState } from "react";
import theme from "../theme";
import { Theme, Text, Section, Image, Box } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "../global-page-styles";
import { RawHtml, Override } from "@quarkly/components";

import Footer from "../components/Footer";
import Header from "../components/Header";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { useParams } from "react-router-dom";
//CMS
import ContentUpdate from "../components/CMS/ContentUpdate";
import { setSelectedData } from "../redux/CMS/cms-actions";

export default () => {
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const [mounted, setMounted] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [selectedData, updateSelectedData] = useState("update");
  let data = useSelector((state) => state.cms.pages["About"]);

  const { token } = useParams();

  if (token) {
    let cmsStyle = document.getElementsByClassName("hoverText");

    for (let i = 0; i < cmsStyle.length; i++) {
      cmsStyle[i].onClick = function () {
        return false;
      };
      cmsStyle[i].classList.add("hoverText1");
    }
  }

  async function isSideBarOpen(id) {
    if (token) {
      await updateSelectedData(id);
      // selectedData = id;
      setIsShow(true);

      console.log(isShow, selectedData, id, "show");
    }
  }

  function closeSidebar(value) {
    setIsShow(value);
    updateSelectedData("");
    // selectedData = "";
    dispatch(setSelectedData({}));
    console.log(selectedData, "show");
  }

  useEffect(() => {
    setMounted(true);
  }, [dispatch, setMounted]);
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"index1"} />
      <Helmet>
        <title>SCV-Engineering</title>
        <meta
          name={"description"}
          content={"Web site created using quarkly.io"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://uploads.quarkly.io/readme/cra/favicon-32x32.ico"}
          type={"image/x-icon"}
        />
      </Helmet>
      <Header />
      {/* <Nav>
        <Override slot="linkBox1" />
        <Override slot="linkBox2" font="300 16px sans-serif" />
        <Override slot="linkBox3" />
        <Override slot="linkBox4" />
        <Override slot="linkBox5" />
        <Override
          slot="text1"
          color="--primary"
          font="700 16px --fontFamily-googlePoppins"
        />
        <Override
          slot="text"
          font="500 16px --fontFamily-googlePoppins"
          border-color="--color-grey"
          color="--grey"
        />
      </Nav> */}
      <Section
        padding="0px 0 0px 0"
        background="linear-gradient(0deg,rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%),rgba(0, 0, 0, 0) url(https://images.unsplash.com/photo-1455165814004-1126a7199f9b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000) 0% 0% /auto repeat scroll padding-box"
        height="350px"
        id="5919385231"
      >
        <Override
          slot="SectionContent"
          width="100%"
          margin="0px 0px 0px 0px"
          max-width="100%"
          justify-content="center"
          align-items="center"
          background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Frame%201000004059.png?v=2023-08-11T08:10:12.902Z) 0% 0% /100% repeat scroll padding-box"
        />
        <Text
          font="--headline1"
          color="--white"
          text-align="center"
          sm-font="--headline4"
          letter-spacing="2px"
          id="4828081086"
          className="hoverText"
          onClick={() => isSideBarOpen("4828081086")}
        >
          {data ? data["4828081086"] : "ABOUT US"}
        </Text>
      </Section>
      <Section
        padding="48px 0 48px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="About-14"
        background="#ffffff"
      >
        <Override
          slot="SectionContent"
          flex-direction="row"
          flex-wrap="wrap"
          sm-min-width="280px"
          background="--color-primary"
          height="540px"
          lg-height="fit-content"
        />
        <Box
          display="flex"
          width="40%"
          justify-content="flex-start"
          lg-width="100%"
          align-items="flex-start"
          lg-margin="0px 0px 0px 0px"
          margin="0px 0px 0px 0px"
          lg-padding="0px 0px 0px 0px"
          lg-justify-content="center"
          height="100%"
          lg-height="400px"
          sm-height="390px"
        >
          <Image
            src="https://uploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Group.png?v=2023-08-31T08:02:02.304Z"
            alt="image"
            object-fit="cover"
            transform="translateY(0px)"
            transition="transform 0.2s ease-in-out 0s"
            hover-transform="translateY(-10px)"
            height="100%"
            width="100%"
            id="0794926805"
            srcSet="https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Group.png?v=2023-08-31T08%3A02%3A02.304Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Group.png?v=2023-08-31T08%3A02%3A02.304Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Group.png?v=2023-08-31T08%3A02%3A02.304Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Group.png?v=2023-08-31T08%3A02%3A02.304Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Group.png?v=2023-08-31T08%3A02%3A02.304Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Group.png?v=2023-08-31T08%3A02%3A02.304Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Group.png?v=2023-08-31T08%3A02%3A02.304Z&quality=85&w=3200 3200w"
            sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
          />
        </Box>
        <Box
          display="flex"
          width="60%"
          flex-direction="column"
          align-items="flex-start"
          lg-width="100%"
          lg-align-items="flex-start"
          lg-margin="0px 0px 0px 0px"
          padding="48px 64px 48px 64px"
          justify-content="center"
          background="--color-lightD1"
          md-padding="36px 40px 48px 40px"
          sm-padding="36px 24px 48px 24px"
        >
          <Text
            margin="0px 0px 2px 0px"
            font="--subheader1"
            color="--darkGrey"
            text-align="center"
            sm-font="--descirption3"
            id="7484581822"
            className="hoverText"
            onClick={() => isSideBarOpen("7484581822")}
          >
            {data ? data["7484581822"] : "Services"}
          </Text>
          <Text
            margin="0px 0px 20px 0px"
            font="--headline2"
            color="--primary"
            text-align="center"
            height="50px"
            sm-font="--headline4"
            id="8257811291"
            className="hoverText"
            onClick={() => isSideBarOpen("8257811291")}
          >
            {data ? data["8257811291"] : "About SCV Engineering"}
          </Text>
          <Text
            margin="0px 0px 2px 0px"
            font="--description1"
            color="--darkGrey"
            text-align="left"
            sm-font="--descirption3"
            id="4757050916"
            className="hoverText "
            dangerouslySetInnerHTML={{
              __html: data ? data["4757050916"] : "We at SCV Engineering are",
            }}
            onClick={() => isSideBarOpen("4757050916")}
          ></Text>
        </Box>
      </Section>
      {/* <Section
        background="#ffffff"
        padding="90px 0 110px 0"
        quarkly-title="Team-13"
      >
        <Box
          min-width="100px"
          min-height="100px"
          display="flex"
          grid-template-columns="repeat(2, 1fr)"
          lg-flex-direction="column"
        >
          <Box
            min-width="100px"
            min-height="100px"
            padding="0px 80px 0px 0px"
            width="40%"
            lg-padding="0px 50px 0px 0px"
            lg-width="100%"
            lg-margin="0px 0px 50px 0px"
            sm-margin="0px 0px 35px 0px"
            color="--greyD2"
            sm-padding="0px 0 0px 0px"
            md-padding="0px 0 0px 0px"
          >
            <Text
              margin="0px 0px 2px 0px"
              font="--subheader1"
              color="--darkGrey"
              text-align="left"
              sm-font="--descirption3"
              id="5941198303"
              className="hoverText"
              onClick={() => isSideBarOpen("5941198303")}
            >
              {data ? data["5941198303"] : "Teams"}
            </Text>
            <Text
              margin="0px 0px 20px 0px"
              font="--headline2"
              color="--primary"
              text-align="left"
              sm-font="--headline4"
              id="0591826293"
              className="hoverText"
              onClick={() => isSideBarOpen("0591826293")}
            >
              {data ? data["0591826293"] : "Expert Solutions with Experties"}
            </Text>
            <Text
              color="--greyD2"
              font="--descirption3"
              id="1192462326"
              className="hoverText "
              dangerouslySetInnerHTML={{
                __html: data
                  ? data["1192462326"]
                  : "We are united by our passion",
              }}
              onClick={() => isSideBarOpen("1192462326")}
            ></Text>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            width="60%"
            display="grid"
            flex-direction="column"
            lg-width="100%"
            grid-template-columns="repeat(2, 1fr)"
            grid-template-rows="repeat(3, 1fr)"
            grid-gap="46px 24px"
            sm-grid-template-columns="1fr"
            sm-grid-template-rows="auto"
            sm-grid-gap="36px 0"
          >
            <Box
              min-width="100px"
              min-height="100px"
              sm-display="flex"
              sm-flex-direction="row"
              display="flex"
              flex-direction="row"
            >
              <Box
                min-width="100px"
                min-height="100px"
                sm-margin="0px 0px 0 0px"
              >
                <Image
                  src="https://uploads.quarkly.io/643d8e6ebf72b300207d7233/images/man-isolated-showing-facial-emotions.jpg?v=2023-04-18T19:20:05.237Z"
                  display="block"
                  width="100px"
                  border-radius="50%"
                  lg-max-width="100%"
                  max-width="100%"
                  object-fit="cover"
                  max-height="100%"
                  height="100px"
                  srcSet="https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/man-isolated-showing-facial-emotions.jpg?v=2023-04-18T19%3A20%3A05.237Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/man-isolated-showing-facial-emotions.jpg?v=2023-04-18T19%3A20%3A05.237Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/man-isolated-showing-facial-emotions.jpg?v=2023-04-18T19%3A20%3A05.237Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/man-isolated-showing-facial-emotions.jpg?v=2023-04-18T19%3A20%3A05.237Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/man-isolated-showing-facial-emotions.jpg?v=2023-04-18T19%3A20%3A05.237Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/man-isolated-showing-facial-emotions.jpg?v=2023-04-18T19%3A20%3A05.237Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/man-isolated-showing-facial-emotions.jpg?v=2023-04-18T19%3A20%3A05.237Z&quality=85&w=3200 3200w"
                  sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
                />
              </Box>
              <Box
                min-width="100px"
                min-height="100px"
                display="flex"
                flex-direction="column"
                justify-content="center"
                sm-width="100%"
                sm-padding="0px 0px 0px 0"
                margin="0 0px 0px 25px"
                sm-margin="0 0px 0px 18px"
              >
                <Text
                  color="--dark"
                  font="--subheader2"
                  lg-margin="0 0px 0px 0px"
                  margin="0 0px 0 0px"
                  sm-font="--description2"
                >
                  Michael Foster
                </Text>
                <Text
                  margin="8px 0px 0px 0px"
                  color="--primary"
                  font="--descirption3"
                >
                  Co-Founder / CO
                </Text>
              </Box>
            </Box>
            <Box
              min-width="100px"
              min-height="100px"
              sm-display="flex"
              sm-flex-direction="row"
              display="flex"
              flex-direction="row"
            >
              <Box
                min-width="100px"
                min-height="100px"
                sm-margin="0px 0px 0 0px"
              >
                <Image
                  src="https://uploads.quarkly.io/643d8e6ebf72b300207d7233/images/tender-feminine-woman-with-blue-eyes-smiles-pleasantly-has-toothy-smile-wears-white-comfortable-sweater-looks-directly-camera-isolated-pink-background.jpg?v=2023-04-18T18:06:34.340Z"
                  display="block"
                  width="100px"
                  border-radius="50%"
                  lg-max-width="100%"
                  max-width="100%"
                  object-fit="cover"
                  max-height="100%"
                  height="100px"
                  srcSet="https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/tender-feminine-woman-with-blue-eyes-smiles-pleasantly-has-toothy-smile-wears-white-comfortable-sweater-looks-directly-camera-isolated-pink-background.jpg?v=2023-04-18T18%3A06%3A34.340Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/tender-feminine-woman-with-blue-eyes-smiles-pleasantly-has-toothy-smile-wears-white-comfortable-sweater-looks-directly-camera-isolated-pink-background.jpg?v=2023-04-18T18%3A06%3A34.340Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/tender-feminine-woman-with-blue-eyes-smiles-pleasantly-has-toothy-smile-wears-white-comfortable-sweater-looks-directly-camera-isolated-pink-background.jpg?v=2023-04-18T18%3A06%3A34.340Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/tender-feminine-woman-with-blue-eyes-smiles-pleasantly-has-toothy-smile-wears-white-comfortable-sweater-looks-directly-camera-isolated-pink-background.jpg?v=2023-04-18T18%3A06%3A34.340Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/tender-feminine-woman-with-blue-eyes-smiles-pleasantly-has-toothy-smile-wears-white-comfortable-sweater-looks-directly-camera-isolated-pink-background.jpg?v=2023-04-18T18%3A06%3A34.340Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/tender-feminine-woman-with-blue-eyes-smiles-pleasantly-has-toothy-smile-wears-white-comfortable-sweater-looks-directly-camera-isolated-pink-background.jpg?v=2023-04-18T18%3A06%3A34.340Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/tender-feminine-woman-with-blue-eyes-smiles-pleasantly-has-toothy-smile-wears-white-comfortable-sweater-looks-directly-camera-isolated-pink-background.jpg?v=2023-04-18T18%3A06%3A34.340Z&quality=85&w=3200 3200w"
                  sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
                />
              </Box>
              <Box
                min-width="100px"
                min-height="100px"
                display="flex"
                flex-direction="column"
                justify-content="center"
                sm-width="100%"
                sm-padding="0px 0px 0px 0"
                margin="0 0px 0px 25px"
                sm-margin="0 0px 0px 18px"
              >
                <Text
                  color="--dark"
                  font="--subheader2"
                  lg-margin="0 0px 0px 0px"
                  margin="0 0px 0 0px"
                  sm-font="--description2"
                >
                  Emma Dorsey
                </Text>
                <Text
                  margin="8px 0px 0px 0px"
                  color="--primary"
                  font="--descirption3"
                >
                  Co-Founder / CTO
                </Text>
              </Box>
            </Box>
            <Box
              min-width="100px"
              min-height="100px"
              sm-display="flex"
              sm-flex-direction="row"
              display="flex"
              flex-direction="row"
            >
              <Box
                min-width="100px"
                min-height="100px"
                sm-margin="0px 0px 0 0px"
              >
                <Image
                  src="https://uploads.quarkly.io/643d8e6ebf72b300207d7233/images/smiling-young-brunette-caucasian-girl-looks-camera-olive-green.jpg?v=2023-04-18T18:07:43.011Z"
                  display="block"
                  width="100px"
                  border-radius="50%"
                  lg-max-width="100%"
                  max-width="100%"
                  object-fit="cover"
                  max-height="100%"
                  height="100px"
                  srcSet="https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/smiling-young-brunette-caucasian-girl-looks-camera-olive-green.jpg?v=2023-04-18T18%3A07%3A43.011Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/smiling-young-brunette-caucasian-girl-looks-camera-olive-green.jpg?v=2023-04-18T18%3A07%3A43.011Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/smiling-young-brunette-caucasian-girl-looks-camera-olive-green.jpg?v=2023-04-18T18%3A07%3A43.011Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/smiling-young-brunette-caucasian-girl-looks-camera-olive-green.jpg?v=2023-04-18T18%3A07%3A43.011Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/smiling-young-brunette-caucasian-girl-looks-camera-olive-green.jpg?v=2023-04-18T18%3A07%3A43.011Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/smiling-young-brunette-caucasian-girl-looks-camera-olive-green.jpg?v=2023-04-18T18%3A07%3A43.011Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/smiling-young-brunette-caucasian-girl-looks-camera-olive-green.jpg?v=2023-04-18T18%3A07%3A43.011Z&quality=85&w=3200 3200w"
                  sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
                />
              </Box>
              <Box
                min-width="100px"
                min-height="100px"
                display="flex"
                flex-direction="column"
                justify-content="center"
                sm-width="100%"
                sm-padding="0px 0px 0px 0"
                margin="0 0px 0px 25px"
                sm-margin="0 0px 0px 18px"
              >
                <Text
                  color="--dark"
                  font="--subheader2"
                  lg-margin="0 0px 0px 0px"
                  margin="0 0px 0 0px"
                  sm-font="--description2"
                >
                  Alicia Bell
                </Text>
                <Text
                  margin="8px 0px 0px 0px"
                  color="--primary"
                  font="--descirption3"
                >
                  Business Relations
                </Text>
              </Box>
            </Box>
            <Box
              min-width="100px"
              min-height="100px"
              sm-display="flex"
              sm-flex-direction="row"
              display="flex"
              flex-direction="row"
            >
              <Box
                min-width="100px"
                min-height="100px"
                sm-margin="0px 0px 0 0px"
              >
                <Image
                  src="https://uploads.quarkly.io/643d8e6ebf72b300207d7233/images/portrait-positive-cherful-nice-attractive-guy-toothy-beaming-smile-violet-background.jpg?v=2023-04-18T18:08:27.505Z"
                  display="block"
                  width="100px"
                  border-radius="50%"
                  lg-max-width="100%"
                  max-width="100%"
                  object-fit="cover"
                  max-height="100%"
                  height="100px"
                  srcSet="https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/portrait-positive-cherful-nice-attractive-guy-toothy-beaming-smile-violet-background.jpg?v=2023-04-18T18%3A08%3A27.505Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/portrait-positive-cherful-nice-attractive-guy-toothy-beaming-smile-violet-background.jpg?v=2023-04-18T18%3A08%3A27.505Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/portrait-positive-cherful-nice-attractive-guy-toothy-beaming-smile-violet-background.jpg?v=2023-04-18T18%3A08%3A27.505Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/portrait-positive-cherful-nice-attractive-guy-toothy-beaming-smile-violet-background.jpg?v=2023-04-18T18%3A08%3A27.505Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/portrait-positive-cherful-nice-attractive-guy-toothy-beaming-smile-violet-background.jpg?v=2023-04-18T18%3A08%3A27.505Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/portrait-positive-cherful-nice-attractive-guy-toothy-beaming-smile-violet-background.jpg?v=2023-04-18T18%3A08%3A27.505Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/portrait-positive-cherful-nice-attractive-guy-toothy-beaming-smile-violet-background.jpg?v=2023-04-18T18%3A08%3A27.505Z&quality=85&w=3200 3200w"
                  sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
                />
              </Box>
              <Box
                min-width="100px"
                min-height="100px"
                display="flex"
                flex-direction="column"
                justify-content="center"
                sm-width="100%"
                sm-padding="0px 0px 0px 0"
                margin="0 0px 0px 25px"
                sm-margin="0 0px 0px 18px"
              >
                <Text
                  color="--dark"
                  font="--subheader2"
                  lg-margin="0 0px 0px 0px"
                  margin="0 0px 0 0px"
                  sm-font="--description2"
                >
                  Michael Foster
                </Text>
                <Text
                  margin="8px 0px 0px 0px"
                  color="--primary"
                  font="--descirption3"
                >
                  Front-end Developer
                </Text>
              </Box>
            </Box>
            <Box
              min-width="100px"
              min-height="100px"
              sm-display="flex"
              sm-flex-direction="row"
              display="flex"
              flex-direction="row"
            >
              <Box
                min-width="100px"
                min-height="100px"
                sm-margin="0px 0px 0 0px"
              >
                <Image
                  src="https://uploads.quarkly.io/643d8e6ebf72b300207d7233/images/happy-woman-beige-sweater.jpg?v=2023-04-18T19:20:53.770Z"
                  display="block"
                  width="100px"
                  border-radius="50%"
                  lg-max-width="100%"
                  max-width="100%"
                  object-fit="cover"
                  max-height="100%"
                  height="100px"
                  srcSet="https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/happy-woman-beige-sweater.jpg?v=2023-04-18T19%3A20%3A53.770Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/happy-woman-beige-sweater.jpg?v=2023-04-18T19%3A20%3A53.770Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/happy-woman-beige-sweater.jpg?v=2023-04-18T19%3A20%3A53.770Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/happy-woman-beige-sweater.jpg?v=2023-04-18T19%3A20%3A53.770Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/happy-woman-beige-sweater.jpg?v=2023-04-18T19%3A20%3A53.770Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/happy-woman-beige-sweater.jpg?v=2023-04-18T19%3A20%3A53.770Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/happy-woman-beige-sweater.jpg?v=2023-04-18T19%3A20%3A53.770Z&quality=85&w=3200 3200w"
                  sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
                />
              </Box>
              <Box
                min-width="100px"
                min-height="100px"
                display="flex"
                flex-direction="column"
                justify-content="center"
                sm-width="100%"
                sm-padding="0px 0px 0px 0"
                margin="0 0px 0px 25px"
                sm-margin="0 0px 0px 18px"
              >
                <Text
                  color="--dark"
                  font="--subheader2"
                  lg-margin="0 0px 0px 0px"
                  margin="0 0px 0 0px"
                  sm-font="--description2"
                >
                  Courtney Henry
                </Text>
                <Text
                  margin="8px 0px 0px 0px"
                  color="--primary"
                  font="--descirption3"
                >
                  Designer
                </Text>
              </Box>
            </Box>
            <Box
              min-width="100px"
              min-height="100px"
              sm-display="flex"
              sm-flex-direction="row"
              display="flex"
              flex-direction="row"
            >
              <Box
                min-width="100px"
                min-height="100px"
                sm-margin="0px 0px 0 0px"
              >
                <Image
                  src="https://uploads.quarkly.io/643d8e6ebf72b300207d7233/images/european-man-smiling-cheerful-expression-closeup-portrait.jpg?v=2023-04-18T18:03:35.040Z"
                  display="block"
                  width="100px"
                  border-radius="50%"
                  lg-max-width="100%"
                  max-width="100%"
                  object-fit="cover"
                  max-height="100%"
                  height="100px"
                  srcSet="https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/european-man-smiling-cheerful-expression-closeup-portrait.jpg?v=2023-04-18T18%3A03%3A35.040Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/european-man-smiling-cheerful-expression-closeup-portrait.jpg?v=2023-04-18T18%3A03%3A35.040Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/european-man-smiling-cheerful-expression-closeup-portrait.jpg?v=2023-04-18T18%3A03%3A35.040Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/european-man-smiling-cheerful-expression-closeup-portrait.jpg?v=2023-04-18T18%3A03%3A35.040Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/european-man-smiling-cheerful-expression-closeup-portrait.jpg?v=2023-04-18T18%3A03%3A35.040Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/european-man-smiling-cheerful-expression-closeup-portrait.jpg?v=2023-04-18T18%3A03%3A35.040Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/643d8e6ebf72b300207d7233/images/european-man-smiling-cheerful-expression-closeup-portrait.jpg?v=2023-04-18T18%3A03%3A35.040Z&quality=85&w=3200 3200w"
                  sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
                />
              </Box>
              <Box
                min-width="100px"
                min-height="100px"
                display="flex"
                flex-direction="column"
                justify-content="center"
                sm-width="100%"
                sm-padding="0px 0px 0px 0"
                margin="0 0px 0px 25px"
                sm-margin="0 0px 0px 18px"
              >
                <Text
                  color="--dark"
                  font="--subheader2"
                  lg-margin="0 0px 0px 0px"
                  margin="0 0px 0 0px"
                  sm-font="--description2"
                >
                  Tom Cook
                </Text>
                <Text
                  margin="8px 0px 0px 0px"
                  color="--primary"
                  font="--descirption3"
                >
                  Director of Product
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </Section> */}
      <Footer>
        <Override slot="SectionContent" flex-direction="column" />
      </Footer>
      {isShow ? (
        <ContentUpdate
          selectedElement={selectedData}
          isShow={isShow}
          closeSidebar={closeSidebar}
        />
      ) : (
        ""
      )}
      <RawHtml>
        <style place={"endOfHead"} rawKey={"64d4bf2f6efcea001ae4fe4d"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  );
};
