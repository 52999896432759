import React, { useEffect, useState } from "react";

import theme from "../theme";
import { getAllPortfolio } from "../redux/Portfolio/portfolio-action";
import {
  Theme,
  Text,
  Button,
  Box,
  Section,
  Image,
  Icon,
  LinkBox,
} from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "../global-page-styles";
import { RawHtml, Override } from "@quarkly/components";

import EnquireForm from "../components/EnquireForm";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { NavLink, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/swiper.min.css";
//CMS
import ContentUpdate from "../components/CMS/ContentUpdate";
import { setSelectedData } from "../redux/CMS/cms-actions";

import { MdCall, MdEmail } from "react-icons/md";
import Brands from "./components/Brands";
import { getSubCategory } from "../redux/Product/product-action";
export default () => {
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const [mounted, setMounted] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [selectedData, updateSelectedData] = useState("update");
  let data = useSelector((state) => state.cms.pages["Home"]);
  // let portfolioData = useSelector((state) => state.portfolio.Portfolio);
  let categoryData = useSelector((state) => state.product.addSingleSubCategory);

  const { token } = useParams();

  if (token) {
    let cmsStyle = document.getElementsByClassName("hoverText");

    for (let i = 0; i < cmsStyle.length; i++) {
      cmsStyle[i].onClick = function () {
        return false;
      };
      cmsStyle[i].classList.add("hoverText1");
    }
  }

  async function isSideBarOpen(id) {
    if (token) {
      await updateSelectedData(id);
      // selectedData = id;
      setIsShow(true);

      console.log(isShow, selectedData, id, "show");
    }
  }

  function closeSidebar(value) {
    setIsShow(value);
    updateSelectedData("");
    // selectedData = "";
    dispatch(setSelectedData({}));
    console.log(selectedData, "show");
  }

  useEffect(() => {
    setMounted(true);
    dispatch(getAllPortfolio());
    dispatch(getSubCategory());
  }, [dispatch, setMounted]);

  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"index"} />
      <Helmet>
        <title>SCV-Engineering</title>
        <meta
          name={"description"}
          content={"Web site created using quarkly.io"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://uploads.quarkly.io/readme/cra/favicon-32x32.ico"}
          type={"image/x-icon"}
        />
      </Helmet>
      <Header />

      <Section
        height="600px"
        background="linear-gradient(0deg,rgba(0, 33, 71, 0.38) 0%,#002147 100%),rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Group.png?v=2023-08-31T12:38:40.884Z) center/cover repeat scroll padding-box"
        sm-height="480px"
        id="1778851829"
      >
        <Override
          slot="SectionContent"
          margin="0px auto 0px auto"
          align-items="flex-start"
          justify-content="center"
        />
        <Box width="50%" lg-width="80%" md-width="94%" sm-width="100%">
          {/* <Text
            margin="0px 0px 10px 0px"
            font="--subheader1"
            color="--light"
            letter-spacing="1px"
            id="2952347793"
            className="hoverText "
            onClick={() => isSideBarOpen("3891279381")}
          >
            {data ? data["3891279381"] : "Home"}
          </Text> */}
          <Text
            margin="0px 0px 16px 0px"
            font="--headline1"
            color="--light"
            sm-font="--headline2"
            id="7717973199"
            className="hoverText"
            dangerouslySetInnerHTML={{
              __html: data ? data["7717973199"] : "Innovative",
            }}
            onClick={() => isSideBarOpen("7717973199")}
          >
            {/* {data ? data["7717973199"] : "Innovative"} */}
          </Text>
          <Text
            margin="0px 0px 20px 0px"
            font="--description1"
            color="--light"
            sm-font="--descirption3"
            id="3161932445"
            className="hoverText "
            dangerouslySetInnerHTML={{
              __html: data ? data["3161932445"] : "data",
            }}
            onClick={() => isSideBarOpen("3161932445")}
          >
            {/* {data
              ? data["7717973199"]
              : "Empowering Innovation Through Expert Engineering Solutions."} */}
          </Text>
          <div
            id="2201704297"
            className="hoverText "
            onClick={() => isSideBarOpen("2201704297")}
          >
            <NavLink to={"/Product Listing"}>
              <Button
                font="--button1"
                color="--primary"
                background="--color-white"
                width="fit-content"
                padding="10px 24px 10px 24px"
              >
                {data ? data["2201704297"] : "Explore Now"}
              </Button>
            </NavLink>
          </div>
        </Box>
      </Section>
      {/* <Section padding="40px 0 40px 0">
        <Override
          slot="SectionContent"
          margin="0px auto 0px auto"
          display="flex"
          flex-direction="row"
          justify-content="center"
          sm-display="grid"
          sm-grid-template-columns="repeat(2, 1fr)"
          sm-grid-gap="56px"
          md-justify-content="space-between"
        />
        <Box
          width="150px"
          display="flex"
          flex-direction="column"
          align-items="center"
          margin="0px 100px 0px 0px"
          sm-margin="0px 0px 0px 0px"
          sm-width="100%"
          md-margin="0px 0px 0px 0px"
        >
          <Text
            margin="0px 0px 14px 0px"
            font="--headline3"
            color="--primary"
            text-align="center"
            sm-font="--headline2"
            id="3387382625"
            className="hoverText"
            onClick={() => isSideBarOpen("3387382625")}
          >
            <CountUp end={344} duration={5} />
           
          </Text>
          <Text
            margin="0px 0px 0px 0px"
            font="--subheader1"
            color="--darkGrey"
            text-align="center"
            sm-font="--subheader1"
            id="3458879694"
            className="hoverText"
            dangerouslySetInnerHTML={{
              __html: data ? data["3458879694"] : "Marine",
            }}
            onClick={() => isSideBarOpen("3161932445")}
          >
            
          </Text>
        </Box>
        <Box
          width="150px"
          display="flex"
          flex-direction="column"
          align-items="center"
          margin="0px 100px 0px 0px"
          sm-margin="0px 0px 0px 0px"
          sm-width="100%"
          md-margin="0px 0px 0px 0px"
        >
          <Text
            margin="0px 0px 14px 0px"
            font="--headline3"
            color="--primary"
            text-align="center"
            sm-font="--headline2"
            id="7884247249"
            className="hoverText"
            onClick={() => isSideBarOpen("7884247249")}
          >
            <CountUp end={344} duration={5} />
            
          </Text>
          <Text
            margin="0px 0px 0px 0px"
            font="--subheader1"
            color="--darkGrey"
            text-align="center"
            sm-font="--subheader1"
            id="6533622032"
            className="hoverText"
            dangerouslySetInnerHTML={{
              __html: data ? data["6533622032"] : "Insustrial",
            }}
            onClick={() => isSideBarOpen("6533622032")}
          >
            
          </Text>
        </Box>
        <Box
          width="150px"
          display="flex"
          flex-direction="column"
          align-items="center"
          margin="0px 100px 0px 0px"
          sm-margin="0px 0px 0px 0px"
          sm-width="100%"
          md-margin="0px 0px 0px 0px"
        >
          <Text
            margin="0px 0px 14px 0px"
            font="--headline3"
            color="--primary"
            text-align="center"
            sm-font="--headline2"
            id="9174189293 "
            className="hoverText"
            onClick={() => isSideBarOpen("9174189293")}
          >
            <CountUp end={344} duration={5} />
           
          </Text>
          <Text
            margin="0px 0px 0px 0px"
            font="--subheader1"
            color="--darkGrey"
            text-align="center"
            sm-font="--subheader1"
            id="9021162553"
            className="hoverText"
            dangerouslySetInnerHTML={{
              __html: data ? data["9021162553"] : "F&B",
            }}
            onClick={() => isSideBarOpen("9021162553")}
          >
         
          </Text>
        </Box>
        <Box
          width="150px"
          display="flex"
          flex-direction="column"
          align-items="center"
          sm-width="100%"
        >
          <Text
            margin="0px 0px 14px 0px"
            font="--headline3"
            color="--primary"
            text-align="center"
            sm-font="--headline2"
            id="2254719910"
            className="hoverText"
            onClick={() => isSideBarOpen("2254719910")}
          >
            <CountUp end={344} duration={5} />
           
          </Text>
          <Text
            margin="0px 0px 0px 0px"
            font="--subheader1"
            color="--darkGrey"
            text-align="center"
            sm-font="--subheader1"
            id="0751165512"
            className="hoverText"
            dangerouslySetInnerHTML={{
              __html: data ? data["0751165512"] : "Hydraulic",
            }}
            onClick={() => isSideBarOpen("0751165512")}
          >
            
          </Text>
        </Box>
      </Section> */}
      <Section padding="50px 0 50px 0">
        <Override
          slot="SectionContent"
          margin="0px auto 0px auto"
          display="flex"
          flex-direction="row"
          align-items="flex-start"
          sm-flex-direction="column-reverse"
          md-flex-direction="column-reverse"
        />
        <Box
          width="52%"
          display="flex"
          position="relative"
          margin="0px 64px 0px 0px"
          sm-width="100%"
          sm-height="500px"
          sm-justify-content="flex-start"
          sm-margin="0px 0px 0px 0px"
          md-width="100%"
        >
          <Image
            src="https://uploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Group%202.svg?v=2023-08-10T11:28:36.163Z"
            display="block"
            sm-display="flex"
            sm-justify-content="flex-start"
            sm-width="200px"
            alt="image"
          />
          <Box
            position="absolute"
            bottom="-66px"
            left="auto"
            right={0}
            top={0}
            width="100%"
            height="100%"
            padding="75px 0px 75px 88px"
            sm-padding="55px 0px 55px 44px"
            className="hoverText"
            onClick={() => isSideBarOpen("1778851829")}
          >
            <Image
              src={
                data
                  ? data["1778851829"]
                  : "https://uploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group4.png?v=2023-08-31T12:33:57.955Z"
              }
              alt="image"
              // src="https://uploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Group.png?v=2023-08-31T12:37:05.333Z"
              display="block"
              position="static"
              left="auto"
              right="-0"
              top="auto"
              object-fit="cover"
              width="100%"
              height="100%"
              border-width="6px"
              border-style="solid"
              border-color="--color-white"
              id="1778851829"
              // srcSet="https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Group.png?v=2023-08-31T12%3A37%3A05.333Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Group.png?v=2023-08-31T12%3A37%3A05.333Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Group.png?v=2023-08-31T12%3A37%3A05.333Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Group.png?v=2023-08-31T12%3A37%3A05.333Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Group.png?v=2023-08-31T12%3A37%3A05.333Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Group.png?v=2023-08-31T12%3A37%3A05.333Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Group.png?v=2023-08-31T12%3A37%3A05.333Z&quality=85&w=3200 3200w"
              sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
            />
          </Box>
        </Box>
        <Box
          display="flex"
          flex-direction="column"
          align-items="flex-start"
          width="48%"
          sm-width="100%"
          sm-margin="0px 0px 30px 0px"
          md-width="100%"
          md-margin="0px 0px 60px 0px"
        >
          <Text
            margin="0px 0px 4px 0px"
            font="--subheader1"
            color="--darkGrey"
            text-align="center"
            letter-spacing="1px"
            sm-font="--description4"
            id="1059065852"
            className="hoverText "
            onClick={() => isSideBarOpen("1059065852")}
          >
            {data ? data["1059065852"] : " About us"}
          </Text>
          <Text
            margin="0px 0px 40px 0px"
            font="--headline2"
            color="--primary"
            text-align="center"
            sm-font="--headline4"
            sm-margin="0px 0px 20px 0px"
            id="3758175555"
            className="hoverText "
            onClick={() => isSideBarOpen("3758175555")}
          >
            {data ? data["3758175555"] : "About SCV"}
          </Text>
          <Box
            display="flex"
            align-items="flex-start"
            margin="0px 0px 24px 0px"
            width="100%"
          >
            <Image
              src="https://uploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Vector%2040.svg?v=2023-08-10T11:28:36.171Z"
              alt="image"
              display="block"
              width="21px"
              height="24px"
            />
            <Box
              background="--color-primary"
              padding="20px 25px 20px 25px"
              width="100%"
            >
              <Text
                margin="0px 0px 0px 0px"
                font="--description2"
                color="--white"
                text-align="left"
                sm-font="--description4"
                id="1882258502"
                className="hoverText "
                onClick={() => isSideBarOpen("1882258502")}
              >
                {data ? data["1882258502"] : "Unveiling Our Vision"}
              </Text>
            </Box>
          </Box>
          <Box
            display="flex"
            align-items="flex-start"
            margin="0px 0px 24px 0px"
          >
            <Image
              src="https://uploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Vector%2041.svg?v=2023-08-10T11:28:36.165Z"
              display="block"
              width="22px"
              height="24px"
              alt="image"
            />
            <Box
              padding="20px 25px 20px 25px"
              border-width="1px"
              border-style="solid"
              border-color="#E3E3E3"
            >
              <Text
                margin="0px 0px 0px 0px"
                font="--description2"
                color="--darkL2"
                text-align="left"
                sm-font="--description4"
                id="1577065133"
                className="hoverText"
                onClick={() => isSideBarOpen("1577065133")}
              >
                {data
                  ? data["1577065133"]
                  : "Our mission is to exceed the expectations"}
              </Text>
            </Box>
          </Box>
          <Box
            display="flex"
            align-items="flex-start"
            margin="0px 0px 24px 0px"
          >
            <Image
              src="https://uploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Vector%2041.svg?v=2023-08-10T11:28:36.165Z"
              display="block"
              width="22px"
              height="24px"
              alt="image"
            />
            <Box
              padding="20px 25px 20px 25px"
              border-width="1px"
              border-style="solid"
              border-color="#E3E3E3"
            >
              <Text
                margin="0px 0px 0px 0px"
                font="--description2"
                color="--darkL2"
                text-align="left"
                sm-font="--description4"
                id="5561325872"
                className="hoverText"
                onClick={() => isSideBarOpen("5561325872")}
              >
                {data ? data["5561325872"] : "To be a worldwide leader"}
              </Text>
            </Box>
          </Box>
          <Box display="flex" align-items="flex-start">
            <Image
              src="https://uploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Vector%2041.svg?v=2023-08-10T11:28:36.165Z"
              display="block"
              width="21px"
              height="24px"
              alt="image"
            />
            <Box
              padding="20px 25px 20px 25px"
              border-width="1px"
              border-style="solid"
              border-color="#E3E3E3"
            >
              <Text
                margin="0px 0px 0px 0px"
                font="--description2"
                color="--darkL2"
                text-align="left"
                sm-font="--description4"
                id="1645516342"
                className="hoverText"
                onClick={() => isSideBarOpen("1645516342")}
              >
                {data
                  ? data["1645516342"]
                  : "Safety, quality. accountability, honest and ethically focused"}
              </Text>
            </Box>
          </Box>
        </Box>
      </Section>
      <Section padding="50px 0 80px 0">
        <Override slot="SectionContent" margin="0px auto 0px auto" />
        <Text
          margin="0px 0px 4px 0px"
          font="--subheader1"
          color="--darkGrey"
          text-align="center"
          letter-spacing="1px"
          sm-font="--description4"
          id="1131154728"
          className="hoverText"
          onClick={() => isSideBarOpen("1131154728")}
        >
          {data ? data["1131154728"] : "Services"}
        </Text>
        <Text
          margin="0px 0px 70px 0px"
          font="--headline2"
          color="--primary"
          text-align="center"
          sm-font="--headline4"
          sm-margin="0px 0px 40px 0px"
          id="3699790176"
          className="hoverText"
          onClick={() => isSideBarOpen("3699790176")}
        >
          {data ? data["3699790176"] : "Expert Solutions Across Industries"}
        </Text>
        <Box
          display="flex"
          justify-content="space-between"
          sm-display="grid"
          md-display="grid"
          md-grid-template-columns="repeat(1, 1fr)"
          md-grid-gap="38px"
        >
          <Box
            display="flex"
            flex-direction="column"
            align-items="center"
            margin="0px 80px 0px 0px"
            sm-margin="0px 0px 50px 0px"
            md-margin="0px 0px 0px 0px"
          >
            <Image
              src="https://uploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/image2vector%20%282%29%201.svg?v=2023-08-11T05:49:01.341Z"
              display="block"
              width="80px"
              height="80px"
              object-fit="cover"
              margin="0px 0px 14px 0px"
              alt="image"
            />
            <Text
              margin="0px 0px 16px 0px"
              font="--subheader2"
              color="--primary"
              text-align="center"
              sm-font="--subheader1"
              sm-margin="0px 0px 22px 0px"
              id="5772183500"
              className="hoverText "
              dangerouslySetInnerHTML={{
                __html: data ? data["5772183500"] : "no data",
              }}
              onClick={() => isSideBarOpen("5772183500")}
            >
              {/* Marine
              <br />
              Engineering */}
            </Text>
            <Text
              margin="0px 0px 2px 0px"
              font="--descirption3"
              color="--darkGrey"
              text-align="center"
              sm-font="--description4"
              id="9682328443"
              className="hoverText "
              dangerouslySetInnerHTML={{
                __html: data ? data["9682328443"] : "As Marine Engine",
              }}
              onClick={() => isSideBarOpen("9682328443")}
            >
              {/* As Marine Engine Specialist we service and repair a wide range of
              engines and related equipment. */}
            </Text>
          </Box>
          <Box
            display="flex"
            flex-direction="column"
            align-items="center"
            margin="0px 80px 0px 0px"
            sm-margin="0px 0px 50px 0px"
            md-margin="0px 0px 0px 0px"
          >
            <Image
              src="https://uploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/image2vector%20%283%29%201.svg?v=2023-08-11T05:49:01.344Z"
              display="block"
              width="80px"
              height="80px"
              object-fit="cover"
              margin="0px 0px 14px 0px"
              alt="image"
            />
            <Text
              margin="0px 0px 16px 0px"
              font="--subheader2"
              color="--primary"
              text-align="center"
              sm-font="--subheader1"
              sm-margin="0px 0px 22px 0px"
              id="9326229431"
              className="hoverText "
              dangerouslySetInnerHTML={{
                __html: data ? data["9326229431"] : "no data",
              }}
              onClick={() => isSideBarOpen("9326229431")}
            >
              {/* Industrial <br />
              Engineering */}
            </Text>
            <Text
              margin="0px 0px 2px 0px"
              font="--descirption3"
              color="--darkGrey"
              text-align="center"
              sm-font="--description4"
              id="8029299616"
              className="hoverText "
              dangerouslySetInnerHTML={{
                __html: data ? data["8029299616"] : "We provide wide ",
              }}
              onClick={() => isSideBarOpen("8029299616")}
            >
              {/* We provide wide variety of Industrial Technology services varying
              according to clients preference. */}
            </Text>
          </Box>
          <Box
            display="flex"
            flex-direction="column"
            align-items="center"
            margin="0px 80px 0px 0px"
            sm-margin="0px 0px 50px 0px"
            md-margin="0px 0px 0px 0px"
          >
            <Image
              src="https://uploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/image-removebg-preview%201.svg?v=2023-09-04T05:11:51.330Z"
              display="block"
              width="80px"
              height="80px"
              object-fit="cover"
              margin="0px 0px 14px 0px"
              alt="image"
            />
            <Text
              margin="0px 0px 16px 0px"
              font="--subheader2"
              color="--primary"
              text-align="center"
              sm-font="--subheader1"
              sm-margin="0px 0px 22px 0px"
              id="1911780151"
              className="hoverText "
              dangerouslySetInnerHTML={{
                __html: data ? data["1911780151"] : "no data",
              }}
              onClick={() => isSideBarOpen("1911780151")}
            >
              {/* F&B <br />
              industry{"\n\n"} */}
            </Text>
            <Text
              margin="0px 0px 2px 0px"
              font="--descirption3"
              color="--darkGrey"
              text-align="center"
              sm-font="--description4"
              id="8697585636"
              className="hoverText "
              dangerouslySetInnerHTML={{
                __html: data ? data["8697585636"] : "no data",
              }}
              onClick={() => isSideBarOpen("8697585636")}
            >
              {/* Well qualified professionals to engage in useful solutions for our
              customers. */}
            </Text>
          </Box>
          <Box display="flex" flex-direction="column" align-items="center">
            <Image
              src="https://uploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/image2vector%201.svg?v=2023-08-11T05:49:01.346Z"
              display="block"
              width="80px"
              height="80px"
              object-fit="cover"
              margin="0px 0px 14px 0px"
              alt="image"
            />
            <Text
              margin="0px 0px 16px 0px"
              font="--subheader2"
              color="--primary"
              text-align="center"
              sm-font="--subheader1"
              sm-margin="0px 0px 22px 0px"
              id="5288417651"
              className="hoverText "
              dangerouslySetInnerHTML={{
                __html: data ? data["5288417651"] : "5288417651",
              }}
              onClick={() => isSideBarOpen("5288417651")}
            >
              {/* 5288417651
              <br />
              Engineering */}
            </Text>
            <Text
              margin="0px 0px 2px 0px"
              font="--descirption3"
              color="--darkGrey"
              text-align="center"
              sm-font="--description4"
              id="6116942833"
              className="hoverText "
              dangerouslySetInnerHTML={{
                __html: data ? data["6116942833"] : "no data",
              }}
              onClick={() => isSideBarOpen("6116942833")}
            >
              {/* Expertise Solution guaranteed for any mechanical work across
              locations. */}
            </Text>
          </Box>
        </Box>
      </Section>
      <Brands />
      {/* <Section
        padding="80px 0 80px 0"
        background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Frame%201000004059.svg?v=2023-08-11T08:16:12.814Z) 0% 0% /cover repeat scroll padding-box"
      >
        <Override
          slot="SectionContent"
          margin="0px auto 0px auto"
          flex-direction="row"
          justify-content="space-between"
          sm-flex-direction="column"
          md-flex-direction="column"
        />
        <Box
          margin="0px 20px 0px 0px"
          width="400px"
          sm-width="100%"
          sm-margin="0px 20px 40px 0px"
          md-width="100%"
          md-margin="0px 20px 60px 0px"
        >
          <Text
            margin="0px 0px 4px 0px"
            font="--subheader1"
            color="--lightD2"
            text-align="left"
            letter-spacing="1px"
            sm-font="--description4"
            id="3468967497"
            className="hoverText"
            onClick={() => isSideBarOpen("3468967497")}
          >
            {data ? data["3468967497"] : "Testimonials"}
          </Text>
          <Text
            margin="0px 0px 30px 0px"
            font="normal 600 36px/47px --fontFamily-googlePoppins"
            color="--white"
            text-align="left"
            sm-font="--headline4"
            id="8201855977"
            className="hoverText"
            dangerouslySetInnerHTML={{
              __html: data
                ? data["8201855977"]
                : "Words From Our Satisfied Clients",
            }}
            onClick={() => isSideBarOpen("8201855977")}
          ></Text>
        </Box>
        <Box
          display="flex"
          width="700px"
          sm-width="100%"
          sm-overflow-x="scroll"
          md-overflow-x="scroll"
          md-width="100%"
          overflow-x="hidden"
          justify-content="center"
          align-items="center"
        >
          <Swiper
            observer={true}
            observeParents={true}
            modules={[Navigation]}
            className="mySwiper relative"
            navigation={{
              nextEl: ".image-swiper-button-nexts",
              prevEl: ".image-swiper-button-prevs",
            }}
            breakpoints={{
              1536: {
                slidesPerView: 1,
                spaceBetween: 30,
              },
              1280: {
                slidesPerView: 1,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 1,
                spaceBetween: 30,
              },
              991: {
                slidesPerView: 1,
                spaceBetween: 12,
              },
              640: {
                slidesPerView: 1,
                spaceBetween: 12,
              },
              425: {
                slidesPerView: 1,
                spaceBetween: 12,
              },
              325: {
                slidesPerView: 1,
                spaceBetween: 12,
              },
            }}
          >
            <div className="swiper-button image-swiper-button-prevs cursor-pointer">
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
              </svg>
            </div>
            <div className="swiper-button image-swiper-button-nexts cursor-pointer">
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
              </svg>
            </div>
            <SwiperSlide className="">
              <Box
                display="flex"
                width="700px"
                sm-width="100%"
                sm-overflow-x="scroll"
                md-overflow-x="scroll"
                md-width="100%"
                overflow-x="hidden"
                justify-content="center"
                align-items="center"
              >
                <Box
                  padding="32px 24px 32px 24px"
                  background="--color-white"
                  margin="0px 0px 0px 0px"
                  width="700px"
                  min-width="594px"
                >
                  <Text
                    margin="0px 0px 32px 0px"
                    font="--description2"
                    color="--darkGrey"
                    text-align="left"
                    sm-font="--descirption3"
                    id="8564704404"
                  >
                    I had one of best experience with the team, I had choose the
                    marine engineering service.  I had one of best experience
                    with the team, I had choose the marine engineering service.
                  </Text>
                  <Box display="flex" justify-content="space-between">
                    <Box display="flex">
                      <Image
                        src="https://images.unsplash.com/photo-1691573478305-2734dfb852ad?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
                        display="block"
                        width="48px"
                        height="48px"
                        object-fit="cover"
                        margin="0px 12px 0px 0px"
                        border-radius="100px"
                        sm-width="44px"
                        sm-height="44px"
                        srcSet="https://images.unsplash.com/photo-1691573478305-2734dfb852ad?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=500 500w,https://images.unsplash.com/photo-1691573478305-2734dfb852ad?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=800 800w,https://images.unsplash.com/photo-1691573478305-2734dfb852ad?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1080 1080w,https://images.unsplash.com/photo-1691573478305-2734dfb852ad?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1600 1600w,https://images.unsplash.com/photo-1691573478305-2734dfb852ad?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2000 2000w,https://images.unsplash.com/photo-1691573478305-2734dfb852ad?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2600 2600w,https://images.unsplash.com/photo-1691573478305-2734dfb852ad?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=3200 3200w"
                        sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
                      />
                      <Box>
                        <Text
                          margin="0px 0px 0px 0px"
                          font="--subheader1"
                          color="--primary"
                          text-align="left"
                          sm-font="--description2"
                          id="9796888065"
                        >
                          Kristin Watson
                        </Text>
                        <Text
                          margin="0px 0px 2px 0px"
                          font="normal 500 12px/26px --fontFamily-googlePoppins"
                          color="--darkGrey"
                          text-align="left"
                          sm-font="--description4"
                          id="9261270788"
                        >
                          Jun 27, 2020
                        </Text>
                      </Box>
                    </Box>
                    <Box display="flex" align-items="center">
                      <Icon
                        category="md"
                        icon={MdStar}
                        size="64px"
                        width="18px"
                        height="18px"
                        color="--secondary"
                      />
                      <Icon
                        category="md"
                        icon={MdStar}
                        size="64px"
                        width="18px"
                        height="18px"
                        color="--secondary"
                      />
                      <Icon
                        category="md"
                        icon={MdStar}
                        size="64px"
                        width="18px"
                        height="18px"
                        color="--secondary"
                      />
                      <Icon
                        category="md"
                        icon={MdStar}
                        size="64px"
                        width="18px"
                        height="18px"
                        color="--secondary"
                      />
                      <Icon
                        category="md"
                        icon={MdStar}
                        size="64px"
                        width="18px"
                        height="18px"
                        color="--secondary"
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </SwiperSlide>
            <SwiperSlide className="">
              <Box
                display="flex"
                width="700px"
                sm-width="100%"
                sm-overflow-x="scroll"
                md-overflow-x="scroll"
                md-width="100%"
                overflow-x="hidden"
                justify-content="center"
                align-items="center"
              >
                <Box
                  padding="32px 24px 32px 24px"
                  background="--color-white"
                  margin="0px 0px 0px 0px"
                  width="700px"
                  min-width="594px"
                >
                  <Text
                    margin="0px 0px 32px 0px"
                    font="--description2"
                    color="--darkGrey"
                    text-align="left"
                    sm-font="--descirption3"
                    id="8564704404"
                  >
                    I had one of best experience with the team, I had choose the
                    marine engineering service.  I had one of best experience
                    with the team, I had choose the marine engineering service.
                  </Text>
                  <Box display="flex" justify-content="space-between">
                    <Box display="flex">
                      <Image
                        src="https://images.unsplash.com/photo-1691573478305-2734dfb852ad?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
                        display="block"
                        width="48px"
                        height="48px"
                        object-fit="cover"
                        margin="0px 12px 0px 0px"
                        border-radius="100px"
                        sm-width="44px"
                        sm-height="44px"
                        srcSet="https://images.unsplash.com/photo-1691573478305-2734dfb852ad?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=500 500w,https://images.unsplash.com/photo-1691573478305-2734dfb852ad?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=800 800w,https://images.unsplash.com/photo-1691573478305-2734dfb852ad?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1080 1080w,https://images.unsplash.com/photo-1691573478305-2734dfb852ad?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1600 1600w,https://images.unsplash.com/photo-1691573478305-2734dfb852ad?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2000 2000w,https://images.unsplash.com/photo-1691573478305-2734dfb852ad?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2600 2600w,https://images.unsplash.com/photo-1691573478305-2734dfb852ad?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=3200 3200w"
                        sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
                      />
                      <Box>
                        <Text
                          margin="0px 0px 0px 0px"
                          font="--subheader1"
                          color="--primary"
                          text-align="left"
                          sm-font="--description2"
                          id="9796888065"
                        >
                          Kristin Watson
                        </Text>
                        <Text
                          margin="0px 0px 2px 0px"
                          font="normal 500 12px/26px --fontFamily-googlePoppins"
                          color="--darkGrey"
                          text-align="left"
                          sm-font="--description4"
                          id="9261270788"
                        >
                          Jun 27, 2020
                        </Text>
                      </Box>
                    </Box>
                    <Box display="flex" align-items="center">
                      <Icon
                        category="md"
                        icon={MdStar}
                        size="64px"
                        width="18px"
                        height="18px"
                        color="--secondary"
                      />
                      <Icon
                        category="md"
                        icon={MdStar}
                        size="64px"
                        width="18px"
                        height="18px"
                        color="--secondary"
                      />
                      <Icon
                        category="md"
                        icon={MdStar}
                        size="64px"
                        width="18px"
                        height="18px"
                        color="--secondary"
                      />
                      <Icon
                        category="md"
                        icon={MdStar}
                        size="64px"
                        width="18px"
                        height="18px"
                        color="--secondary"
                      />
                      <Icon
                        category="md"
                        icon={MdStar}
                        size="64px"
                        width="18px"
                        height="18px"
                        color="--secondary"
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </SwiperSlide>
          </Swiper>
        </Box>
      </Section> */}
      {/* <Section padding="90px 0 80px 0">
        <Override slot="SectionContent" margin="0px auto 0px auto" />
        <Box
          min-width="100px"
          min-height="100px"
          display="flex"
          sm-flex-direction="column"
          md-flex-direction="column"
        >
          <Box
            min-width="100px"
            min-height="100px"
            width="50%"
            margin="0px 38px 0px 0px"
            sm-width="100%"
            sm-margin="0px 0px 70px 0px"
            md-width="100%"
            md-margin="0px 38px 50px 0px"
          >
            <Box margin="0px 0px 30px 0px">
              <Text
                margin="0px 0px 12px 0px"
                font="--subheader3"
                color="--darkGrey"
                text-align="left"
                sm-font="--subheader2"
                id="1743558276"
                className="hoverText"
                onClick={() => isSideBarOpen("1743558276")}
              >
                {data ? data["1743558276"] : "Vision"}
              </Text>
              <Text
                margin="0px 0px 0px 0px"
                font="--descirption3"
                color="--darkL2"
                text-align="left"
                sm-font="--descirption3"
                id="4304061892"
                className="hoverText "
                dangerouslySetInnerHTML={{
                  __html: data
                    ? data["4304061892"]
                    : "To be a worldwide leader",
                }}
                onClick={() => isSideBarOpen("4304061892")}
              ></Text>
            </Box>
            <Box margin="0px 0px 30px 0px">
              <Text
                margin="0px 0px 12px 0px"
                font="--subheader3"
                color="--darkGrey"
                text-align="left"
                sm-font="--subheader2"
                id="0953368769"
                className="hoverText"
                onClick={() => isSideBarOpen("0953368769")}
              >
                {data ? data["0953368769"] : "Mission"}
              </Text>
              <Text
                margin="0px 0px 0px 0px"
                font="--descirption3"
                color="--darkL2"
                text-align="left"
                sm-font="--descirption3"
                id="1984357617"
                className="hoverText "
                dangerouslySetInnerHTML={{
                  __html: data
                    ? data["1984357617"]
                    : "Our mission is to exceed ",
                }}
                onClick={() => isSideBarOpen("1984357617")}
              ></Text>
            </Box>
            <Box>
              <Text
                margin="0px 0px 12px 0px"
                font="--subheader3"
                color="--darkGrey"
                text-align="left"
                sm-font="--subheader2"
                id="4890588143"
                className="hoverText"
                onClick={() => isSideBarOpen("4890588143")}
              >
                {data ? data["4890588143"] : "Marine Vision"}
              </Text>
              <Text
                margin="0px 0px 0px 0px"
                font="--descirption3"
                color="--darkL2"
                text-align="left"
                sm-font="--descirption3"
                id="2285559876"
                className="hoverText "
                dangerouslySetInnerHTML={{
                  __html: data
                    ? data["2285559876"]
                    : "Safety, quality. accountability, honest and ethically focused.",
                }}
                onClick={() => isSideBarOpen("2285559876")}
              ></Text>
            </Box>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            width="50%"
            sm-width="100%"
            md-width="100%"
          >
            <Box margin="0px 0px 30px 0px">
              <Text
                margin="0px 0px 12px 0px"
                font="--subheader3"
                color="--darkGrey"
                text-align="left"
                sm-font="--subheader2"
                id="5068683690"
                className="hoverText"
                onClick={() => isSideBarOpen("5068683690")}
              >
                {data ? data["5068683690"] : "Marine Engineering"}
              </Text>
              <Box
                display="flex"
                align-items="center"
                justify-content="space-between"
              >
              
                <Progressbar
                  bgcolor="#002147"
                  borderWidth="0px 0px 5px 0px"
                  progress="55"
                  height={10}
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="normal 400 22px/26px --fontFamily-googlePoppins"
                  color="--darkL2"
                  text-align="left"
                >
                  55%
                </Text>
              </Box>
            </Box>
            <Box margin="0px 0px 30px 0px">
              <Text
                margin="0px 0px 12px 0px"
                font="--subheader3"
                color="--darkGrey"
                text-align="left"
                sm-font="--subheader2"
                id="5063176162"
                className="hoverText"
                onClick={() => isSideBarOpen("5063176162")}
              >
                {data ? data["5063176162"] : "Industrial Engineering"}
              </Text>
              <Box
                display="flex"
                align-items="center"
                justify-content="space-between"
              >
               
                <Progressbar
                  bgcolor="#002147"
                  borderWidth="0px 0px 5px 0px"
                  progress="36"
                  height={10}
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="normal 400 22px/26px --fontFamily-googlePoppins"
                  color="--darkL2"
                  text-align="left"
                >
                  36%
                </Text>
              </Box>
            </Box>
            <Box margin="0px 0px 30px 0px">
              <Text
                margin="0px 0px 12px 0px"
                font="--subheader3"
                color="--darkGrey"
                text-align="left"
                sm-font="--subheader2"
                id="2597997530"
                className="hoverText"
                onClick={() => isSideBarOpen("2597997530")}
              >
                {data ? data["2597997530"] : "Commercial Engineering"}
              </Text>
              <Box
                display="flex"
                align-items="center"
                justify-content="space-between"
              >
              
                <Progressbar
                  bgcolor="#002147"
                  borderWidth="0px 0px 5px 0px"
                  progress="25"
                  height={10}
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="normal 400 22px/26px --fontFamily-googlePoppins"
                  color="--darkL2"
                  text-align="left"
                >
                  25%
                </Text>
              </Box>
            </Box>
            <Box>
              <Text
                margin="0px 0px 12px 0px"
                font="--subheader3"
                color="--darkGrey"
                text-align="left"
                sm-font="--subheader2"
                id="6916175020"
                className="hoverText"
                onClick={() => isSideBarOpen("6916175020")}
              >
                {data ? data["6916175020"] : "Hydraulic Engineering"}
              </Text>
              <Box
                display="flex"
                align-items="center"
                justify-content="space-between"
              >
               
                <Progressbar
                  bgcolor="#002147"
                  borderWidth="0px 0px 5px 0px"
                  progress="11"
                  height={10}
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="normal 400 22px/26px --fontFamily-googlePoppins"
                  color="--darkL2"
                  text-align="left"
                >
                  11%
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </Section> */}
      <Section
        quarkly-title="Product"
        align-items="center"
        justify-content="center"
        position="static"
        md-align-items="flex-start"
        md-justify-content="flex-start"
        md-height="fit-content"
      >
        <Override
          slot="SectionContent"
          display="block"
          padding="0px 0px 0px 0px"
          md-padding="0px 0px 0px 0px"
          sm-padding="0px 12px 0px 12px"
          sm-margin="60px 0px 60px 0px"
          md-display="flex"
          md-align-items="flex-start"
          md-height="fit-content"
          lg-margin="60px auto 0px auto"
          margin="48px 80px 48px 80px"
          sm-width="100%"
        />
        <Text
          margin="0px 0px 2px 0px"
          font="normal 600 18px/26px --fontFamily-googlePoppins"
          sm-font="normal 600 14px/26px --fontFamily-googlePoppins"
          color="--darkGrey"
          text-align="left"
          id="2065198246"
          className="hoverText"
          onClick={() => isSideBarOpen("2065198246")}
        >
          {data ? data["2065198246"] : "Products"}
        </Text>
        <Box
          sm-height="100%"
          sm-width="100%"
          lg-width="100%"
          quarkly-title="Headphones For You"
          md-display="flex"
          md-flex-direction="column"
          md-height="fit-content"
        >
          <Box
            display="flex"
            sm-display="block"
            justify-content="space-between"
            margin="0px 0px 50px 0px"
          >
            <Text
              margin="0px 0px 0px 0px"
              font="normal 600 36px/130% --fontFamily-googlePoppins"
              sm-font-size="24px"
              color="--primary"
              text-align="left"
              sm-font="--headline4"
              sm-width="100% "
              id="0012786018"
              className="hoverText "
              dangerouslySetInnerHTML={{
                __html: data
                  ? data["0012786018"]
                  : "Expert Solutions with Experties",
              }}
              onClick={() => isSideBarOpen("0012786018")}
            ></Text>
            <div
              id="1033140053"
              className="hoverText"
              onClick={() => isSideBarOpen("1033140053")}
            >
              <Button
                type="link"
                href="/Product Listing"
                font="--button1"
                color="--primary"
                width="fit-content"
                padding="10px 24px 10px 24px"
                sm-padding="10px 0px 10px 0px"
                height="fit-content"
                background="#ffffff"
                sm-width="fit-content"
              >
                {data ? data["1033140053"] : "View All"}
              </Button>
            </div>
          </Box>
          <Box
            // display="grid"
            // align-items="start"
            // grid-gap="24px"
            // grid-template-columns="repeat(3, 1fr)"
            // sm-grid-template-rows="repeat(1, 1fr)"
            // sm-grid-template-columns="repeat(1, 1fr)"
            // md-grid-gap="16px"
            // md-grid-template-columns="repeat(2, 1fr)"
            lg-width="100%"
            // lg-grid-template-rows="repeat(2, 1fr)"
            // lg-grid-template-columns="repeat(2, 1fr)"
            // align-content="start"
            // md-display="grid"
            // md-justify-content="flex-start"

            sm-width="100%"
            className="container mx-auto newrswipe overflow-hidden"
          >
            <Swiper
              observer={true}
              observeParents={true}
              modules={[Navigation]}
              className=" relative"
              navigation={{
                nextEl: ".image-swiper-button-next",
                prevEl: ".image-swiper-button-prev",
              }}
              breakpoints={{
                1536: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                1280: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                991: {
                  slidesPerView: 1,
                  spaceBetween: 12,
                },
                640: {
                  slidesPerView: 1.5,
                  spaceBetween: 12,
                },
                425: {
                  slidesPerView: 1.2,
                  spaceBetween: 12,
                },
                325: {
                  slidesPerView: 1.2,
                  spaceBetween: 12,
                },
              }}
            >
              <div className="swiper-button image-swiper-button-prev cursor-pointer">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
                </svg>
              </div>
              <div className="swiper-button image-swiper-button-next cursor-pointer">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
                </svg>
              </div>
              {categoryData && categoryData.result && categoryData.result.length
                ? categoryData.result.map((item, i) => {
                    return (
                      <SwiperSlide key={i}>
                        <LinkBox
                          display="block"
                          href="/Product Listing"
                          quarkly-title="Product Card"
                          padding="16px 16px 16px 16px"
                          width="400px"
                          sm-width="100%"
                          overflow-y="hidden"
                          border-width="1px"
                          border-style="solid"
                          border-color="--color-grey"
                          overflow-x="hidden"
                        >
                          <Image
                            src={
                              item.image
                                ? process.env.REACT_APP_STORAGE_URL_2 +
                                  item.image
                                : "https://uploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Group%205565.png?v=2023-08-31T07:43:44.271Z"
                            }
                            alt="image"
                            display="block"
                            margin="0px 60px 0px 0px"
                            padding="0px 0px 0px 0px"
                            height="305px"
                            sm-width="100%"
                            sm-margin="0px 60px 0px 0px"
                            md-width="100%"
                            md-margin="0px 60px 0px 0px"
                            width="100%"
                            object-fit="cover"
                            // srcSet="https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Group%205565.png?v=2023-08-31T07%3A43%3A44.271Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Group%205565.png?v=2023-08-31T07%3A43%3A44.271Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Group%205565.png?v=2023-08-31T07%3A43%3A44.271Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Group%205565.png?v=2023-08-31T07%3A43%3A44.271Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Group%205565.png?v=2023-08-31T07%3A43%3A44.271Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Group%205565.png?v=2023-08-31T07%3A43%3A44.271Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Group%205565.png?v=2023-08-31T07%3A43%3A44.271Z&quality=85&w=3200 3200w"
                            sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
                          />
                          <Box
                            display="flex"
                            align-items="flex-start"
                            flex-direction="column"
                            justify-content="flex-end"
                            padding="16px 0px 0px 0px"
                          >
                            <Text
                              margin="0px 0px 4px 0px"
                              color="--darkL1"
                              font="--headline4"
                              sm-font="--headline5"
                              height="fit-content"
                              width="100%"
                            >
                              {item.name}
                            </Text>
                            <Text
                              margin="0px 0px 4px 0px"
                              color="--darkL1"
                              font="--descirption3"
                              height="fit-content"
                              width="100%"
                            >
                              {item.description}
                            </Text>
                          </Box>
                        </LinkBox>
                      </SwiperSlide>
                    );
                  })
                : "no-data"}

              {/* <SwiperSlide className="">
                <Box
                  display="block"
                  quarkly-title="Product Card"
                  padding="16px 16px 16px 16px"
                  width="400px"
                  overflow-y="hidden"
                  border-width="1px"
                  border-style="solid"
                  border-color="--color-grey"
                  overflow-x="hidden"
                >
                  <Image
                    src="https://uploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Group%205565.png?v=2023-08-31T07:43:44.271Z"
                    display="block"
                    margin="0px 60px 0px 0px"
                    padding="0px 0px 0px 0px"
                    height="305px"
                    sm-width="100%"
                    sm-margin="0px 60px 0px 0px"
                    md-width="100%"
                    md-margin="0px 60px 0px 0px"
                    width="100%"
                    object-fit="contain"
                    srcSet="https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Group%205565.png?v=2023-08-31T07%3A43%3A44.271Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Group%205565.png?v=2023-08-31T07%3A43%3A44.271Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Group%205565.png?v=2023-08-31T07%3A43%3A44.271Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Group%205565.png?v=2023-08-31T07%3A43%3A44.271Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Group%205565.png?v=2023-08-31T07%3A43%3A44.271Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Group%205565.png?v=2023-08-31T07%3A43%3A44.271Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Group%205565.png?v=2023-08-31T07%3A43%3A44.271Z&quality=85&w=3200 3200w"
                    sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
                  />
                  <Box
                    display="flex"
                    align-items="flex-start"
                    flex-direction="column"
                    justify-content="flex-end"
                    padding="16px 0px 0px 0px"
                  >
                    <Text
                      margin="0px 0px 4px 0px"
                      color="--darkL1"
                      font="--headline4"
                      height="fit-content"
                      width="100%"
                    >
                      Filtration Product
                    </Text>
                    <Text
                      margin="0px 0px 4px 0px"
                      color="--darkL1"
                      font="--descirption3"
                      height="fit-content"
                      width="100%"
                    >
                      Introducing our OTR & Mining product – built for extreme
                      durability and high performance. Designed to excel in the
                      toughest conditions, it prioritizes safety and efficiency.
                      {"\n\n"}
                    </Text>
                  </Box>
                </Box>
              </SwiperSlide> */}
              {/* <SwiperSlide className="">
                <Box
                  display="block"
                  quarkly-title="Product Card"
                  padding="16px 16px 16px 16px"
                  width="400px"
                  overflow-y="hidden"
                  border-width="1px"
                  border-style="solid"
                  border-color="--color-grey"
                  overflow-x="hidden"
                >
                  <Image
                    src="https://uploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group-1.png?v=2023-08-31T07:43:33.181Z"
                    display="block"
                    margin="0px 60px 0px 0px"
                    padding="0px 0px 0px 0px"
                    height="305px"
                    sm-width="100%"
                    sm-margin="0px 60px 0px 0px"
                    md-width="100%"
                    md-margin="0px 60px 0px 0px"
                    width="100%"
                    object-fit="cover"
                    srcSet="https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group-1.png?v=2023-08-31T07%3A43%3A33.181Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group-1.png?v=2023-08-31T07%3A43%3A33.181Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group-1.png?v=2023-08-31T07%3A43%3A33.181Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group-1.png?v=2023-08-31T07%3A43%3A33.181Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group-1.png?v=2023-08-31T07%3A43%3A33.181Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group-1.png?v=2023-08-31T07%3A43%3A33.181Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group-1.png?v=2023-08-31T07%3A43%3A33.181Z&quality=85&w=3200 3200w"
                    sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
                  />
                  <Box
                    display="flex"
                    align-items="flex-start"
                    flex-direction="column"
                    justify-content="flex-end"
                    padding="16px 0px 0px 0px"
                  >
                    <Text
                      margin="0px 0px 4px 0px"
                      color="--darkL1"
                      font="--headline4"
                      height="fit-content"
                      width="100%"
                    >
                      Pipe Fitting
                    </Text>
                    <Text
                      margin="0px 0px 4px 0px"
                      color="--darkL1"
                      font="--descirption3"
                      height="fit-content"
                      width="100%"
                    >
                      Introducing our OTR & Mining product – built for extreme
                      durability and high performance. Designed to excel in the
                      toughest conditions, it prioritizes safety and efficiency.
                      {"\n\n"}
                    </Text>
                  </Box>
                </Box>
              </SwiperSlide>
              <SwiperSlide className="">
                <Box
                  display="block"
                  quarkly-title="Product Card"
                  padding="16px 16px 16px 16px"
                  width="400px"
                  overflow-y="hidden"
                  border-width="1px"
                  border-style="solid"
                  border-color="--color-grey"
                  overflow-x="hidden"
                >
                  <Image
                    src="https://uploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group-3.png?v=2023-08-31T07:51:35.567Z"
                    display="block"
                    margin="0px 60px 0px 0px"
                    padding="0px 0px 0px 0px"
                    height="305px"
                    sm-width="100%"
                    sm-margin="0px 60px 0px 0px"
                    md-width="100%"
                    md-margin="0px 60px 0px 0px"
                    width="100%"
                    object-fit="cover"
                    srcSet="https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group-3.png?v=2023-08-31T07%3A51%3A35.567Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group-3.png?v=2023-08-31T07%3A51%3A35.567Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group-3.png?v=2023-08-31T07%3A51%3A35.567Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group-3.png?v=2023-08-31T07%3A51%3A35.567Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group-3.png?v=2023-08-31T07%3A51%3A35.567Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group-3.png?v=2023-08-31T07%3A51%3A35.567Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group-3.png?v=2023-08-31T07%3A51%3A35.567Z&quality=85&w=3200 3200w"
                    sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
                  />
                  <Box
                    display="flex"
                    align-items="flex-start"
                    flex-direction="column"
                    justify-content="flex-end"
                    padding="16px 0px 0px 0px"
                  >
                    <Text
                      margin="0px 0px 4px 0px"
                      color="--darkL1"
                      font="--headline4"
                      height="fit-content"
                      width="100%"
                    >
                      Hydraulic Components
                    </Text>
                    <Text
                      margin="0px 0px 4px 0px"
                      color="--darkL1"
                      font="--descirption3"
                      height="fit-content"
                      width="100%"
                    >
                      Introducing our OTR & Mining product – built for extreme
                      durability and high performance. Designed to excel in the
                      toughest conditions, it prioritizes safety and efficiency.
                      {"\n\n"}
                    </Text>
                  </Box>
                </Box>{" "}
              </SwiperSlide>
              <SwiperSlide className="">
                <Box
                  display="block"
                  quarkly-title="Product Card"
                  padding="16px 16px 16px 16px"
                  width="400px"
                  overflow-y="hidden"
                  border-width="1px"
                  border-style="solid"
                  border-color="--color-grey"
                  overflow-x="hidden"
                >
                  <Image
                    src="https://uploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group-3.png?v=2023-08-31T07:51:35.567Z"
                    display="block"
                    margin="0px 60px 0px 0px"
                    padding="0px 0px 0px 0px"
                    height="305px"
                    sm-width="100%"
                    sm-margin="0px 60px 0px 0px"
                    md-width="100%"
                    md-margin="0px 60px 0px 0px"
                    width="100%"
                    object-fit="cover"
                    srcSet="https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group-3.png?v=2023-08-31T07%3A51%3A35.567Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group-3.png?v=2023-08-31T07%3A51%3A35.567Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group-3.png?v=2023-08-31T07%3A51%3A35.567Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group-3.png?v=2023-08-31T07%3A51%3A35.567Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group-3.png?v=2023-08-31T07%3A51%3A35.567Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group-3.png?v=2023-08-31T07%3A51%3A35.567Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group-3.png?v=2023-08-31T07%3A51%3A35.567Z&quality=85&w=3200 3200w"
                    sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
                  />
                  <Box
                    display="flex"
                    align-items="flex-start"
                    flex-direction="column"
                    justify-content="flex-end"
                    padding="16px 0px 0px 0px"
                  >
                    <Text
                      margin="0px 0px 4px 0px"
                      color="--darkL1"
                      font="--headline4"
                      height="fit-content"
                      width="100%"
                    >
                      Hydraulic Components
                    </Text>
                    <Text
                      margin="0px 0px 4px 0px"
                      color="--darkL1"
                      font="--descirption3"
                      height="fit-content"
                      width="100%"
                    >
                      Introducing our OTR & Mining product – built for extreme
                      durability and high performance. Designed to excel in the
                      toughest conditions, it prioritizes safety and efficiency.
                      {"\n\n"}
                    </Text>
                  </Box>
                </Box>{" "}
              </SwiperSlide> */}
            </Swiper>
          </Box>
        </Box>
      </Section>
      <Section
        padding="0px 0 0px 0"
        background="--color-primary"
        height="fit-content"
      >
        <Override
          slot="SectionContent"
          margin="0px auto 0px auto"
          flex-direction="row"
          justify-content="space-between"
          width="100%"
          min-width="100%"
          sm-flex-direction="column"
          md-flex-direction="column"
        />
        <Image
          src="https://uploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Untitled%20design%20%2819%29.png?v=2023-08-11T08:26:03.467Z"
          display="block"
          width="45%"
          margin="0px 60px 0px 0px"
          padding="0px 0px 0px 0px"
          height="fit-content"
          sm-width="100%"
          sm-margin="0px 60px 0px 0px"
          md-width="100%"
          md-margin="0px 60px 0px 0px"
          alt="image"
          // srcSet="https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Untitled%20design%20%2819%29.png?v=2023-08-11T08%3A26%3A03.467Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Untitled%20design%20%2819%29.png?v=2023-08-11T08%3A26%3A03.467Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Untitled%20design%20%2819%29.png?v=2023-08-11T08%3A26%3A03.467Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Untitled%20design%20%2819%29.png?v=2023-08-11T08%3A26%3A03.467Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Untitled%20design%20%2819%29.png?v=2023-08-11T08%3A26%3A03.467Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Untitled%20design%20%2819%29.png?v=2023-08-11T08%3A26%3A03.467Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Untitled%20design%20%2819%29.png?v=2023-08-11T08%3A26%3A03.467Z&quality=85&w=3200 3200w"
          sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
        />
        <Box
          margin="0px 0px 0px 0px"
          width="57%"
          padding="12px 70px 12px 0px"
          align-items="flex-start"
          display="flex"
          flex-direction="column"
          justify-content="center"
          sm-width="100%"
          sm-padding="40px 29px 40px 29px"
          md-width="100%"
          md-padding="40px 38px 40px 38px"
        >
          <Text
            margin="0px 0px 2px 0px"
            font="--subheader1"
            color="#cecece"
            text-align="left"
            letter-spacing="1px"
            sm-font="--description4"
            id="6389104345"
            className="hoverText"
            onClick={() => isSideBarOpen("6389104345")}
          >
            {data ? data["6389104345"] : "Connect"}
          </Text>
          <Text
            margin="0px 0px 20px 0px"
            font="--headline2"
            color="--white"
            text-align="left"
            sm-font="--headline4"
            sm-margin="0px 0px 10px 0px"
            id="9602813314"
            className="hoverText "
            dangerouslySetInnerHTML={{
              __html: data ? data["9602813314"] : "Connect",
            }}
            onClick={() => isSideBarOpen("9602813314")}
          ></Text>
          <Text
            margin="0px 0px 28px 0px"
            font="--description3"
            color="#cecece"
            text-align="left"
            sm-font="--description4"
            id="7076291088"
            className="hoverText "
            dangerouslySetInnerHTML={{
              __html: data
                ? data["7076291088"]
                : "Looking for expert engineering solutions",
            }}
            onClick={() => isSideBarOpen("7076291088")}
          ></Text>
          <Box
            display="flex"
            align-items="center"
            sm-align-items="flex-start"
            sm-flex-direction="column"
            sm-width="100%"
          >
            <div
              id="1410615958"
              className="hoverText"
              onClick={() => isSideBarOpen("1410615958")}
            >
              <NavLink to={"/Product Listing"}>
                <Button
                  font="--button1"
                  sm-font="--button2"
                  color="--primary"
                  background="--color-white"
                  border-radius="0px"
                  margin="0px 24px 0px 0px"
                  sm-margin="0px 0px 14px 0px"
                  sm-width="100%"
                  height="fit-content"
                >
                  {data ? data["1410615958"] : "Explore"}
                </Button>
              </NavLink>
            </div>
            <Box
              sm-display="flex"
              sm-justify-content="space-between"
              sm-align-items="flex-start"
              sm-flex-direction="column"
              display="flex"
              align-items="flex-start"
              sm-width="100%"
            >
              <LinkBox
                padding="6px 0px 6px 0px"
                margin="0px 24px 0px 0px"
                display="flex"
                flex-direction="row"
                sm-margin="0px 0px 8px 0px"
                href="tel:+971543779204"
              >
                <Icon
                  category="md"
                  icon={MdCall}
                  size="64px"
                  width="38px"
                  height="38px"
                  color="--white"
                  margin="0px 14px 0px 0px"
                  sm-width="30px"
                  sm-height="30px"
                  id="1127945878"
                />
                <Text
                  margin="0px 0px 2px 0px"
                  font="--descirption3"
                  color="--white"
                  text-align="left"
                  sm-font="--descirption3"
                  id="8177497651"
                  className="hoverText"
                  onClick={() => isSideBarOpen("8177497651")}
                >
                  {data ? data["8177497651"] : "+971 54 377 9204"}
                </Text>
              </LinkBox>
              <LinkBox
                padding="6px 0px 6px 0px"
                display="flex"
                flex-direction="row"
                href="mailto:info@scvengineering.com"
              >
                <Icon
                  category="md"
                  icon={MdEmail}
                  size="64px"
                  width="38px"
                  height="38px"
                  color="--white"
                  margin="0px 14px 0px 0px"
                  sm-width="30px"
                  sm-height="30px"
                  id="2997334083"
                />
                <Text
                  margin="0px 0px 2px 0px"
                  font="--descirption3"
                  color="--white"
                  text-align="left"
                  sm-font="--descirption3"
                  id="2702983273"
                  className="hoverText"
                  onClick={() => isSideBarOpen("2702983273")}
                >
                  {data ? data["2702983273"] : "scvengineering"}
                </Text>
              </LinkBox>
            </Box>
          </Box>
        </Box>
      </Section>
      <EnquireForm>
        <Override slot="input" />
        <Override slot="input1" />
        <Override slot="input2" />
        <Override slot="input3" />
        <Override slot="input4" />
        <Override slot="text5" />
        <Override slot="text" />
        <Override slot="text1" />
        <Override slot="text2" />
        <Override slot="text3" />
        <Override slot="text4" />
        <Override slot="text6" />
        <Override slot="button" />
      </EnquireForm>
      <Footer>
        <Override slot="SectionContent" />
        <Override slot="text2" />
        <Override slot="text3" />
        <Override slot="box1" />
        <Override slot="text" />
        <Override slot="text1" />
        <Override slot="text4" />
        <Override slot="text5" />
        <Override slot="image" />
        <Override slot="icon" />
        <Override slot="icon1" />
        <Override slot="icon2" />
        <Override slot="text6" />
      </Footer>
      {isShow ? (
        <ContentUpdate
          selectedElement={selectedData}
          isShow={isShow}
          closeSidebar={closeSidebar}
        />
      ) : (
        ""
      )}
      <RawHtml>
        <style place={"endOfHead"} rawKey={"64d4bf2f6efcea001ae4fe4d"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  );
};
