import React, { useEffect } from "react";
import theme from "../theme";
import { Theme, Image, Box, Text, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "../global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/swiper.min.css";

import Header from "../components/Header";
import Footer from "../components/Footer";
import { useDispatch, useSelector } from "react-redux/es/exports";

import ImageGallery from "react-image-gallery";
import { addSingleSubCategoryAlbum } from "../redux/Product/product-action";

// const images = [
//   {
//     original: "https://picsum.photos/id/1018/1000/600/",
//     thumbnail: "https://picsum.photos/id/1018/250/150/",
//   },
//   {
//     original: "https://picsum.photos/id/1015/1000/600/",
//     thumbnail: "https://picsum.photos/id/1015/250/150/",
//   },
//   {
//     original: "https://picsum.photos/id/1019/1000/600/",
//     thumbnail: "https://picsum.photos/id/1019/250/150/",
//   },
// ];

export default () => {
  const dispatch = useDispatch();
  // let categoryData = useSelector((state) => state.product.Category);
  let categoryData = useSelector((state) => state.product.addSingleSubCategory);
  let selectedPortfolio = useSelector(
    (state) => state.product.selectedPortfolio
  );

  // const singlePortfolioData = useSelector(
  //   (state) => state.portfolio.singlePortfolio
  // );

  // let getSubCategoryData = useSelector(
  //   (state) => state.product.addSingleSubCategory
  // );

  // console.log(getSubCategoryData, "MANISH");

  // const portfolioData = useSelector((state) => state.portfolio.Portfolio);
  // const [reversePortfolioData, setReversePortfolioData] = useState(
  //   [...portfolioData].reverse()
  // );
  // console.log(singlePortfolioData, portfolioData, "16");

  // console.log(categoryData, "ALLALL");

  const handleImage = (album) => {
    return album.map((img) => ({
      original: `${process.env.REACT_APP_STORAGE_URL_2}${img}`,
      thumbnail: `${process.env.REACT_APP_STORAGE_URL_2}${img}`,
    }));
  };

  useEffect(() => {
    dispatch(addSingleSubCategoryAlbum());
  }, [dispatch]);

  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"product-overview"} />
      <Helmet>
        <title>SVC-Engineering</title>
        <meta
          name={"description"}
          content={"Web site created using quarkly.io"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://uploads.quarkly.io/readme/cra/favicon-32x32.ico"}
          type={"image/x-icon"}
        />
      </Helmet>

      <Header />

      <Section padding="100px 0 100px 0" sm-padding="10px">
        <Override
          slot="SectionContent"
          width="100%"
          margin="0px auto 0px auto"
          lg-max-width="none"
          lg-height="fit-content"
          flex-direction="row-reverse"
        />

        <Box
          display="flex"
          width="100%"
          flex-direction="row"
          quarkly-title="Product overview"
          sm-flex-direction="column"
          md-flex-direction="column"
          lg-flex-direction="column"
          lg-align-items="flex-start"
          align-items="flex-start"
        >
          <Box
            width="50%"
            display="flex"
            flex-direction="column"
            sm-margin="0px 0px 40px 0px"
            md-margin="0px 0px 60px 0px"
            lg-margin="0px 0px 60px 0px"
            lg-width="100%"
            lg-align-items="center"
            justify-content="space-between"
            margin="0px 40px 0px 0px"
          >
            {/* <Image
              src={
                singlePortfolioData.image
                  ? process.env.REACT_APP_STORAGE_URL_2 +
                    singlePortfolioData.image
                  : "https://uploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Vector%2041.svg?v=2023-08-10T11:28:36.165Z"
              }
              display="block"
              width="100%"
              object-fit="cover"
              height="100%"
              object-position="0% 80%"
              lg-width="100%"
              sm-width="100%"
              md-width="100%"
              background="--color-bgColor"
              margin="0px 0px 20px 0px"
              border-radius="14px"
              sm-border-radius="0px"
            /> */}
            {selectedPortfolio && selectedPortfolio.album ? (
              <ImageGallery
                items={handleImage(selectedPortfolio.album)}
                className="imgGallery"
              />
            ) : (
              "No Album"
            )}
          </Box>
          <Box
            width="50%"
            display="flex"
            flex-direction="column"
            lg-width="100%"
            sm-width="100%"
            md-width="100%"
            margin="0px 0px 0px 0px"
            align-items="flex-start"
            md-margin="0px 0px 0px 0px"
          >
            <Box
              display="flex"
              width="100%"
              margin="0px 0px 20px 0px"
              sm-margin="0px 0px 8px 0px"
              justify-content="space-between"
              align-items="flex-start"
            >
              <Box
                display="block"
                justify-content="space-between"
                height="fit-content"
                align-items="center"
                margin="0px 0px 0px 0px"
                sm-width="100%"
                width="50%"
              >
                <Text
                  font="--headline2"
                  sm-font="--headline4"
                  margin="0px 0px 0px 0px"
                  md-width="fit-content"
                  color="--primary"
                  width="400px"
                >
                  {selectedPortfolio && selectedPortfolio.name
                    ? selectedPortfolio.name
                    : "Name"}
                </Text>
              </Box>
            </Box>
            <Box
              display="block"
              sm-width="100%"
              width="100%"
              flex-direction="column"
              align-items="flex-start"
              margin="0px 0px 60px 0px"
              sm-margin="0px 0px 20px 0px"
            >
              <Text
                margin="0px 0px 40px 0px"
                sm-margin="0px 0px 20px 0px"
                display="flex"
                align-items="flex-end"
                font="--description1"
                sm-font="--description3"
                color="--darkL2"
              >
                {selectedPortfolio && selectedPortfolio.description
                  ? selectedPortfolio.description
                  : "Name"}
              </Text>
            </Box>
          </Box>
        </Box>
      </Section>
      <Section
        sm-padding="24px 10px"
        quarkly-title="Product"
        align-items="center"
        justify-content="center"
        position="static"
        md-align-items="flex-start"
        md-justify-content="flex-start"
        md-height="fit-content"
      >
        <Override
          slot="SectionContent"
          display="block"
          padding="0px 0px 0px 0px"
          sm-margin="0px"
          width="100%"
          md-display="flex"
          md-align-items="flex-start"
          md-height="fit-content"
          lg-margin="60px auto 0px auto"
          margin="48px 80px 48px 80px"
        />
        <Text
          margin="0px 0px 2px 0px"
          font="normal 600 18px/26px --fontFamily-googlePoppins"
          color="--darkGrey"
          text-align="left"
          id="8707874733"
        >
          Products
        </Text>
        <Box
          sm-height="fit-content"
          width="100%"
          quarkly-title="Headphones For You"
          md-display="flex"
          md-flex-direction="column"
          md-height="fit-content"
        >
          <Box
            display="flex"
            justify-content="space-between"
            margin="0px 0px 50px 0px"
            sm-margin="0px 0px 20px 0px"
          >
            <Text
              margin="0px 0px 0px 0px"
              font="normal 600 36px/130% --fontFamily-googlePoppins"
              color="--primary"
              text-align="left"
              sm-font="--headline5"
              sm-width="100%"
              id="6518410230"
            >
              Expert Solutions with Experties
            </Text>
            {/* <Button
              font="--button1"
              color="--primary"
              width="fit-content"
              padding="10px 24px 10px 24px"
              height="fit-content"
              background="#ffffff"
              sm-width="fit-content"
              id="1937871613"
            >
              View All
            </Button> */}
          </Box>
          <Box
            lg-width="100%"
            md-width="fit-content"
            sm-width="100%"
            className="container mx-auto overflow-hidden"
          >
            <Swiper
              observer={true}
              observeParents={true}
              modules={[Navigation]}
              className="mySwiper"
              navigation={{
                nextEl: ".image-swiper-button-next",
                prevEl: ".image-swiper-button-prev",
              }}
              breakpoints={{
                1536: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                1280: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                991: {
                  slidesPerView: 1,
                  spaceBetween: 12,
                },
                640: {
                  slidesPerView: 1,
                  spaceBetween: 12,
                },
                425: {
                  slidesPerView: 1,
                  spaceBetween: 12,
                },
                325: {
                  slidesPerView: 1,
                  spaceBetween: 12,
                },
              }}
            >
              <div className="swiper-button image-swiper-button-prev cursor-pointer">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
                </svg>
              </div>
              <div className="swiper-button image-swiper-button-next cursor-pointer">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
                </svg>
              </div>
              {categoryData.result
                ? categoryData.result.reverse().map((item, i) => {
                    return (
                      <SwiperSlide className="" key={i}>
                        <Box
                          display="block"
                          padding="16px 16px 16px 16px"
                          sm-padding="10px"
                          border-width="1px"
                          border-style="solid"
                          width="100%"
                          sm-width="auto"
                          sm-margin="0px 10px 0px 0px"
                          border-color="--color-grey"
                        >
                          <Image
                            src={
                              item.image
                                ? process.env.REACT_APP_STORAGE_URL_2 +
                                  item.image
                                : "https://uploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Group%205565.png?v=2023-08-31T07:43:44.271Z"
                            }
                            height="305px"
                            sm-height="215px"
                            width="100%"
                            sm-width="100%"
                            object-fit="cover"
                          />
                          <Box
                            display="flex"
                            align-items="flex-start"
                            flex-direction="column"
                            justify-content="flex-end"
                            padding="16px 0px 0px 0px"
                          >
                            <Text
                              margin="0px 0px 4px 0px"
                              color="--darkL1"
                              font="--headline4"
                            >
                              {item.name}
                            </Text>
                            <Text
                              margin="0px 0px 4px 0px"
                              color="--darkL1"
                              font="--descirption3"
                            >
                              {item.description}
                            </Text>
                          </Box>
                        </Box>
                      </SwiperSlide>
                    );
                  })
                : "no-data"}
            </Swiper>
          </Box>
        </Box>
      </Section>
      <Footer>
        <Override slot="SectionContent" flex-direction="column" />
        <Override slot="linkBox" sm-width="116px" sm-align-items="center" />
        <Override slot="linkBox1" sm-width="116px" sm-align-items="center" />
        <Override slot="linkBox2" sm-width="116px" sm-align-items="center" />
        <Override slot="linkBox3" sm-width="116px" sm-align-items="center" />
        <Override slot="linkBox4" sm-width="116px" sm-align-items="center" />
        <Override slot="linkBox5" sm-width="116px" sm-align-items="center" />
      </Footer>
      <RawHtml>
        <style place={"endOfHead"} rawKey={"64d4bf2f6efcea001ae4fe4d"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  );
};
