const initialState = {
  //   project: {},
  Portfolio: [],
};
const PortfolioReducer = (state = initialState, action) => {
  switch (action.type) {
    case "setPortfolio":
      return { ...state, Portfolio: action.payload };
    case "ADD_SINGLE_PORTFOLIO":
      return {
        ...state,
        singlePortfolio: action.payload,
      };
    default:
      return state;
  }
};

export default PortfolioReducer;
