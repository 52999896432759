import React, { useEffect, useState } from "react";
import theme from "../theme";
import { Theme, Text, Section, Link, Box, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "../global-page-styles";
import { RawHtml, Override, SocialMedia } from "@quarkly/components";
// import Nav from "../components/Nav";
import Header from "../components/Header";
import { createContact } from "../redux/marcom/marcom-action";
import Footer from "../components/Footer";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
//CMS
import ContentUpdate from "../components/CMS/ContentUpdate";
import { setSelectedData } from "../redux/CMS/cms-actions";
// import { Textarea } from "@quarkly/community-kit";
import { useFormik } from "formik";
import * as Yup from "yup";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const validationSchema = Yup.object({
  username: Yup.string()
    .trim()
    .matches(/^[A-Za-z]+(\s[A-Za-z]+)*$/, "Only alphabetic characters allowed")
    .min(2, "Full Name must be at least 2 character")
    .max(50, "Full Name must be 50 characters or less")
    .required("Full Name is required"),
  companyName: Yup.string()
    .trim()
    .matches(/^[^\s]+(\s[^\s]+)*[^\s]$/, "Invalid Format")
    .min(2, "Full Name must be at least 2 character")
    .max(50, "Full Name must be 50 characters or less")
    .required("Company Name is required"),
  phoneNumber: Yup.string()
    .matches(phoneRegExp, "Please enter valid mobile number")
    .required("Mobile number is required")
    .min(10, "Enter valid number")
    .max(10, "Enter valid number"),
  email: Yup.string().email("Not Valid Email").required("Email id is required"),
  message: Yup.string()
    .required("Message is required")
    .max(500, "Field cannot exceed 500 characters"),
});

const ErrorComponent = ({ error }) => (
  <div style={{ color: "red", marginTop: "5px", fontSize: "14px" }}>
    {error}
  </div>
);

export default () => {
  const formik = useFormik({
    initialValues: {
      username: "",
      companyName: "",
      phoneNumber: "",
      email: "",
      message: "",
    },
    validationSchema: validationSchema,
  });

  const dispatch = useDispatch();
  // eslint-disable-next-line
  const [mounted, setMounted] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [selectedData, updateSelectedData] = useState("update");
  let data = useSelector((state) => state.cms.pages["Contact Us"]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    let payload = {
      name: formik.values.username.trim(),
      mobile: formik.values.phoneNumber.trim(),
      email: formik.values.email.trim(),
      company: formik.values.companyName.trim(),
      description: formik.values.message.trim(),
    };

    if (
      payload.name !== "" &&
      payload.company !== "" &&
      payload.mobile !== "" &&
      payload.email !== "" &&
      payload.description !== "" &&
      Object.keys(formik.errors).length === 0
    ) {
      let data = await dispatch(createContact(payload));
      console.log(data, "contact");

      if (data.code === 200) {
        formik.resetForm();
        Swal.fire({
          title: "Congratulations",
          text: "Your query been successfully sent.",
          icon: "success",
        });
      }
    } else {
      Swal.fire({
        title: "Error",
        text: "Please fill all the required details",
        icon: "error",
      });
    }
  };

  const { token } = useParams();

  if (token) {
    let cmsStyle = document.getElementsByClassName("hoverText");

    for (let i = 0; i < cmsStyle.length; i++) {
      cmsStyle[i].onClick = function () {
        return false;
      };
      cmsStyle[i].classList.add("hoverText1");
    }
  }

  async function isSideBarOpen(id) {
    if (token) {
      await updateSelectedData(id);
      // selectedData = id;
      setIsShow(true);

      console.log(isShow, selectedData, id, "show");
    }
  }

  function closeSidebar(value) {
    setIsShow(value);
    updateSelectedData("");
    // selectedData = "";
    dispatch(setSelectedData({}));
    console.log(selectedData, "show");
  }

  useEffect(() => {
    setMounted(true);
  }, [dispatch, setMounted]);
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"contact"} />
      <Helmet>
        <title>SCV-Engineering</title>
        <meta
          name={"description"}
          content={"Web site created using quarkly.io"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://uploads.quarkly.io/readme/cra/favicon-32x32.ico"}
          type={"image/x-icon"}
        />
      </Helmet>
      <Header />
      {/* <Nav>
        <Override
          slot="text4"
          color="--primary"
          font="700 16px --fontFamily-googlePoppins"
        />
        <Override
          slot="text"
          color="--grey"
          font="500 16px --fontFamily-googlePoppins"
        />
      </Nav> */}
      <Section
        padding="0px 0 0px 0"
        background="linear-gradient(0deg,rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%),rgba(0, 0, 0, 0) url(https://images.unsplash.com/photo-1455165814004-1126a7199f9b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000) 0% 0% /auto repeat scroll padding-box"
        height="350px"
      >
        <Override
          slot="SectionContent"
          width="100%"
          margin="0px 0px 0px 0px"
          max-width="100%"
          font="--headline1"
          align-items="center"
          justify-content="center"
          id="8375746680"
        />
        <Text
          font="--headline1"
          color="--white"
          text-align="center"
          sm-font="--headline2"
          letter-spacing="1px"
          id="3728937369"
          className="hoverText"
          onClick={() => isSideBarOpen("3728937369")}
        >
          {data ? data["3728937369"] : "CONTACT US"}
        </Text>
      </Section>
      <Section padding="90px 0 100px 0">
        <Override slot="SectionContent" flex-direction="row" />
        <Box display="flex" sm-display="block">
          <Box
            display="flex"
            align-items="flex-start"
            flex-direction="column"
            justify-content="center"
            lg-padding="0px 0px 0px 0px"
            sm-width="100%"
            margin="0px 50px 0px 0px"
            md-margin="0px 10px 0px 0px"
            sm-margin="0px 50px 40px 0px"
            width="50%"
          >
            <Text
              margin="0px 0px 4px 0px"
              font="--subheader1"
              color="--darkGrey"
              text-align="left"
              letter-spacing="1px"
              sm-font="--description4"
              id="2534337874"
              className="hoverText"
              onClick={() => isSideBarOpen("2534337874")}
            >
              {data ? data["2534337874"] : "Know Us"}
            </Text>
            <Text
              margin="0px 0px 14px 0px"
              font="--headline2"
              md-font="--headline3"
              color="--primary"
              text-align="center"
              sm-font='normal 700 24px/1.2 "Source Sans Pro", sans-serif'
              id="0865151332"
              className="hoverText"
              onClick={() => isSideBarOpen("0865151332")}
            >
              {data ? data["0865151332"] : "Contact Us"}
            </Text>
            <Text
              margin="0px 0px 40px 0px"
              sm-margin="0px 0px 20px 0px"
              font="--description1"
              color="--darkGrey"
              text-align="left"
              id="4732550431"
              sm-font-size="14px"
              className="hoverText "
              dangerouslySetInnerHTML={{
                __html: data
                  ? data["4732550431"]
                  : "Curabitur lobortis id lorem id",
              }}
              onClick={() => isSideBarOpen("4732550431")}
            ></Text>
            <Link
              href="mailto:blank?hello@company.com"
              text-align="center"
              color="--primary"
              font="--subheader3"
              text-decoration-line="initial"
              sm-font-size="20px"
              margin="0px 0px 16px 0px"
              sm-margin="0px 0px 6px 0px"
              id="9050737769"
              className="hoverText"
              onClick={() => isSideBarOpen("9050737769")}
            >
              {data ? data["9050737769"] : "info@scvengineering.com"}
            </Link>
            <Link
              href="tel:1234567899"
              text-align="center"
              color="--primary"
              font="--subheader3"
              sm-font-size="20px"
              text-decoration-line="initial"
              margin="0px 0px 16px 0px"
              sm-margin="0px 0px 6px 0px"
              id="8480995211"
              className="hoverText"
              onClick={() => isSideBarOpen("8480995211")}
            >
              {data ? data["8480995211"] : "+971 54 377 9204"}
            </Link>
            <Text
              margin="0px 0px 48px 0px"
              sm-margin="0px 0px 34px 0px"
              font="--subheader3"
              sm-font-size="20px"
              color="--primary"
              text-align="left"
              id="2215864759"
              className="hoverText"
              onClick={() => isSideBarOpen("2215864759")}
            >
              {data
                ? data["2215864759"]
                : "SCV Engineering LLC,P.O.Box 48116, Dubai. United Arab Emirates."}
            </Text>
            <SocialMedia
              linked-in="https://www.linkedin.com/company/scv-engineering-l-l-c/"
              // facebook="https://www.facebook.com/quarklyapp/"
              // twitter="https://twitter.com/quarklyapp"
              instagram="https://instagram.com/scvengineering?igsh=MjJrM2hyb3hsYm95"
              margin="0px 0px 0px 0px"
            >
              <Override
                slot="link"
                border-radius="50%"
                color="--grey"
                hover-color="--light"
                background="--color-primary"
                hover-background="--color-primary"
                margin="0 5px 0 5px"
                padding="5x 5px 5px 5px"
                width="48px"
                height="48px"
                align-items="center"
                display="flex"
                justify-content="center"
                hover-transition="background-color 0.2s ease-in-out 0s"
                transition="background-color 0.2s ease-in-out 0s"
              />
              <Override
                slot="icon"
                color="--light"
                padding="7px 7px 7px 7px"
                border-width="0px"
                size="38px"
                border-radius="50px"
                id="7773701595"
              />
            </SocialMedia>
          </Box>
          <Box
            sm-width="100%"
            display="flex"
            flex-direction="column"
            width="50%"
          >
            <Box
              display="flex"
              margin="0px 0px 20px 0px"
              sm-flex-direction="column"
            >
              <Box
                width="50%"
                margin="0px 22px 0px 0px"
                sm-width="100%"
                md-margin="0px 12px 10px 0px"
                sm-margin="0px 22px 10px 0px"
              >
                <Text
                  margin="0px 0px 6px 0px"
                  font="--descirption3"
                  color="--darkL2"
                  text-align="left"
                  id="9632262215"
                  className="hoverText"
                  onClick={() => isSideBarOpen("9632262215")}
                >
                  {data ? data["9632262215"] : "Your name"}
                </Text>
                <input
                  className="inputs"
                  name="username"
                  id="username"
                  required
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Enter Full Name"
                />
                {formik.touched.username && formik.errors.username && (
                  <ErrorComponent error={formik.errors.username} />
                )}
              </Box>
              <Box width="50%" sm-width="100%">
                <Text
                  margin="0px 0px 6px 0px"
                  font="--descirption3"
                  color="--darkL2"
                  text-align="left"
                  id="7340584892"
                  className="hoverText"
                  onClick={() => isSideBarOpen("7340584892")}
                >
                  {data ? data["7340584892"] : "Company Name"}
                </Text>
                <input
                  className="inputs"
                  name="companyName"
                  id="companyName"
                  required
                  value={formik.values.companyName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Enter Company Name"
                />
                {formik.touched.companyName && formik.errors.companyName && (
                  <ErrorComponent error={formik.errors.companyName} />
                )}
              </Box>
            </Box>
            <Box
              display="flex"
              margin="0px 0px 20px 0px"
              sm-flex-direction="column"
            >
              <Box
                width="50%"
                margin="0px 22px 0px 0px"
                md-margin="0px 12px 0px 0px"
                sm-margin="0px 0px 10px 0px"
                sm-width="100%"
              >
                <Text
                  margin="0px 0px 6px 0px"
                  font="--descirption3"
                  color="--darkL2"
                  text-align="left"
                  id="0925412690"
                  className="hoverText"
                  onClick={() => isSideBarOpen("0925412690")}
                >
                  {data ? data["0925412690"] : "Phone number"}
                </Text>
                <input
                  className="inputs"
                  required
                  placeholder="Enter Phone number"
                  type="text"
                  id="phoneNumber"
                  name="phoneNumber"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phoneNumber}
                />
                {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                  <ErrorComponent error={formik.errors.phoneNumber} />
                )}
              </Box>
              <Box width="50%" sm-width="100%">
                <Text
                  margin="0px 0px 6px 0px"
                  font="--descirption3"
                  color="--darkL2"
                  text-align="left"
                  id="4154983273"
                  className="hoverText"
                  onClick={() => isSideBarOpen("4154983273")}
                >
                  {data ? data["4154983273"] : "Email"}
                </Text>
                <input
                  className="inputs"
                  required
                  placeholder="Enter Email ID"
                  type="email"
                  id="email"
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />

                {formik.touched.email && formik.errors.email && (
                  <ErrorComponent error={formik.errors.email} />
                )}
              </Box>
            </Box>
            <Box
              width="100%"
              display="flex"
              flex-direction="column"
              margin="0px 0px 30px 0px"
            >
              <Text
                margin="0px 0px 6px 0px"
                font="--descirption3"
                color="--darkL2"
                text-align="left"
                id="5538224998"
                className="hoverText"
                onClick={() => isSideBarOpen("5538224998")}
              >
                {data ? data["5538224998"] : "Message"}
              </Text>
              <input
                display="flex"
                placeholder-color="black"
                padding="16px 16px 16px 16px"
                placeholder="Enter Message"
                width="100%"
                height="90px"
                align-items="flex-start"
                placeholder-justify-content="flex-start"
                placeholder-align-items="flex-start"
                sm-padding="0px 16px 0px 16px"
                sm-height="180px"
                sm-justify-content="flex-start"
                border-color="#b4b4b4"
                className="inputs textarea"
                type="message"
                id="message"
                name="message"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.message}
              />
              {formik.touched.message && formik.errors.message && (
                <ErrorComponent error={formik.errors.message} />
              )}
            </Box>
            <Box
              align-items="center"
              display="flex"
              justify-content="center"
              id="9161040822"
              className="hoverText"
              onClick={() => isSideBarOpen("9161040822")}
            >
              <Button
                font="--button1"
                color="--white"
                background="--color-primary"
                border-radius="0px"
                margin="0px 24px 0px 0px"
                padding="12px 24px 12px 24px"
                className="hoverText"
                onClick={handleSubmit}
              >
                {data ? data["9161040822"] : "Submit"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Section>
      <Footer>
        <Override slot="SectionContent" />
        <Override slot="icon2" />
      </Footer>
      {isShow ? (
        <ContentUpdate
          selectedElement={selectedData}
          isShow={isShow}
          closeSidebar={closeSidebar}
        />
      ) : (
        ""
      )}
      <RawHtml>
        <style place={"endOfHead"} rawKey={"64d4bf2f6efcea001ae4fe4d"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  );
};
