import React from "react";
// import Logo from "../../../assets/image/logo.png";
import Whatsapp from "../../../assets/image/WhatsApp.png";
import Phone from "../../../assets/image/Phone.png";

const Banner = () => {
  return (
    <div className="h-700px relative bg-image p-4">
      <div className=" absolute top-0 left-0 h-700px flex items-center justify-center w-full">
        <div className="flex justify-center flex-col">
          <div className="image">
            <img
              src="https://nimbuscluster.blob.core.windows.net/server01/scv-engineering/cms/logo12.png"
              alt="banner"
              className=""
            />
          </div>
          <div className="flex justify-center flex-col lg:mt-16 mt-0">
            <p className="comingsoon text-center">Coming Soon</p>
            <p className="txt-white lg:text-xl texl-lg text-center lg:py-4">
              Website under maintenance
            </p>
          </div>
          <div className="flex justify-center gap-4 mt-4">
            <a
              href="https://wa.me/+971543779204"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Whatsapp} alt="Whatsapp" className="w-8" />
            </a>
            <a href="tel:+971 54 377 9204">
              <img src={Phone} alt="Phone" className="w-8" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
