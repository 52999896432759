import API from "./index";
// const get = "get";
// const create = "create";
// const update = "update";

const portfolioRoute = () => {
  return `portfoliomgmt/getPortfolio`;
};

export default {
  getAllPortfolio(payload) {
    return API.post(portfolioRoute(), payload);
  },
};
// const blogCategoryRoute = (route) => {
//   return `getPortfolio/category/${route}`;
// };
