import API from "./index";

const get = "get";
const create = "create";
const update = "update";

const blogRoute = (route) => {
  return `blogs/${route}`;
};

const blogCategoryRoute = (route) => {
  return `blogs/category/${route}`;
};

export default {
  getAllCategory() {
    return API.post(blogCategoryRoute(get));
  },

  createCategory(payload) {
    return API.post(blogCategoryRoute(create), payload);
  },

  updateCategory(payload) {
    return API.post(blogCategoryRoute(update), payload);
  },

  getAllBlogs() {
    return API.post(blogRoute(get));
  },

  createBlog(payload) {
    return API.post(blogRoute(create), payload);
  },

  updateBlogs(payload) {
    return API.post(blogRoute(update), payload);
  },
};
