import React, { useState, useEffect } from "react";
import theme from "../../../theme";
import { Theme, Text, Box, Section, Input } from "@quarkly/widgets";
// import { Helmet } from "react-helmet";
// import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";

import { useDispatch, useSelector } from "react-redux/es/exports";
import { createContact } from "../../../redux/marcom/marcom-action";

// import GoogleMapReact from "google-map-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
// import {
//   addSingleProduct,
//   product_reducer,
//   defaultImage,
//   defaultVariantData,
// } from "redux/Product/product-action";
// import {
//   getCms,
//   getElementById,
//   getUser,
//   setSelectedData,
//   setCmsActive,
// } from "redux/CMS/cms-actions";
import { useParams } from "react-router-dom";
// import ContentUpdate from "components/CMS/ContentUpdate";
import { getBrandProfile } from "../../../redux/BrandDetails/bd-actions";
export default () => {
  // const AnyReactComponent = ({ text }) => <div>{text}</div>;
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [companyName, setcompanyName] = useState("");
  const [location, setLocation] = useState("");
  const dispatch = useDispatch();

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handlephoneChange = (event) => {
    setPhone(event.target.value);
  };

  const handlecompanyNameChange = (event) => {
    setcompanyName(event.target.value);
  };

  const handlelocationChange = (event) => {
    setLocation(event.target.value);
  };
  const handleemailChange = (event) => {
    setEmail(event.target.value);
  };
  const handlemessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let payload = {
      name: name,
      mobile: phone,
      email: email,
      description: message,
      companyName: companyName,
      location: location,
    };
    if (
      payload.name !== "" ||
      payload.mobile !== "" ||
      payload.description !== "" ||
      payload.email !== "" ||
      payload.companyName !== "" ||
      payload.location !== ""
    ) {
      let data = await dispatch(createContact(payload));
      console.log(data, "DATA");

      if (data.code === 200) {
        Swal.fire({
          title: "Congratulations",
          text: "Your query been successfully send.",
          icon: "success",
        });

        console.log(data.code, "registercode");
      } else {
        // alert(data.message);
        Swal.fire({
          title: "Errow",
          text: "Please enter valid credential",
          icon: "error",
        });
        console.log(data.message, "datamessage");
      }
    }
  };
  // const MapProps = {
  //   center: {
  //     lat: 10.99835602,
  //     lng: 77.01502627,
  //   },
  //   zoom: 11,
  // };
  // const [isShow, setIsShow] = useState(false);
  // eslint-disable-next-line
  const [mounted, setMounted] = useState(false);
  // const [selectedData, updateSelectedData] = useState("update");
  const BrandDetails = useSelector((state) => state.brandDetails.brandProfile);

  console.log(BrandDetails, "outside in");
  let project = useSelector((state) => state.userDetails.project);
  // let data = useSelector((state) => state.cms.pages["Contact Us"]);
  // let userData = useSelector((state) => state.cms.userData);
  // let productData = useSelector((state) => state.product.product);
  // let brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  //-----------hide/show-------
  const { token } = useParams();

  if (token) {
    let cmsStyle = document.getElementsByClassName("hoverText");

    for (let i = 0; i < cmsStyle.length; i++) {
      cmsStyle[i].onClick = function () {
        return false;
      };
      cmsStyle[i].classList.add("hoverText1");
    }
  }
  //----------------hide/show-----------

  useEffect(() => {
    dispatch(getBrandProfile());
    console.log("Home");
    setMounted(true);
    dispatch(
      getBrandProfile({
        projectId: project._id,
        projectName: project.projectName,
      })
    );
  }, [dispatch, setMounted, project._id, project.projectName]);

  function applyThemeToDocument() {
    if (BrandDetails && BrandDetails.hasOwnProperty("primaryColor"))
      document.body.style.setProperty(
        "--qtheme-color-primary",
        BrandDetails.primaryColor
      );

    if (BrandDetails && BrandDetails.hasOwnProperty("secondayColor"))
      document.body.style.setProperty(
        "--qtheme-color-seconday",
        BrandDetails.secondayColor
      );

    if (BrandDetails && BrandDetails.hasOwnProperty("tirtiaryColor"))
      document.body.style.setProperty(
        "--qtheme-color-tirtiary",
        BrandDetails.tirtiaryColor
      );
  }
  applyThemeToDocument(theme);
  // if (mounted) {
  //   if (token === userData.token) {
  //     const ids = Object.keys(data);
  //     console.log(ids, "keys");

  //   }
  // }
  // let selectedData;
  // async function isSideBarOpen(id) {
  //   if (token) {
  //     await updateSelectedData(id);
  //     // selectedData = id;
  //     setIsShow(true);

  //     console.log(isShow, selectedData, id, "show");
  //   }
  // }

  // function closeSidebar(value) {
  //   setIsShow(value);
  //   updateSelectedData("");
  //   // selectedData = "";
  //   dispatch(setSelectedData({}));
  //   console.log(selectedData, "show");
  // }

  return (
    <Theme theme={theme}>
      {/* <GlobalQuarklyPageStyles pageUrl={"index211"} /> */}
      {/* <Components.Nav /> */}

      <Section
        background="--backgroundDark"
        position="relative"
        z-index="0"
        align-items="center"
        justify-content="center"
        sm-padding="0 0 0 0"
        padding="50px 0 50px 0"
      >
        <Override
          slot="SectionContent"
          align-items="center"
          justify-content="center"
          max-width="100%"
          sm-margin="24px 0 24px 0"
          md-margin="16px auto 16px auto"
          margin="0px 80px 0px 80px"
        />

        <Box
          min-width="100px"
          min-height="100px"
          display="flex"
          justify-content="center"
          sm-flex-direction="column"
          align-items="center"
          height="max-content"
          width="100%"
          className="cust-w100"
        >
          <Box
            min-width="100px"
            min-height="100px"
            width="50%"
            lg-width="100%"
            md-width="100%"
            sm-width="100%"
            display="flex"
            flex-direction="column"
          >
            <Text className="contact m-0px">Connect Us</Text>
            <Text className="text-center lg:pb-4 text-121127 sm-fsize14">
              SCV Engineering LLC,P.O.Box 48116, Dubai. United Arab Emirates.
              Phone: 00971 42565950 Mob: 00971 50 4229204 Email -
              info@scvengineering.com
            </Text>
            <Box
              display="flex"
              align-items="center"
              margin="0px 0px 32px 0px"
              sm-display="block"
              sm-margin="16px 0px 16px 0px"
              lg-margin="0px 0px 24px 0px"
            >
              <Box width="100%" margin="0px 24px 0px 0px">
                <span className="pb-2">Your name</span>
                <Input
                  placeholder-color="#121127ad"
                  width="100%"
                  height="40px"
                  border-width="1px"
                  border-style="solid"
                  placeholder="Your name"
                  display="flex"
                  sm-margin="0px 0 16px 0px"
                  border-color="--color-darkL1"
                  background="rgba(255, 255, 255, 0)"
                  value={name}
                  onChange={handleNameChange}
                  color="--darkL1"
                />
              </Box>
              <Box width="100%">
                <span>Company name</span>
                <Input
                  display="block"
                  placeholder-color="#121127ad"
                  width="100%"
                  height="40px"
                  border-width="1px"
                  border-style="solid"
                  placeholder="Company name"
                  border-color="--color-darkL1"
                  background="rgba(255, 255, 255, 0)"
                  color="--darkL1"
                  value={companyName}
                  onChange={handlecompanyNameChange}
                />
              </Box>
            </Box>
            <Box
              display="flex"
              align-items="center"
              margin="0px 0px 32px 0px"
              sm-display="block"
              sm-margin="0px 0px 16px 0px"
              lg-margin="0px 0px 24px 0px"
            >
              <Box width="100%" margin="0px 24px 0px 0px">
                <span>Phone Number*</span>
                <Input
                  placeholder-color="#121127ad"
                  width="100%"
                  height="40px"
                  border-width="1px"
                  border-style="solid"
                  placeholder="Phone Number*"
                  display="flex"
                  sm-margin="0px 0 16px 0px"
                  border-color="--color-darkL1"
                  background="rgba(255, 255, 255, 0)"
                  color="--darkL1"
                  type="number"
                  value={phone}
                  onChange={handlephoneChange}
                />
              </Box>

              <Box width="100%" margin="0px 0px 0px 0px">
                <span>Email ID*</span>
                <Input
                  display="block"
                  placeholder-color="#121127ad"
                  width="100%"
                  height="40px"
                  border-width="1px"
                  border-style="solid"
                  placeholder="Email ID"
                  border-color="--color-darkL1"
                  background="rgba(255, 255, 255, 0)"
                  color="--darkL1"
                  id="0302473180"
                  value={email}
                  onChange={handleemailChange}
                />
              </Box>
            </Box>
            <Box
              width="100%"
              margin="0px 0px 32px 0px"
              sm-display="block"
              sm-margin="0px 0px 16px 0px"
              lg-margin="0px 0px 24px 0px"
            >
              <span>Location</span>
              <Input
                display="block"
                placeholder-color="#121127ad"
                width="100%"
                height="40px"
                border-width="1px"
                border-style="solid"
                placeholder="Location"
                border-color="--color-darkL1"
                background="rgba(255, 255, 255, 0)"
                color="--darkL1"
                value={location}
                onChange={handlelocationChange}
              />
            </Box>
            <Box
              display="flex"
              align-items="center"
              margin="0px 0px 32px 0px"
              lg-margin="0px 0px 24px 0px"
            >
              <Box className="" width="100%">
                <p>Message</p>
                <textarea
                  display="block"
                  placeholder-color="#121127ad"
                  width="100%"
                  margin="0px 0px 0px 0px"
                  height="60px"
                  border-width="1px"
                  border-style="solid"
                  border-color="--color-darkL1"
                  background="rgba(255, 255, 255, 0)"
                  color="--darkL1"
                  placeholder="Enter Message..."
                  type="textarea"
                  className="c-textareatype"
                  value={message}
                  onChange={handlemessageChange}
                />
              </Box>
            </Box>
            <div className="w-full flex justify-center " onClick={handleSubmit}>
              <Text className="txt-white button">Submit</Text>
            </div>
          </Box>
        </Box>
      </Section>

      <RawHtml>
        <style place={"endOfHead"} rawKey={"6459d5984b38c40020065810"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  );
};
