import axios from "axios";

const baseURL = process.env.REACT_APP_BASE_URL;

const API = axios.create({
  baseURL,
  headers: {
    "community-name": "scv-engineering",
    accept: "application/json",
  },
});
// console.log(baseURL, "wrp");
export default API;
