const initialState = {
  //   project: {},
  Courses: [],
  Category: [],
  addSingleCourse: {},
};
const setCourse = (state = initialState, action) => {
  switch (action.type) {
    case "setCourse":
      return { ...state, Courses: action.payload };

    case "setCategory":
      return { ...state, Category: action.payload };

    case "ADD_SINGLE_Course":
      return {
        ...state,
        addSingleCourse: action.payload,
      };
    default:
      return state;
  }
};

export default setCourse;
