const initialState = {
  Category: [],
  Product: [],
  Subcategory: [],
  addSingleSubCategory: {},
  addSingleSubCategoryAlbum: {},
  selectedPortfolio: {},
  isLoadingCat: false,
};
const Product_reducer = (state = initialState, action) => {
  switch (action.type) {
    case "setProductCategory":
      return { ...state, Category: action.payload };
    case "setIsLoadingCat":
      return { ...state, isLoadingCat: action.payload };
    case "setProduct":
      return { ...state, Product: action.payload };
    case "setProductSubCategory":
      return { ...state, Subcategory: action.payload };
    case "setSelectedPortfolio":
      return { ...state, selectedPortfolio: action.payload };
    case "ADD_SINGLE_SubCategory":
      return {
        ...state,
        addSingleSubCategory: action.payload,
      };

    case "ADD_SINGLE_SubCategoryAlbum":
      return {
        ...state,
        addSingleSubCategoryAlbum: action.payload,
      };
    default:
      return state;
  }
};

export default Product_reducer;
