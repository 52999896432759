import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import QAPI from "./qapi";
import "../src/assets/swiper.css";
import "../src/assets/styles/tailwind.css";
import "../src/assets/styles/tailwind.generated.css";
import "../src/assets/index.css";
import "../src/assets/index.scss";
import "../src/assets/CMS.scss";
import { Provider } from "react-redux";
import { store } from "./redux/store";

window.QAPI = QAPI;
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
