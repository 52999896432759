import React from "react";
import { useOverrides, Override, SocialMedia } from "@quarkly/components";
import { Text, LinkBox, Box, Section, Image } from "@quarkly/widgets";
import { AiFillFacebook, AiFillInstagram } from "react-icons/ai";
import { FaLinkedin } from "react-icons/fa";
import { useSelector } from "react-redux/es/exports";
import { NavLink } from "react-router-dom";
const defaultProps = {
  padding: "0px 0 0px 0",
  "flex-direction": "column",
};
const overrides = {
  section: {
    kind: "Section",
    props: {
      background: "--color-primary",
    },
  },
  sectionOverride: {
    kind: "Override",
    props: {
      slot: "SectionContent",
      margin: "0px auto 0px auto",
      display: "flex",
      "flex-direction": "row",
    },
  },
  box: {
    kind: "Box",
    props: {
      "min-height": "10px",
      display: "flex",
      "justify-content": "space-around",
      width: "100%",
      "sm-flex-direction": "column",
      "sm-align-items": "flex-start",
    },
  },
  box1: {
    kind: "Box",
    props: {
      display: "flex",
      width: "100%",
      "justify-content": "space-between",
      "lg-margin": "0px 80px 0px 0px",
      "md-margin": "0px 30px 0px 0px",
      margin: "0px 140px 0px 0px",
      "sm-margin": "0px 0px 0px 0px",
    },
  },
  linkBox: {
    kind: "LinkBox",
    props: {
      "sm-margin": "0px 38px 10px 0px",
    },
  },
  text: {
    kind: "Text",
    props: {
      margin: "0px 0px 0px 0px",
      font: "--descirption3",
      color: "--white",
    },
  },
  linkBox1: {
    kind: "LinkBox",
    props: {
      "sm-margin": "0px 38px 10px 0px",
      href: "/About",
    },
  },
  text1: {
    kind: "Text",
    props: {
      margin: "0px 0px 0px 0px",
      font: "--descirption3",
      color: "--white",
      children: "About Us",
      id: "8542770137",
    },
  },
  linkBox2: {
    kind: "LinkBox",
    props: {
      "sm-margin": "0px 38px 10px 0px",
      href: "/Product Listing",
    },
  },
  text2: {
    kind: "Text",
    props: {
      margin: "0px 0px 0px 0px",
      font: "--descirption3",
      color: "--white",
      children: "Products",
      id: "4478862699",
    },
  },
  box2: {
    kind: "Box",
    props: {
      width: "100%",
      display: "flex",
      "justify-content": "space-between",
    },
  },
  linkBox3: {
    kind: "LinkBox",
    props: {
      "sm-margin": "0px 38px 10px 0px",
      href: "/Service",
    },
  },
  text3: {
    kind: "Text",
    props: {
      margin: "0px 0px 0px 0px",
      font: "--descirption3",
      color: "--white",
      children: "Service",
      id: "6281497711",
    },
  },
  linkBox4: {
    kind: "LinkBox",
    props: {
      "sm-margin": "0px 38px 10px 0px",
    },
  },
  text4: {
    kind: "Text",
    props: {
      margin: "0px 0px 0px 0px",
      font: "--descirption3",
      color: "--white",
      children: "Privacy policy",
      id: "2226316543",
    },
  },
  linkBox5: {
    kind: "LinkBox",
    props: {
      "sm-margin": "0px 38px 10px 0px",
    },
  },
  text5: {
    kind: "Text",
    props: {
      margin: "0px 0px 0px 0px",
      font: "--descirption3",
      color: "--white",
      children: "Terms of Use",
      id: "2226316543",
    },
  },
  section1: {
    kind: "Section",
    props: {
      "justify-content": "center",
      padding: "20px 0 0px 0",
      "sm-flex-direction": "row",
    },
  },
  sectionOverride1: {
    kind: "Override",
    props: {
      slot: "SectionContent",
      margin: "0px atuo 0px auto",
      display: "flex",
      "flex-direction": "row",
      "align-items": "center",
      "justify-content": "space-between",
      "sm-flex-direction": "column",
      "sm-align-items": "flex-start",
    },
  },
  linkBox6: {
    kind: "LinkBox",
    props: {
      "sm-margin": "0px 0px 26px 0px",
    },
  },
  image: {
    kind: "Image",
    props: {
      src: "https://uploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/MicrosoftTeams-image%20%28202%29%201.png?v=2023-08-10T11:04:03.885Z",
      display: "block",
      width: "220px",
      height: "90px",
      "object-fit": "cover",
      id: "2354919362",
    },
  },
  box3: {
    kind: "Box",
    props: {
      display: "flex",
      "sm-width": "100%",
      "justify-content": "center",
      "sm-margin": "0px 0px 10px 0px",
      "lg-margin": "0px 81px 0px 0px",
    },
  },
  icon: {
    kind: "Icon",
    props: {
      category: "ai",
      icon: AiFillFacebook,
      size: "64px",
      width: "32px",
      height: "32px",
      color: "--primary",
      margin: "0px 14px 0px 0px",
      id: "1274039870",
    },
  },
  icon1: {
    kind: "Icon",
    props: {
      category: "ai",
      icon: AiFillInstagram,
      size: "64px",
      width: "32px",
      height: "32px",
      color: "--primary",
      margin: "0px 14px 0px 0px",
      id: "2111099416",
      href: "hhhh",
    },
  },
  icon2: {
    kind: "Icon",
    props: {
      size: "64px",
      width: "32px",
      height: "32px",
      color: "--primary",
      margin: "0px 14px 0px 0px",
      category: "fa",
      icon: FaLinkedin,
      id: "6716442036",
    },
  },
  text6: {
    kind: "Text",
    props: {
      margin: "0px 0px 0px 0px",
      font: "--description4",
      color: "--grey",
      "sm-width": "100%",
      "letter-spacing": "0px",
      children: "© 2023, All Rights Reserved",
      id: "4613250054",
    },
  },
  SectionContent: {
    props: {
      "flex-direction": "column",
    },
  },
};

const Footer = (props) => {
  const { override, children, rest } = useOverrides(
    props,
    overrides,
    defaultProps
  );
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  function getImages(value) {
    return value
      ? value.primaryLogo
        ? process.env.REACT_APP_STORAGE_URL_2 + brandProfile.primaryLogo
        : "https://nimbuscluster.blob.core.windows.net/server01/scv-engineering/cms/primaryLogo.jpg"
      : "https://nimbuscluster.blob.core.windows.net/server01/scv-engineering/cms/primaryLogo.jpg";
  }

  const data = useSelector((state) => state.menu.menu);

  return (
    <Section {...rest}>
      <Override slot="SectionContent" width="100%" min-width="100%" />
      <Section {...override("section")}>
        <Override {...override("sectionOverride")} />
        <Box {...override("box")}>
          {data.map((e) => {
            return (
              <Text
                margin="0px 0px 0px 0px"
                font="--descirption3"
                color="--white"
                key={e._id}
              >
                <NavLink to={`/${e.pageData[0].page}`}>{e.menuName}</NavLink>
              </Text>
            );
          })}

          {/* <LinkBox {...override("linkBox1")}>
            <Text {...override("text1")} />
          </LinkBox>

          <LinkBox {...override("linkBox2")}>
            <Text {...override("text2")} />
          </LinkBox>
          <LinkBox {...override("linkBox3")}>
            <Text {...override("text3")} />
          </LinkBox> */}
          {/* <LinkBox {...override("linkBox4")}>
						<Text {...override("text4")} />
					</LinkBox>
					<LinkBox {...override("linkBox5")}>
						<Text {...override("text5")} />
					</LinkBox> */}
        </Box>
      </Section>
      <Section {...override("section1")}>
        <Override {...override("sectionOverride1")} />
        <LinkBox {...override("linkBox6")}>
          <Image
            src={getImages(brandProfile)}
            display="block"
            width="220px"
            height="90px"
            object-fit="cover"
            alt="image"
          />
        </LinkBox>
        <Box {...override("box3")}>
          {/* <Icon {...override("icon")} /> */}
          {/* <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://instagram.com/scvengineering?igsh=MjJrM2hyb3hsYm95"
          >
            <Icon {...override("icon1")} />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.linkedin.com/company/scv-engineering-l-l-c/"
          >
            <Icon {...override("icon2")} />
          </a> */}
          <SocialMedia
            linked-in="https://www.linkedin.com/company/scv-engineering-l-l-c/"
            // facebook="https://www.facebook.com/quarklyapp/"
            // twitter="https://twitter.com/quarklyapp"
            instagram="https://instagram.com/scvengineering?igsh=MjJrM2hyb3hsYm95"
            margin="0px 0px 0px 0px"
          >
            <Override
              slot="link"
              border-radius="50%"
              color="--grey"
              hover-color="--light"
              background="--color-primary"
              hover-background="--color-primary"
              margin="0 5px 0 5px"
              padding="5x 5px 5px 5px"
              width="48px"
              height="48px"
              align-items="center"
              display="flex"
              justify-content="center"
              hover-transition="background-color 0.2s ease-in-out 0s"
              transition="background-color 0.2s ease-in-out 0s"
            />
            <Override
              slot="icon"
              color="--light"
              padding="7px 7px 7px 7px"
              border-width="0px"
              size="38px"
              border-radius="50px"
            />
          </SocialMedia>
        </Box>
        <Text {...override("text6")} className="text-center" />
      </Section>
      {children}
    </Section>
  );
};

Object.assign(Footer, { ...Section, defaultProps, overrides });
export default Footer;
