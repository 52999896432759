import React, { useEffect, useState } from "react";
import theme from "../theme";
import { Theme, Text, Section, Image, Box } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "../global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
// import Nav from "../components/Nav";
import EnquireForm from "../components/EnquireForm";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { useParams } from "react-router-dom";
//CMS
import ContentUpdate from "../components/CMS/ContentUpdate";
import { setSelectedData } from "../redux/CMS/cms-actions";
export default () => {
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const [mounted, setMounted] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [selectedData, updateSelectedData] = useState("update");
  let data = useSelector((state) => state.cms.pages["Services"]);

  const { token } = useParams();

  if (token) {
    let cmsStyle = document.getElementsByClassName("hoverText");

    for (let i = 0; i < cmsStyle.length; i++) {
      cmsStyle[i].onClick = function () {
        return false;
      };
      cmsStyle[i].classList.add("hoverText1");
    }
  }

  async function isSideBarOpen(id) {
    if (token) {
      await updateSelectedData(id);
      // selectedData = id;
      setIsShow(true);

      console.log(isShow, selectedData, id, "show");
    }
  }

  function closeSidebar(value) {
    setIsShow(value);
    updateSelectedData("");
    // selectedData = "";
    dispatch(setSelectedData({}));
    console.log(selectedData, "show");
  }

  useEffect(() => {
    setMounted(true);
  }, [dispatch, setMounted]);
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"index11"} />
      <Helmet>
        <title>SCV-Engineering</title>
        <meta
          name={"description"}
          content={"Web site created using quarkly.io"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://uploads.quarkly.io/readme/cra/favicon-32x32.ico"}
          type={"image/x-icon"}
        />
      </Helmet>
      <Header />
      {/* <Nav>
        <Override
          slot="text"
          font="500 16px --fontFamily-googlePoppins"
          color="--grey"
        />
        <Override
          slot="text4"
          font="500 16px --fontFamily-googlePoppins"
          color="--grey"
        />
        <Override
          slot="text3"
          color="--primary"
          font="700 16px --fontFamily-googlePoppins"
        />
      </Nav> */}
      <Section
        padding="0px 0 0px 0"
        background="linear-gradient(0deg,rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%),rgba(0, 0, 0, 0) url(https://images.unsplash.com/photo-1455165814004-1126a7199f9b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000) 0% 0% /auto repeat scroll padding-box"
        height="350px"
        sm-height="280px"
      >
        <Override
          slot="SectionContent"
          width="100%"
          margin="0px 0px 0px 0px"
          max-width="100%"
          align-items="center"
          justify-content="center"
          background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Frame%201000004059.png?v=2023-08-11T08:10:12.902Z) 0% 0% /100% repeat scroll padding-box"
          id="6954765285"
        />
        <Text
          font="--headline1"
          color="--white"
          lg-font="--subheadlined4"
          className="hoverText"
          id="9667472320"
          text-align="center"
          letter-spacing="3px"
          md-font="--headline1"
          sm-font="--headline2"
          onClick={() => isSideBarOpen("9667472320")}
        >
          {data ? data["9667472320"] : "SERVICES"}
        </Text>
      </Section>
      <Section>
        <Override
          slot="SectionContent"
          padding="0 0px 0px 0px"
          margin="40px auto 40px auto"
          lg-margin="60px auto 60px auto"
          md-margin="32px auto 32px auto"
        />
        <Box
          min-width="100px"
          min-height="100px"
          display="flex"
          md-flex-direction="column"
          height="fit-content"
        >
          <Box
            width="50%"
            md-width="100%"
            md-margin="0px 0px 16px 0px"
            md-height="454px"
            sm-height="218px"
            height="380px"
            className="hoverText"
            onClick={() => isSideBarOpen("6359654503")}
          >
            <Image
              src={
                data
                  ? data["6359654503"]
                  : "https://images.unsplash.com/photo-1496247749665-49cf5b1022e9?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
              }
              alt="image"
              // src="https://images.unsplash.com/photo-1584270148139-7dfa9637869b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
              display="block"
              width="100%"
              border-width=" 0 0 0 10px"
              border-style="solid"
              border-color="--color-primary"
              height="100%"
              object-fit="cover"
              id="6359654503"
              // srcSet="https://images.unsplash.com/photo-1584270148139-7dfa9637869b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=500 500w,https://images.unsplash.com/photo-1584270148139-7dfa9637869b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=800 800w,https://images.unsplash.com/photo-1584270148139-7dfa9637869b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1080 1080w,https://images.unsplash.com/photo-1584270148139-7dfa9637869b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1600 1600w,https://images.unsplash.com/photo-1584270148139-7dfa9637869b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2000 2000w,https://images.unsplash.com/photo-1584270148139-7dfa9637869b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2600 2600w,https://images.unsplash.com/photo-1584270148139-7dfa9637869b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=3200 3200w"
              sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
            />
          </Box>
          <Box
            width="50%"
            margin="0px 0px 0px 50px"
            padding="0px 30PX 0px 0px"
            display="flex"
            flex-direction="column"
            justify-content="flex-start"
            align-items="flex-start"
            lg-margin="0px 0px 0px 30px"
            lg-padding="0px 0 0px 0px"
            md-margin="0px 0px 0px 0"
            md-width="100%"
            height="fit-content"
          >
            <Text
              margin="0px 0px 6px 0px"
              font="--headline3"
              color="--primary"
              padding="0px 0px 0 0px"
              lg-font="--subheadlined4"
              className="hoverText"
              id="7753740291"
              md-font="--headline3"
              sm-font="--headline4"
              onClick={() => isSideBarOpen("7753740291")}
            >
              {data ? data["7753740291"] : "MARINE"}
            </Text>
            <Text
              margin="0px 0px 24px 0px"
              font="--description4"
              lg-font="--p4"
              className="hoverText"
              id="4523318509"
              md-font="--descirption3"
              sm-font="--description4"
              onClick={() => isSideBarOpen("4523318509")}
            >
              {data
                ? data["4523318509"]
                : "The goal of SEO is to increase organic"}
            </Text>
            <Box
              display="grid"
              grid-template-columns="repeat(2, 1fr)"
              sm-grid-template-columns="repeat(1, 1fr)"
              grid-gap="24px"
              height="max-content"
              width="100%"
              lg-grid-gap="16px 24px"
              md-grid-gap="16px"
              sm-grid-gap="8px"
            >
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
                lg-align-items="flex-start"
                md-align-items="center"
                sm-align-items="flex-start"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-green"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                  md-width="12px"
                  sm-margin="6px 8px 0px 0px"
                  sm-width="10px"
                  sm-height="10px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--descirption3"
                  color="--dark"
                  text-align="left"
                  width="max-content"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  md-font="--description1"
                  className="hoverText"
                  id="0173920837"
                  sm-font="--descirption3"
                  onClick={() => isSideBarOpen("0173920837")}
                >
                  {data ? data["0173920837"] : "Conduct Keyword Research"}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
                lg-align-items="flex-start"
                md-align-items="center"
                sm-align-items="flex-start"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-green"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                  md-width="12px"
                  sm-margin="6px 8px 0px 0px"
                  sm-width="10px"
                  sm-height="10px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--descirption3"
                  color="--dark"
                  text-align="left"
                  width="max-content"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  md-font="--description1"
                  className="hoverText"
                  id="3954846113"
                  sm-font="--descirption3"
                  onClick={() => isSideBarOpen("3954846113")}
                >
                  {data ? data["3954846113"] : "Conduct Keyword Research"}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
                lg-align-items="flex-start"
                md-align-items="center"
                sm-align-items="flex-start"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-green"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                  md-width="12px"
                  sm-margin="6px 8px 0px 0px"
                  sm-width="10px"
                  sm-height="10px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--descirption3"
                  color="--dark"
                  text-align="left"
                  width="max-content"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  md-font="--description1"
                  className="hoverText"
                  id="0477026811"
                  sm-font="--descirption3"
                  onClick={() => isSideBarOpen("0477026811")}
                >
                  {data ? data["0477026811"] : "Conduct Keyword Research"}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
                lg-align-items="flex-start"
                md-align-items="center"
                sm-align-items="flex-start"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-green"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                  md-width="12px"
                  sm-margin="6px 8px 0px 0px"
                  sm-width="10px"
                  sm-height="10px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--descirption3"
                  color="--dark"
                  text-align="left"
                  width="max-content"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  md-font="--description1"
                  className="hoverText"
                  id="7745687706"
                  sm-font="--descirption3"
                  onClick={() => isSideBarOpen("7745687706")}
                >
                  {data ? data["7745687706"] : "Conduct Keyword Research"}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
                lg-align-items="flex-start"
                md-align-items="center"
                sm-align-items="flex-start"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-green"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                  md-width="12px"
                  sm-margin="6px 8px 0px 0px"
                  sm-width="10px"
                  sm-height="10px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--descirption3"
                  color="--dark"
                  text-align="left"
                  width="max-content"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  md-font="--description1"
                  className="hoverText"
                  id="1963592828"
                  sm-font="--descirption3"
                  onClick={() => isSideBarOpen("1963592828")}
                >
                  {data ? data["1963592828"] : "Conduct Keyword Research"}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
                lg-align-items="flex-start"
                md-align-items="center"
                sm-align-items="flex-start"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-green"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                  md-width="12px"
                  sm-margin="6px 8px 0px 0px"
                  sm-width="10px"
                  sm-height="10px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--descirption3"
                  color="--dark"
                  text-align="left"
                  width="max-content"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  md-font="--description1"
                  className="hoverText"
                  id="7281769724"
                  sm-font="--descirption3"
                  onClick={() => isSideBarOpen("7281769724")}
                >
                  {data ? data["7281769724"] : "Conduct Keyword Research"}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
                lg-align-items="flex-start"
                md-align-items="center"
                sm-align-items="flex-start"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-green"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                  md-width="12px"
                  sm-margin="6px 8px 0px 0px"
                  sm-width="10px"
                  sm-height="10px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--descirption3"
                  color="--dark"
                  text-align="left"
                  width="max-content"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  md-font="--description1"
                  className="hoverText"
                  id="3733616032"
                  sm-font="--descirption3"
                  onClick={() => isSideBarOpen("3733616032")}
                >
                  {data ? data["3733616032"] : "Conduct Keyword Research"}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
                lg-align-items="flex-start"
                md-align-items="center"
                sm-align-items="flex-start"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-green"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                  md-width="12px"
                  sm-margin="6px 8px 0px 0px"
                  sm-width="10px"
                  sm-height="10px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--descirption3"
                  color="--dark"
                  text-align="left"
                  width="max-content"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  md-font="--description1"
                  className="hoverText"
                  id="5506641057"
                  sm-font="--descirption3"
                  onClick={() => isSideBarOpen("5506641057")}
                >
                  {data ? data["5506641057"] : "Conduct Keyword Research"}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
                lg-align-items="flex-start"
                md-align-items="center"
                sm-align-items="flex-start"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-green"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                  md-width="12px"
                  sm-margin="6px 8px 0px 0px"
                  sm-width="10px"
                  sm-height="10px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--descirption3"
                  color="--dark"
                  text-align="left"
                  width="max-content"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  md-font="--description1"
                  className="hoverText"
                  id="0998611804"
                  sm-font="--descirption3"
                  onClick={() => isSideBarOpen("0998611804")}
                >
                  {data ? data["0998611804"] : "Conduct Keyword Research"}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
                lg-align-items="flex-start"
                md-align-items="center"
                sm-align-items="flex-start"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-green"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                  md-width="12px"
                  sm-margin="6px 8px 0px 0px"
                  sm-width="10px"
                  sm-height="10px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--descirption3"
                  color="--dark"
                  text-align="left"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  md-font="--description1"
                  className="hoverText"
                  id="3572655447"
                  sm-font="--descirption3"
                  onClick={() => isSideBarOpen("3572655447")}
                >
                  {data ? data["3572655447"] : "Optimize Your Website"}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
                lg-align-items="flex-start"
                md-align-items="center"
                sm-align-items="flex-start"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-green"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                  md-width="12px"
                  sm-margin="6px 8px 0px 0px"
                  sm-width="10px"
                  sm-height="10px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--descirption3"
                  color="--dark"
                  text-align="left"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  md-font="--description1"
                  className="hoverText"
                  id="9457077080"
                  sm-font="--descirption3"
                  onClick={() => isSideBarOpen("9457077080")}
                >
                  {data ? data["9457077080"] : "Create Quality Content:"}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
                lg-align-items="flex-start"
                md-align-items="center"
                sm-align-items="flex-start"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-green"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                  md-width="12px"
                  sm-margin="6px 8px 0px 0px"
                  sm-width="10px"
                  sm-height="10px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--descirption3"
                  color="--dark"
                  text-align="left"
                  width="max-content"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  md-font="--description1"
                  className="hoverText"
                  id="2297471646"
                  sm-font="--descirption3"
                  onClick={() => isSideBarOpen("2297471646")}
                >
                  {data ? data["2297471646"] : "Build High-Quality Backlinks"}
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </Section>
      <Section
        background="--color-primary"
        position="relative"
        z-index="0"
        md-overflow-x="hidden"
        md-overflow-y="hidden"
        overflow-x="hidden"
        overflow-y="hidden"
      >
        <Override
          slot="SectionContent"
          padding="0 0px 0px 0px"
          margin="40px auto 40px auto"
          md-margin="24px auto 24px auto"
        />
        <Box
          min-width="100px"
          min-height="100px"
          display="flex"
          color="--darkL1"
          md-flex-direction="column-reverse"
        >
          <Box
            width="50%"
            margin="0px 0px 0px 0px"
            padding="0px 30PX 0px 0px"
            display="flex"
            flex-direction="column"
            justify-content="flex-start"
            align-items="flex-start"
            lg-margin="0px 0px 0px 0px"
            md-width="100%"
            md-margin="0px 0px 0px 0px"
          >
            <Text
              margin="0px 0px 6px 0px"
              font="--headline3"
              color="--white"
              padding="0px 0px 0 0px"
              lg-font="--subheadlined4"
              className="hoverText"
              id="3777331079"
              md-font="--headline3"
              sm-font="--headline4"
              onClick={() => isSideBarOpen("3777331079")}
            >
              {data ? data["3777331079"] : "    INDUSTRIAL"}
            </Text>
            <Text
              margin="0px 0px 24px 0px"
              font="--description4"
              color="--grey"
              lg-font="--p4"
              className="hoverText"
              id="7616366287"
              md-font="--descirption3"
              sm-font="--description4"
              dangerouslySetInnerHTML={{
                __html: data ? data["7616366287"] : "Connect with Us for Your",
              }}
              onClick={() => isSideBarOpen("7616366287")}
            ></Text>
            <Box
              display="grid"
              grid-template-columns="repeat(2, 1fr)"
              sm-grid-template-columns="repeat(1, 1fr)"
              grid-gap="24px"
              height="max-content"
              width="100%"
              lg-grid-gap="16px 24px"
              md-grid-gap="16px"
              sm-grid-gap="8px"
            >
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
                lg-align-items="flex-start"
                md-align-items="center"
                sm-align-items="flex-start"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-green"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                  md-width="12px"
                  sm-margin="6px 8px 0px 0px"
                  sm-width="10px"
                  sm-height="10px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--descirption3"
                  color="--lightD1"
                  text-align="left"
                  width="max-content"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  md-font="--description1"
                  className="hoverText"
                  id="0930216047"
                  sm-font="--descirption3"
                  onClick={() => isSideBarOpen("0930216047")}
                >
                  {data ? data["0930216047"] : "Conduct Keyword Research"}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
                lg-align-items="flex-start"
                md-align-items="center"
                sm-align-items="flex-start"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-green"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                  md-width="12px"
                  sm-margin="6px 8px 0px 0px"
                  sm-width="10px"
                  sm-height="10px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--descirption3"
                  color="--lightD1"
                  text-align="left"
                  width="max-content"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  md-font="--description1"
                  className="hoverText"
                  id="6255868573"
                  sm-font="--descirption3"
                  onClick={() => isSideBarOpen("6255868573")}
                >
                  {data ? data["6255868573"] : "Conduct Keyword Research"}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
                lg-align-items="flex-start"
                md-align-items="center"
                sm-align-items="flex-start"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-green"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                  md-width="12px"
                  sm-margin="6px 8px 0px 0px"
                  sm-width="10px"
                  sm-height="10px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--descirption3"
                  color="--lightD1"
                  text-align="left"
                  width="max-content"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  md-font="--description1"
                  className="hoverText"
                  id="6874272645"
                  sm-font="--descirption3"
                  onClick={() => isSideBarOpen("6874272645")}
                >
                  {data ? data["6874272645"] : "Conduct Keyword Research"}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
                lg-align-items="flex-start"
                md-align-items="center"
                sm-align-items="flex-start"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-green"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                  md-width="12px"
                  sm-margin="6px 8px 0px 0px"
                  sm-width="10px"
                  sm-height="10px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--descirption3"
                  color="--lightD1"
                  text-align="left"
                  width="max-content"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  md-font="--description1"
                  className="hoverText"
                  id="1886161377"
                  sm-font="--descirption3"
                  onClick={() => isSideBarOpen("1886161377")}
                >
                  {data ? data["1886161377"] : "Conduct Keyword Research"}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
                lg-align-items="flex-start"
                md-align-items="center"
                sm-align-items="flex-start"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-green"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                  md-width="12px"
                  sm-margin="6px 8px 0px 0px"
                  sm-width="10px"
                  sm-height="10px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--descirption3"
                  color="--lightD1"
                  text-align="left"
                  width="max-content"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  md-font="--description1"
                  className="hoverText"
                  id="6634534225"
                  sm-font="--descirption3"
                  onClick={() => isSideBarOpen("6634534225")}
                >
                  {data ? data["6634534225"] : "Conduct Keyword Research"}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
                lg-align-items="flex-start"
                md-align-items="center"
                sm-align-items="flex-start"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-green"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                  md-width="12px"
                  sm-margin="6px 8px 0px 0px"
                  sm-width="10px"
                  sm-height="10px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--descirption3"
                  color="--lightD1"
                  text-align="left"
                  width="max-content"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  md-font="--description1"
                  className="hoverText"
                  id="0510643861"
                  sm-font="--descirption3"
                  onClick={() => isSideBarOpen("0510643861")}
                >
                  {data ? data["0510643861"] : "Conduct Keyword Research"}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
                lg-align-items="flex-start"
                md-align-items="center"
                sm-align-items="flex-start"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-green"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                  md-width="12px"
                  sm-margin="6px 8px 0px 0px"
                  sm-width="10px"
                  sm-height="10px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--descirption3"
                  color="--lightD1"
                  text-align="left"
                  width="max-content"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  md-font="--description1"
                  className="hoverText"
                  id="7247912236"
                  sm-font="--descirption3"
                  onClick={() => isSideBarOpen("7247912236")}
                >
                  {data ? data["7247912236"] : "Conduct Keyword Research"}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
                lg-align-items="flex-start"
                md-align-items="center"
                sm-align-items="flex-start"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-green"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                  md-width="12px"
                  sm-margin="6px 8px 0px 0px"
                  sm-width="10px"
                  sm-height="10px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--descirption3"
                  color="--lightD1"
                  text-align="left"
                  width="max-content"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  md-font="--description1"
                  className="hoverText"
                  id="8331604223"
                  sm-font="--descirption3"
                  onClick={() => isSideBarOpen("8331604223")}
                >
                  {data ? data["8331604223"] : "Conduct Keyword Research"}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
                lg-align-items="flex-start"
                md-align-items="center"
                sm-align-items="flex-start"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-green"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                  md-width="12px"
                  sm-margin="6px 8px 0px 0px"
                  sm-width="10px"
                  sm-height="10px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--descirption3"
                  color="--lightD1"
                  text-align="left"
                  width="max-content"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  md-font="--description1"
                  className="hoverText"
                  id="7961111292"
                  sm-font="--descirption3"
                  onClick={() => isSideBarOpen("7961111292")}
                >
                  {data ? data["7961111292"] : "Conduct Keyword Research"}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
                lg-align-items="flex-start"
                md-align-items="center"
                sm-align-items="flex-start"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-green"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                  md-width="12px"
                  sm-margin="6px 8px 0px 0px"
                  sm-width="10px"
                  sm-height="10px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--descirption3"
                  color="--lightD1"
                  text-align="left"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  md-font="--description1"
                  className="hoverText"
                  id="6001972349"
                  sm-font="--descirption3"
                  onClick={() => isSideBarOpen("6001972349")}
                >
                  {data ? data["6001972349"] : "Conduct Keyword Research"}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
                lg-align-items="flex-start"
                md-align-items="center"
                sm-align-items="flex-start"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-green"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                  md-width="12px"
                  sm-margin="6px 8px 0px 0px"
                  sm-width="10px"
                  sm-height="10px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--descirption3"
                  color="--lightD1"
                  text-align="left"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  md-font="--description1"
                  className="hoverText"
                  id="5887006070"
                  sm-font="--descirption3"
                  onClick={() => isSideBarOpen("5887006070")}
                >
                  {data ? data["5887006070"] : "Conduct Keyword Research"}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
                lg-align-items="flex-start"
                md-align-items="center"
                sm-align-items="flex-start"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-green"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                  md-width="12px"
                  sm-margin="6px 8px 0px 0px"
                  sm-width="10px"
                  sm-height="10px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--descirption3"
                  color="--lightD1"
                  text-align="left"
                  width="max-content"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  md-font="--description1"
                  className="hoverText"
                  id="1280172675"
                  sm-font="--descirption3"
                  onClick={() => isSideBarOpen("1280172675")}
                >
                  {data ? data["1280172675"] : "Build High-Quality Backlinks"}
                </Text>
              </Box>
            </Box>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            width="50%"
            md-width="100%"
            md-margin="0px 0px 26px 0px"
            className="hoverText"
            onClick={() => isSideBarOpen("4533265236")}
          >
            <Image
              src={
                data
                  ? data["4533265236"]
                  : "https://images.unsplash.com/photo-1496247749665-49cf5b1022e9?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
              }
              alt="image"
              // src="https://images.unsplash.com/photo-1496247749665-49cf5b1022e9?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
              display="block"
              width="100%"
              border-width=" 0 0 0 10px"
              border-style="solid"
              border-color="--color-white"
              height="100%"
              object-fit="cover"
              id="4533265236"
              className="hoverText"
              // srcSet="https://images.unsplash.com/photo-1496247749665-49cf5b1022e9?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1496247749665-49cf5b1022e9?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1496247749665-49cf5b1022e9?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1496247749665-49cf5b1022e9?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1496247749665-49cf5b1022e9?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1496247749665-49cf5b1022e9?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1496247749665-49cf5b1022e9?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
              sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
            />
          </Box>
        </Box>
      </Section>
      <Section>
        <Override
          slot="SectionContent"
          padding="0 0px 0px 0px"
          margin="40px auto 40px auto"
          lg-margin="60px auto 60px auto"
          md-margin="24px auto 24px auto"
        />
        <Box
          min-width="100px"
          min-height="100px"
          display="flex"
          md-flex-direction="column"
        >
          <Box
            min-width="100px"
            min-height="100px"
            width="50%"
            md-width="100%"
            md-margin="0px 0px 16px 0px"
            className="hoverText"
            onClick={() => isSideBarOpen("6236933212")}
          >
            <Image
              src={
                data
                  ? data["6236933212"]
                  : "https://images.unsplash.com/photo-1464938050520-ef2270bb8ce8?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
              }
              alt="image"
              // src="https://images.unsplash.com/photo-1464938050520-ef2270bb8ce8?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
              display="block"
              width="100%"
              border-width=" 0 0 0 10px"
              border-style="solid"
              border-color="--color-primary"
              height="100%"
              object-fit="cover"
              className="hoverText"
              id="6236933212"
              // srcSet="https://images.unsplash.com/photo-1464938050520-ef2270bb8ce8?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1464938050520-ef2270bb8ce8?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1464938050520-ef2270bb8ce8?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1464938050520-ef2270bb8ce8?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1464938050520-ef2270bb8ce8?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1464938050520-ef2270bb8ce8?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1464938050520-ef2270bb8ce8?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
              sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
            />
          </Box>
          <Box
            width="50%"
            margin="0px 0px 0px 50px"
            padding="0px 30PX 0px 0px"
            display="flex"
            flex-direction="column"
            justify-content="flex-start"
            align-items="flex-start"
            lg-padding="0px 0 0px 0px"
            md-width="100%"
            md-margin="0px 0px 0px 0"
          >
            <Text
              margin="0px 0px 6px 0px"
              font="--headline3"
              color="--primary"
              padding="0px 0px 0 0px"
              lg-font="--subheadlined4"
              className="hoverText"
              id="7931998540"
              md-font="--headline3"
              sm-font="--headline4"
              onClick={() => isSideBarOpen("7931998540")}
            >
              {data ? data["7931998540"] : "F&B industry"}
            </Text>
            <Text
              margin="0px 0px 24px 0px"
              font="--description4"
              lg-font="--p4"
              className="hoverText"
              id="5857994302"
              color="--darkL2"
              md-font="--descirption3"
              sm-font="--description4"
              dangerouslySetInnerHTML={{
                __html: data ? data["5857994302"] : "Connect with Us for Your",
              }}
              onClick={() => isSideBarOpen("5857994302")}
            ></Text>
            <Box
              display="grid"
              grid-template-columns="repeat(2, 1fr)"
              sm-grid-template-columns="repeat(1, 1fr)"
              grid-gap="24px"
              height="max-content"
              width="100%"
              lg-grid-gap="16px 24px"
              md-grid-gap="16px"
              sm-grid-gap="8px"
            >
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
                lg-align-items="flex-start"
                md-align-items="center"
                sm-align-items="flex-start"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-green"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                  md-width="12px"
                  sm-margin="6px 8px 0px 0px"
                  sm-width="10px"
                  sm-height="10px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--descirption3"
                  color="--dark"
                  text-align="left"
                  width="max-content"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  md-font="--description1"
                  className="hoverText"
                  id="5510525417"
                  sm-font="--descirption3"
                  onClick={() => isSideBarOpen("5510525417")}
                >
                  {data ? data["5510525417"] : "Conduct Keyword Research"}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
                lg-align-items="flex-start"
                md-align-items="center"
                sm-align-items="flex-start"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-green"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                  md-width="12px"
                  sm-margin="6px 8px 0px 0px"
                  sm-width="10px"
                  sm-height="10px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--descirption3"
                  color="--dark"
                  text-align="left"
                  width="max-content"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  md-font="--description1"
                  className="hoverText"
                  id="2096111842"
                  sm-font="--descirption3"
                  onClick={() => isSideBarOpen("2096111842")}
                >
                  {data ? data["2096111842"] : "Conduct Keyword Research"}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
                lg-align-items="flex-start"
                md-align-items="center"
                sm-align-items="flex-start"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-green"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                  md-width="12px"
                  sm-margin="6px 8px 0px 0px"
                  sm-width="10px"
                  sm-height="10px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--descirption3"
                  color="--dark"
                  text-align="left"
                  width="max-content"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  md-font="--description1"
                  className="hoverText"
                  id="7578059947"
                  sm-font="--descirption3"
                  onClick={() => isSideBarOpen("7578059947")}
                >
                  {data ? data["7578059947"] : "Conduct Keyword Research"}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
                lg-align-items="flex-start"
                md-align-items="center"
                sm-align-items="flex-start"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-green"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                  md-width="12px"
                  sm-margin="6px 8px 0px 0px"
                  sm-width="10px"
                  sm-height="10px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--descirption3"
                  color="--dark"
                  text-align="left"
                  width="max-content"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  md-font="--description1"
                  className="hoverText"
                  id="9897307494"
                  sm-font="--descirption3"
                  onClick={() => isSideBarOpen("9897307494")}
                >
                  {data ? data["9897307494"] : "Conduct Keyword Research"}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
                lg-align-items="flex-start"
                md-align-items="center"
                sm-align-items="flex-start"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-green"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                  md-width="12px"
                  sm-margin="6px 8px 0px 0px"
                  sm-width="10px"
                  sm-height="10px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--descirption3"
                  color="--dark"
                  text-align="left"
                  width="max-content"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  md-font="--description1"
                  className="hoverText"
                  id="8199012058"
                  sm-font="--descirption3"
                  onClick={() => isSideBarOpen("8199012058")}
                >
                  {data ? data["8199012058"] : "Conduct Keyword Research"}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
                lg-align-items="flex-start"
                md-align-items="center"
                sm-align-items="flex-start"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-green"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                  md-width="12px"
                  sm-margin="6px 8px 0px 0px"
                  sm-width="10px"
                  sm-height="10px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--descirption3"
                  color="--dark"
                  text-align="left"
                  width="max-content"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  md-font="--description1"
                  className="hoverText"
                  id="9896211176"
                  sm-font="--descirption3"
                  onClick={() => isSideBarOpen("9896211176")}
                >
                  {data ? data["9896211176"] : "Conduct Keyword Research"}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
                lg-align-items="flex-start"
                md-align-items="center"
                sm-align-items="flex-start"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-green"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                  md-width="12px"
                  sm-margin="6px 8px 0px 0px"
                  sm-width="10px"
                  sm-height="10px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--descirption3"
                  color="--dark"
                  text-align="left"
                  width="max-content"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  md-font="--description1"
                  className="hoverText"
                  id="4355829402"
                  sm-font="--descirption3"
                  onClick={() => isSideBarOpen("4355829402")}
                >
                  {data ? data["4355829402"] : "Conduct Keyword Research"}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
                lg-align-items="flex-start"
                md-align-items="center"
                sm-align-items="flex-start"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-green"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                  md-width="12px"
                  sm-margin="6px 8px 0px 0px"
                  sm-width="10px"
                  sm-height="10px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--descirption3"
                  color="--dark"
                  text-align="left"
                  width="max-content"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  md-font="--description1"
                  className="hoverText"
                  id="8741667481"
                  sm-font="--descirption3"
                  onClick={() => isSideBarOpen("8741667481")}
                >
                  {data ? data["8741667481"] : "Conduct Keyword Research"}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
                lg-align-items="flex-start"
                md-align-items="center"
                sm-align-items="flex-start"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-green"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                  md-width="12px"
                  sm-margin="6px 8px 0px 0px"
                  sm-width="10px"
                  sm-height="10px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--descirption3"
                  color="--dark"
                  text-align="left"
                  width="max-content"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  md-font="--description1"
                  className="hoverText"
                  id="9388170878"
                  sm-font="--descirption3"
                  onClick={() => isSideBarOpen("9388170878")}
                >
                  {data ? data["9388170878"] : "Conduct Keyword Research"}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
                lg-align-items="flex-start"
                md-align-items="center"
                sm-align-items="flex-start"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-green"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                  md-width="12px"
                  sm-margin="6px 8px 0px 0px"
                  sm-width="10px"
                  sm-height="10px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--descirption3"
                  color="--dark"
                  text-align="left"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  md-font="--description1"
                  className="hoverText"
                  id="8908368424"
                  sm-font="--descirption3"
                  onClick={() => isSideBarOpen("8908368424")}
                >
                  {data ? data["8908368424"] : "Conduct Keyword Research"}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
                lg-align-items="flex-start"
                md-align-items="center"
                sm-align-items="flex-start"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-green"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                  md-width="12px"
                  sm-margin="6px 8px 0px 0px"
                  sm-width="10px"
                  sm-height="10px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--descirption3"
                  color="--dark"
                  text-align="left"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  md-font="--description1"
                  className="hoverText"
                  id="9180886642"
                  sm-font="--descirption3"
                  onClick={() => isSideBarOpen("9180886642")}
                >
                  {data ? data["9180886642"] : "Conduct Keyword Research"}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
                lg-align-items="flex-start"
                md-align-items="center"
                sm-align-items="flex-start"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-green"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                  md-width="12px"
                  sm-margin="6px 8px 0px 0px"
                  sm-width="10px"
                  sm-height="10px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--descirption3"
                  color="--dark"
                  text-align="left"
                  width="max-content"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  md-font="--description1"
                  className="hoverText"
                  id="3384721876"
                  sm-font="--descirption3"
                  onClick={() => isSideBarOpen("3384721876")}
                >
                  {data ? data["3384721876"] : "Conduct Keyword Research"}
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </Section>
      <Section
        background="--color-primary"
        position="relative"
        z-index="0"
        md-overflow-x="hidden"
        md-overflow-y="hidden"
        overflow-x="hidden"
        overflow-y="hidden"
      >
        <Override
          slot="SectionContent"
          padding="0 0px 0px 0px"
          margin="40px auto 40px auto"
          md-margin="24px auto 24px auto"
        />
        <Box
          min-width="100px"
          min-height="100px"
          display="flex"
          color="--darkL1"
          md-flex-direction="column-reverse"
        >
          <Box
            width="50%"
            margin="0px 0px 0px 0px"
            padding="0px 30PX 0px 0px"
            display="flex"
            flex-direction="column"
            justify-content="flex-start"
            align-items="flex-start"
            md-width="100%"
            md-margin="0px 0px 0px 0px"
          >
            <Text
              margin="0px 0px 6px 0px"
              font="--headline3"
              color="--white"
              padding="0px 0px 0 0px"
              lg-font="--subheadlined4"
              className="hoverText"
              id="1015180182"
              md-font="--headline3"
              sm-font="--headline4"
              onClick={() => isSideBarOpen("1015180182")}
            >
              {data ? data["1015180182"] : "HYRAULIC"}
            </Text>
            <Text
              margin="0px 0px 24px 0px"
              font="--description4"
              color="--grey"
              lg-font="--p4"
              className="hoverText"
              id="9210148817"
              md-font="--descirption3"
              sm-font="--description4"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data["9210148817"]
                  : "The key to content marketing",
              }}
              onClick={() => isSideBarOpen("9210148817")}
            ></Text>
            <Box
              display="grid"
              grid-template-columns="repeat(2, 1fr)"
              sm-grid-template-columns="repeat(1, 1fr)"
              grid-gap="24px"
              height="max-content"
              width="100%"
              lg-grid-gap="16px 24px"
              md-grid-gap="16px"
              sm-grid-gap="8px"
            >
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
                lg-align-items="flex-start"
                md-align-items="center"
                sm-align-items="flex-start"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-green"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                  md-width="12px"
                  sm-margin="6px 8px 0px 0px"
                  sm-width="10px"
                  sm-height="10px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--descirption3"
                  color="--lightD1"
                  text-align="left"
                  width="max-content"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  md-font="--description1"
                  className="hoverText"
                  id="3086261101"
                  sm-font="--descirption3"
                  onClick={() => isSideBarOpen("3086261101")}
                >
                  {data ? data["3086261101"] : "Conduct Keyword Research"}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
                lg-align-items="flex-start"
                md-align-items="center"
                sm-align-items="flex-start"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-green"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                  md-width="12px"
                  sm-margin="6px 8px 0px 0px"
                  sm-width="10px"
                  sm-height="10px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--descirption3"
                  color="--lightD1"
                  text-align="left"
                  width="max-content"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  md-font="--description1"
                  className="hoverText"
                  id="5940009492"
                  sm-font="--descirption3"
                  onClick={() => isSideBarOpen("5940009492")}
                >
                  {data ? data["5940009492"] : "Conduct Keyword Research"}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
                lg-align-items="flex-start"
                md-align-items="center"
                sm-align-items="flex-start"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-green"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                  md-width="12px"
                  sm-margin="6px 8px 0px 0px"
                  sm-width="10px"
                  sm-height="10px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--descirption3"
                  color="--lightD1"
                  text-align="left"
                  width="max-content"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  md-font="--description1"
                  className="hoverText"
                  id="4854963889"
                  sm-font="--descirption3"
                  onClick={() => isSideBarOpen("4854963889")}
                >
                  {data ? data["4854963889"] : "Conduct Keyword Research"}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
                lg-align-items="flex-start"
                md-align-items="center"
                sm-align-items="flex-start"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-green"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                  md-width="12px"
                  sm-margin="6px 8px 0px 0px"
                  sm-width="10px"
                  sm-height="10px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--descirption3"
                  color="--lightD1"
                  text-align="left"
                  width="max-content"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  md-font="--description1"
                  className="hoverText"
                  id="2958115089"
                  sm-font="--descirption3"
                  onClick={() => isSideBarOpen("2958115089")}
                >
                  {data ? data["2958115089"] : "Conduct Keyword Research"}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
                lg-align-items="flex-start"
                md-align-items="center"
                sm-align-items="flex-start"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-green"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                  md-width="12px"
                  sm-margin="6px 8px 0px 0px"
                  sm-width="10px"
                  sm-height="10px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--descirption3"
                  color="--lightD1"
                  text-align="left"
                  width="max-content"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  md-font="--description1"
                  className="hoverText"
                  id="1977480646"
                  sm-font="--descirption3"
                  onClick={() => isSideBarOpen("1977480646")}
                >
                  {data ? data["1977480646"] : "Conduct Keyword Research"}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
                lg-align-items="flex-start"
                md-align-items="center"
                sm-align-items="flex-start"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-green"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                  md-width="12px"
                  sm-margin="6px 8px 0px 0px"
                  sm-width="10px"
                  sm-height="10px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--descirption3"
                  color="--lightD1"
                  text-align="left"
                  width="max-content"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  md-font="--description1"
                  className="hoverText"
                  id="6213624304"
                  sm-font="--descirption3"
                  onClick={() => isSideBarOpen("6213624304")}
                >
                  {data ? data["6213624304"] : "Conduct Keyword Research"}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
                lg-align-items="flex-start"
                md-align-items="center"
                sm-align-items="flex-start"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-green"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                  md-width="12px"
                  sm-margin="6px 8px 0px 0px"
                  sm-width="10px"
                  sm-height="10px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--descirption3"
                  color="--lightD1"
                  text-align="left"
                  width="max-content"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  md-font="--description1"
                  className="hoverText"
                  id="2596036668"
                  sm-font="--descirption3"
                  onClick={() => isSideBarOpen("2596036668")}
                >
                  {data ? data["2596036668"] : "Conduct Keyword Research"}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
                lg-align-items="flex-start"
                md-align-items="center"
                sm-align-items="flex-start"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-green"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                  md-width="12px"
                  sm-margin="6px 8px 0px 0px"
                  sm-width="10px"
                  sm-height="10px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--descirption3"
                  color="--lightD1"
                  text-align="left"
                  width="max-content"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  md-font="--description1"
                  className="hoverText"
                  id="5587741852"
                  sm-font="--descirption3"
                  onClick={() => isSideBarOpen("5587741852")}
                >
                  {data ? data["5587741852"] : "Conduct Keyword Research"}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
                lg-align-items="flex-start"
                md-align-items="center"
                sm-align-items="flex-start"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-green"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                  md-width="12px"
                  sm-margin="6px 8px 0px 0px"
                  sm-width="10px"
                  sm-height="10px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--descirption3"
                  color="--lightD1"
                  text-align="left"
                  width="max-content"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  md-font="--description1"
                  className="hoverText"
                  id="1011338176"
                  sm-font="--descirption3"
                  onClick={() => isSideBarOpen("1011338176")}
                >
                  {data ? data["1011338176"] : "Conduct Keyword Research"}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
                lg-align-items="flex-start"
                md-align-items="center"
                sm-align-items="flex-start"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-green"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                  md-width="12px"
                  sm-margin="6px 8px 0px 0px"
                  sm-width="10px"
                  sm-height="10px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--descirption3"
                  color="--lightD1"
                  text-align="left"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  md-font="--description1"
                  className="hoverText"
                  id="6807305597"
                  sm-font="--descirption3"
                  onClick={() => isSideBarOpen("6807305597")}
                >
                  {data ? data["6807305597"] : "Optimize Your Website"}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
                lg-align-items="flex-start"
                md-align-items="center"
                sm-align-items="flex-start"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-green"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                  md-width="12px"
                  sm-margin="6px 8px 0px 0px"
                  sm-width="10px"
                  sm-height="10px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--descirption3"
                  color="--lightD1"
                  text-align="left"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  md-font="--description1"
                  className="hoverText"
                  id="1368066510"
                  sm-font="--descirption3"
                  onClick={() => isSideBarOpen("1368066510")}
                >
                  {data ? data["1368066510"] : "Create Quality Content:"}
                </Text>
              </Box>
              <Box
                height="max-content"
                display="flex"
                flex-direction="row"
                align-items="center"
                lg-align-items="flex-start"
                md-align-items="center"
                sm-align-items="flex-start"
              >
                <Box
                  width="12px"
                  height="12Px"
                  background="--color-green"
                  border-width={0}
                  border-radius="20px"
                  margin="0px 6px 0px 0px"
                  md-width="12px"
                  sm-margin="6px 8px 0px 0px"
                  sm-width="10px"
                  sm-height="10px"
                />
                <Text
                  margin="0px 0px 0px 0px"
                  font="--descirption3"
                  color="--lightD1"
                  text-align="left"
                  width="max-content"
                  lg-font="--subheadlined5"
                  lg-width="auto"
                  md-font="--description1"
                  className="hoverText"
                  id="2256873693"
                  sm-font="--descirption3"
                  onClick={() => isSideBarOpen("2256873693")}
                >
                  {data ? data["2256873693"] : "Build High-Quality Backlinks"}
                </Text>
              </Box>
            </Box>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            width="50%"
            md-height="454px"
            sm-height="218px"
            height="380px"
            md-width="100%"
            md-margin="0px 0px 26px 0px"
            className="hoverText"
            onClick={() => isSideBarOpen("9268950478")}
          >
            <Image
              src={
                data
                  ? data["9268950478"]
                  : "https://uploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group4.png?v=2023-08-31T12:33:57.955Z"
              }
              alt="image"
              // src="https://uploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group4.png?v=2023-08-31T12:33:57.955Z"
              display="block"
              width="100%"
              border-width=" 0 0 0 10px"
              border-style="solid"
              border-color="--color-white"
              height="100%"
              object-fit="cover"
              className="hoverText"
              id="9268950478"
              // srcSet="https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group4.png?v=2023-08-31T12%3A33%3A57.955Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group4.png?v=2023-08-31T12%3A33%3A57.955Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group4.png?v=2023-08-31T12%3A33%3A57.955Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group4.png?v=2023-08-31T12%3A33%3A57.955Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group4.png?v=2023-08-31T12%3A33%3A57.955Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group4.png?v=2023-08-31T12%3A33%3A57.955Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Clip%20path%20group4.png?v=2023-08-31T12%3A33%3A57.955Z&quality=85&w=3200 3200w"
              sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
            />
          </Box>
        </Box>
      </Section>
      <EnquireForm />
      <Footer>
        <Override slot="SectionContent" flex-direction="column" />
      </Footer>
      {isShow ? (
        <ContentUpdate
          selectedElement={selectedData}
          isShow={isShow}
          closeSidebar={closeSidebar}
        />
      ) : (
        ""
      )}
      <RawHtml>
        <style place={"endOfHead"} rawKey={"64d4bf2f6efcea001ae4fe4d"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  );
};
