import React, { useEffect } from "react";

import Home from "./pages/Home";
import About from "./pages/About";
import Service from "./pages/Service";
import Contact from "./pages/Contact";
import Product from "./pages/ProductList";
import ProductOverview from "./pages/ProductOverview";
import Maintenance from "./pages/coming/Comingsoon";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { getCms } from "./redux/CMS/cms-actions";
//api
import { getBrandProfile } from "./redux/BrandDetails/bd-actions";

const GlobalStyles = createGlobalStyle`
    body {
        margin: 0;
        padding: 0;
        font-family: sans-serif;
    }
`;

const App = () => {
  const dispatch = useDispatch();
  let project = useSelector((state) => state.userDetails.project);
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);

  useEffect(() => {
    async function mount() {
      await dispatch(getCms());
      await dispatch(
        getBrandProfile({
          projectId: project._id,
          projectName: project.projectName,
        })
      );
    }
    mount();
  }, [dispatch, project._id, project.projectName]);
  function applyThemeToDocument() {
    if (brandProfile && brandProfile.hasOwnProperty("primaryColor"))
      document.body.style.setProperty(
        "--qtheme-color-primary",
        brandProfile.primaryColor
      );

    if (brandProfile && brandProfile.hasOwnProperty("secondaryColor"))
      document.body.style.setProperty(
        "--qtheme-color-secondary",
        brandProfile.secondaryColor
      );

    if (brandProfile && brandProfile.hasOwnProperty("tertiaryColor"))
      document.body.style.setProperty(
        "--qtheme-color-tertiary",
        brandProfile.tertiaryColor
      );
  }
  applyThemeToDocument();
  return (
    <Router>
      <GlobalStyles />
      <div className="margin-nav">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/Home" component={Home} />
          <Route exact path="/Home/:token" component={Home} />
          <Route exact path="/About" component={About} />
          <Route exact path="/About/:token" component={About} />
          <Route exact path="/Services" component={Service} />
          <Route exact path="/Services/:token" component={Service} />
          <Route exact path="/Contact Us" component={Contact} />
          <Route exact path="/Contact Us/:token" component={Contact} />
          <Route exact path="/Product Listing" component={Product} />
          <Route exact path="/Product Listing/:token" component={Product} />
          <Route exact path="/product-overview" component={ProductOverview} />
          <Route exact path="/maintenance" component={Maintenance} />
        </Switch>
      </div>
    </Router>
  );
};
export default App;
