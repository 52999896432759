import API from "./index";

// const get = "get";
// const create = "create";
// const update = "update";

const courseRoute = () => {
  return `eLearning/elearningcourse/getAllCourse`;
};
const categoryRoute = () => {
  return `eLearning/elearncategory/getAllCategory`;
};

export default {
  getAllCourses() {
    return API.post(courseRoute());
  },
  getAllCategory() {
    return API.post(categoryRoute());
  },
};
