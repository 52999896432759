import React, { useState } from "react";
import { Override } from "@quarkly/components";
import { Image, LinkBox, Text, Box, Icon, Section } from "@quarkly/widgets";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { NavLink } from "react-router-dom";

import { AiOutlineMenu } from "react-icons/ai";
import { getAllMenu } from "../redux/Menu/menu-actions";
import { useEffect } from "react";

const NavbarHeader = () => {
  // eslint-disable-next-line
  const [navbar, setnavbar] = useState(0);
  const [navbars, setnavbars] = useState(false);

  const dispatch = useDispatch();
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  let data = useSelector((state) => state.menu.menu);

  function getImages(value) {
    return value
      ? value.primaryLogo
        ? process.env.REACT_APP_STORAGE_URL_2 + brandProfile.primaryLogo
        : "https://nimbuscluster.blob.core.windows.net/server01/scv-engineering/cms/primaryLogo.jpg"
      : "https://nimbuscluster.blob.core.windows.net/server01/scv-engineering/cms/primaryLogo.jpg";
  }
  function setnavonclick() {
    if (navbars === true) {
      setnavbars(false);
    } else if (navbars === false) {
      setnavbars(true);
    }
  }
  useEffect(() => {
    dispatch(getAllMenu());
  }, [dispatch]);

  return (
    <Section
      display="flex"
      flex-direction="row"
      align-items="center"
      justify-content="center"
      padding="10px 0"
    >
      <Section padding="0px">
        <Override
          slot="SectionContent"
          margin="0px atuo 0px auto"
          display="flex"
          flex-direction="row"
          align-items="center"
          justify-content="space-between"
          width="100%"
        />
        <NavLink md-margin="0px 20px 0px 0px" to={"/Home"}>
          <Image
            src={getImages(brandProfile)}
            display="block"
            width="220px"
            height="90px"
            object-fit="contain"
            sm-width="140px"
            sm-height="70px"
            md-height="80px"
            id="7106691438"
            alt="imagee"
          />
        </NavLink>
        <Box display="flex" md-display="none">
          {data
            ? data.map((e, i) => {
                return (
                  <Text
                    padding="6px 0px 6px 0px"
                    margin="0px 38px 0px 0px"
                    font="500 16px --fontFamily-googlePoppins"
                    key={e._id}
                  >
                    <NavLink
                      to={`/${e.pageData[0].page}`}
                      onClick={() => setnavbar(i)}
                      className="clr-blue"
                      activeClassName="active"
                    >
                      {e.menuName}
                    </NavLink>
                  </Text>
                );
              })
            : "no-menu"}
          {/* <LinkBox margin="0px 38px 0px 0px" href="/about">
            <Text
              font="500 16px --fontFamily-googlePoppins"
              color="--grey"
              margin="0px 0px 0px 0px"
              children="About us"
              id="9436661054"
            />
          </LinkBox>
          <LinkBox margin="0px 38px 0px 0px" href="/product">
            <Text
              font="500 16px --fontFamily-googlePoppins"
              color="--grey"
              margin="0px 0px 0px 0px"
              children="Products"
              id="5141297461"
            />
          </LinkBox>
          <LinkBox margin="0px 38px 0px 0px" href="/service">
            <Text
              font="500 16px --fontFamily-googlePoppins"
              color="--grey"
              margin="0px 0px 0px 0px"
              children="Service"
              id="0930904677"
            />
          </LinkBox>
          <LinkBox href="/contact">
            <Text
              font="500 16px --fontFamily-googlePoppins"
              color="--grey"
              margin="0px 0px 0px 0px"
              children="Contact us"
              id="2628222298"
            />
          </LinkBox> */}
        </Box>
        {/* <Box
          display="flex"
          align-items="center"
          padding="10px 14px 10px 14px"
          height="50px"
          md-width="100%"
          md-margin="0px 20px 0px 0px"
          sm-display="none"
          background="#e1e1e1"
          width="257px"
        >
          <Icon category="io" icon={IoIosSearch} color="#b5b5b5" size="28px" />
          <Input
            display="block"
            placeholder-color="#b5b5b5"
            border-style="none"
            placeholder="Search"
            padding="6px 10px 6px 10px"
            background="#e1e1e1"
            font="normal 500 18px/26px --fontFamily-googlePoppins"
            placeholder-font="normal 500 18px/26px --fontFamily-googlePoppins"
            width="100%"
            id="2757090879"
          />
        </Box> */}

        <LinkBox
          display="none"
          md-display="block"
          onClick={() => setnavonclick(true)}
        >
          <Icon
            category="ai"
            icon={AiOutlineMenu}
            size="32px"
            color="#b5b5b5"
            sm-color="--primary"
          />
        </LinkBox>
      </Section>
      {navbars ? (
        <>
          <Box
            display="none"
            flex-direction="column"
            md-display="block"
            style={{ maxHeight: "200px" }}
            className="overflow-y-auto"
          >
            {data.map((e, i) => {
              return (
                <NavLink key={e._id} exact to={`/${e.pageData[0].page}`}>
                  <Text
                    padding="6px 0px 6px 0px"
                    margin="0px"
                    font="500 16px --fontFamily-googlePoppins"
                    color="--primary"
                    id="6694958132"
                  >
                    {e.menuName}
                  </Text>
                </NavLink>
              );
            })}
            {/* <LinkBox
          display="block"
          width="30%"
          margin-right="auto"
          padding="6px 0"
          href="/about"
        >
          <Text
            font="500 16px --fontFamily-googlePoppins"
            color="--grey"
            margin="0px 0px 0px 0px"
            children="About us"
            id="9436661054"
          />
        </LinkBox>
        <LinkBox
          display="block"
          width="30%"
          margin-right="auto"
          padding="6px 0"
          href="/product"
        >
          <Text
            font="500 16px --fontFamily-googlePoppins"
            color="--grey"
            margin="0px 0px 0px 0px"
            children="Products"
            id="5141297461"
          />
        </LinkBox>
        <LinkBox
          display="block"
          width="30%"
          margin-right="auto"
          padding="6px 0"
          href="/service"
        >
          <Text
            font="500 16px --fontFamily-googlePoppins"
            color="--grey"
            margin="0px 0px 0px 0px"
            children="Service"
            id="0930904677"
          />
        </LinkBox>
        <LinkBox
          display="block"
          width="30%"
          margin-right="auto"
          padding="6px 0"
          href="/contact"
        >
          <Text
            font="500 16px --fontFamily-googlePoppins"
            color="--grey"
            margin="0px 0px 0px 0px"
            children="Contact us"
            id="2628222298"
          />
        </LinkBox> */}
            {/* <Box
          display="flex"
          align-items="center"
          padding="10px 14px 10px 14px"
          height="50px"
          md-width="100%"
          md-margin="0px 20px 0px 0px"
          background="#e1e1e1"
          width="257px"
        >
          <Icon category="io" icon={IoIosSearch} color="#b5b5b5" size="28px" />
          <Input
            display="block"
            placeholder-color="#b5b5b5"
            border-style="none"
            placeholder="Search"
            padding="6px 10px 6px 10px"
            background="#e1e1e1"
            font="normal 500 18px/26px --fontFamily-googlePoppins"
            placeholder-font="normal 500 18px/26px --fontFamily-googlePoppins"
            width="100%"
            id="2757090879"
          />
        </Box> */}
          </Box>
        </>
      ) : null}
    </Section>
  );
};

export default NavbarHeader;
