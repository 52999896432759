import menu from "./menu";
import brandprofile from "./brandprofile";
import user from "./user";
import blogs from "./blogs";
import auth from "./auth";
import product from "./product";
import marcom from "./marcom";
import course from "./course";
import portfolio from "./portfolio";
export default {
  getmenu: menu,
  brandprofile: brandprofile,
  user: user,
  blogs,
  auth,
  product: product,
  marcom,
  course,
  portfolio,
};
