import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";
import "swiper/swiper.min.css";
import { Text } from "@quarkly/widgets";
const Brands = () => {
  return (
    <div className="container mx-auto lg:px-0 md:px-0 px-5">
      <Text
        margin="0px 0px 70px 0px"
        font="--headline2"
        color="--primary"
        text-align="center"
        sm-font="--headline4"
        sm-margin="0px 0px 30px 0px"
      >
        Our Valued Clientele
      </Text>

      <Swiper
        spaceBetween={20}
        observer={true}
        observeParents={true}
        className="mySwiper"
        modules={[Navigation, Autoplay]}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        navigation={{
          nextEl: ".image-swiper-button-nexts",
          prevEl: ".image-swiper-button-prevs",
        }}
        breakpoints={{
          1536: {
            slidesPerView: 4.1,
            spaceBetween: 20,
          },
          1280: {
            slidesPerView: 4.1,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 4.1,
            spaceBetween: 20,
          },
          991: {
            slidesPerView: 1,
            spaceBetween: 12,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          425: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          325: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
        }}
      >
        {/* <div className="swiper-button image-swiper-button-prevs  rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
            </svg>
          </div>
          <div className="swiper-button image-swiper-button-nexts  rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
            </svg>
          </div> */}
        <SwiperSlide>
          <div className="">
            <img
              src="https://nexuscluster.blob.core.windows.net/server01/scv-engineering/Testimonials/logo%201.png"
              alt="brands"
              className="object-contain h-24"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="">
            <img
              src="https://nexuscluster.blob.core.windows.net/server01/scv-engineering/Testimonials/logo%202.png"
              alt="brands"
              className="object-contain h-24"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="">
            <img
              src="https://nexuscluster.blob.core.windows.net/server01/scv-engineering/Testimonials/logo%203.png"
              alt="brands"
              className="object-contain h-24"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="">
            <img
              src="https://nexuscluster.blob.core.windows.net/server01/scv-engineering/Testimonials/logo%204.png"
              alt="brands"
              className="object-contain h-24"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="">
            <img
              src="https://nexuscluster.blob.core.windows.net/server01/scv-engineering/Testimonials/logo%205.png"
              alt="brands"
              className="object-contain h-24"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="">
            <img
              src="https://nexuscluster.blob.core.windows.net/server01/scv-engineering/Testimonials/logo%206.png"
              alt="brands"
              className="object-contain h-24"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="">
            <img
              src="https://nexuscluster.blob.core.windows.net/server01/scv-engineering/Testimonials/logo%207.png"
              alt="brands"
              className="object-contain h-24"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="">
            <img
              src="https://nexuscluster.blob.core.windows.net/server01/scv-engineering/Testimonials/logo%208.png"
              alt="brands"
              className="object-contain h-24"
            />
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Brands;
