import React, { useEffect, useState } from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Box, Button, Section } from "@quarkly/widgets";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { useParams } from "react-router-dom";
import { createContact } from "../redux/marcom/marcom-action";
//CMS
import ContentUpdate from "../components/CMS/ContentUpdate";
import { setSelectedData } from "../redux/CMS/cms-actions";
import Swal from "sweetalert2";
// import { Textarea } from "@quarkly/community-kit";

import { useFormik } from "formik";
import * as Yup from "yup";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const validationSchema = Yup.object({
  username: Yup.string()
    .trim()
    .matches(/^[A-Za-z]+(\s[A-Za-z]+)*$/, "Only alphabetic characters allowed")
    .min(2, "Full Name must be at least 2 character")
    .max(50, "Full Name must be 50 characters or less")
    .required("Full Name is required"),
  companyName: Yup.string()
    .trim()
    .matches(/^[^\s]+(\s[^\s]+)*[^\s]$/, "Invalid Format")
    .min(2, "Full Name must be at least 2 character")
    .max(50, "Full Name must be 50 characters or less")
    .required("Company Name is required"),
  phoneNumber: Yup.string()
    .matches(phoneRegExp, "Please enter valid mobile number")
    .required("Mobile number is required")
    .min(10, "Enter valid number")
    .max(10, "Enter valid number"),
  email: Yup.string().email("Not Valid Email").required("Email id is required"),
  message: Yup.string()
    .required("Message is required")
    .max(500, "Field cannot exceed 500 characters"),
});

const ErrorComponent = ({ error }) => (
  <div style={{ color: "red", marginTop: "5px" }}>{error}</div>
);

const defaultProps = {
  padding: "70px 0 80px 0",
  "sm-padding": "40px 0 40px 0",
};
const overrides = {
  text: {
    kind: "Text",
    props: {
      margin: "0px 0px 4px 0px",
      font: "--subheader1",
      color: "--darkGrey",
      "text-align": "center",
      "letter-spacing": "1px",
      "sm-font": "--description4",
      children: "Enquire",
      id: "2702983273",
    },
  },
  text1: {
    kind: "Text",
    props: {
      margin: "0px 0px 70px 0px",
      font: "--headline2",
      color: "--primary",
      "text-align": "center",
      "sm-font": "--headline4",
      children: "Enquire now and get best solution",
    },
  },
  box: {
    kind: "Box",
    props: {
      width: "70%",
      display: "flex",
      "flex-direction": "column",
      "sm-width": "100%",
      "md-width": "100%",
    },
  },
  box1: {
    kind: "Box",
    props: {
      display: "flex",
      margin: "0px 0px 20px 0px",
      "sm-flex-direction": "column",
    },
  },
  box2: {
    kind: "Box",
    props: {
      width: "50%",
      margin: "0px 22px 0px 0px",
      "sm-width": "100%",
      "sm-margin": "0px 22px 10px 0px",
    },
  },
  text2: {
    kind: "Text",
    props: {
      margin: "0px 0px 6px 0px",
      font: "--descirption3",
      color: "--darkL2",
      "text-align": "left",
      children: "Your name",
      id: "5565083155",
    },
  },
  input: {
    kind: "Input",
    props: {
      display: "block",
      "placeholder-color": "LightGray",
      background: "white",
      padding: "16px 16px 16px 16px",
      placeholder: "Enter",
      width: "100%",
      "border-color": "rgba(132, 132, 132, 0.86)",
      id: "9815271360",
    },
  },
  box3: {
    kind: "Box",
    props: {
      width: "50%",
      "sm-width": "100%",
    },
  },
  text3: {
    kind: "Text",
    props: {
      margin: "0px 0px 6px 0px",
      font: "--descirption3",
      color: "--darkL2",
      "text-align": "left",
      children: "Company Name",
      id: "8367236209",
    },
  },
  input1: {
    kind: "Input",
    props: {
      display: "block",
      "placeholder-color": "LightGray",
      background: "white",
      padding: "16px 16px 16px 16px",
      placeholder: "Enter",
      width: "100%",
      "border-color": "rgba(132, 132, 132, 0.86)",
      id: "7012993659",
    },
  },
  box4: {
    kind: "Box",
    props: {
      display: "flex",
      margin: "0px 0px 20px 0px",
      "sm-flex-direction": "column",
    },
  },
  box5: {
    kind: "Box",
    props: {
      width: "50%",
      margin: "0px 22px 0px 0px",
      "sm-margin": "0px 0px 10px 0px",
      "sm-width": "100%",
    },
  },
  text4: {
    kind: "Text",
    props: {
      margin: "0px 0px 6px 0px",
      font: "--descirption3",
      color: "--darkL2",
      "text-align": "left",
      children: "Phone number",
      id: "4044250921",
    },
  },
  input2: {
    kind: "Input",
    props: {
      display: "block",
      "placeholder-color": "LightGray",
      background: "white",
      padding: "16px 16px 16px 16px",
      placeholder: "Enter",
      width: "100%",
      "border-color": "rgba(132, 132, 132, 0.86)",
      id: "3031210094",
    },
  },
  box6: {
    kind: "Box",
    props: {
      width: "50%",
      "sm-width": "100%",
    },
  },
  text5: {
    kind: "Text",
    props: {
      margin: "0px 0px 6px 0px",
      font: "--descirption3",
      color: "--darkL2",
      "text-align": "left",
      children: "Email",
      id: "2011993242",
    },
  },
  input3: {
    kind: "Input",
    props: {
      display: "block",
      "placeholder-color": "LightGray",
      background: "white",
      padding: "16px 16px 16px 16px",
      placeholder: "Enter",
      width: "100%",
      "border-color": "rgba(132, 132, 132, 0.86)",
      id: "6476093320",
    },
  },
  box7: {
    kind: "Box",
    props: {
      width: "100%",
      display: "flex",
      "flex-direction": "column",
      margin: "0px 0px 20px 0px",
    },
  },
  text6: {
    kind: "Text",
    props: {
      margin: "0px 0px 6px 0px",
      font: "--descirption3",
      color: "--darkL2",
      "text-align": "left",
      children: "Message",
      id: "1545735510",
    },
  },
  input4: {
    kind: "Input",
    props: {
      display: "flex",
      "placeholder-color": "LightGray",
      background: "white",
      padding: "16px 16px 16px 16px",
      placeholder: "Enter",
      width: "100%",
      height: "90px",
      "align-items": "flex-start",
      "placeholder-justify-content": "flex-start",
      "placeholder-align-items": "flex-start",
      "sm-padding": "0px 16px 0px 16px",
      "sm-height": "180px",
      "sm-justify-content": "flex-start",
      "border-color": "rgba(132, 132, 132, 0.86)",
      id: "2086567386",
    },
  },
  box8: {
    kind: "Box",
    props: {
      "align-items": "center",
      display: "flex",
      "justify-content": "center",
    },
  },
  button: {
    kind: "Button",
    props: {
      font: "--button1",
      color: "--white",
      background: "--color-primary",
      "border-radius": "0px",
      margin: "0px 24px 0px 0px",
      padding: "12px 24px 12px 24px",
      id: "5262671810",
    },
  },
};

const EnquireForm = (props) => {
  const formik = useFormik({
    initialValues: {
      username: "",
      companyName: "",
      phoneNumber: "",
      email: "",
      message: "",
    },
    validationSchema: validationSchema,
  });

  const dispatch = useDispatch();
  // eslint-disable-next-line
  const [mounted, setMounted] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [selectedData, updateSelectedData] = useState("update");
  let data = useSelector((state) => state.cms.pages["Home"]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    let payload = {
      name: formik.values.username.trim(),
      mobile: formik.values.phoneNumber.trim(),
      email: formik.values.email.trim(),
      company: formik.values.companyName.trim(),
      description: formik.values.message.trim(),
    };

    if (
      payload.name !== "" &&
      payload.company !== "" &&
      payload.mobile !== "" &&
      payload.email !== "" &&
      payload.description !== "" &&
      Object.keys(formik.errors).length === 0
    ) {
      let data = await dispatch(createContact(payload));
      console.log(data, "contact");

      if (data.code === 200) {
        formik.resetForm();
        Swal.fire({
          title: "Congratulations",
          text: "Your query been successfully sent.",
          icon: "success",
        });
      }
    } else {
      Swal.fire({
        title: "Error",
        text: "Please fill all the required details",
        icon: "error",
      });
      console.log(data.message, "datamessage");
    }
  };

  const { token } = useParams();

  if (token) {
    let cmsStyle = document.getElementsByClassName("hoverText");

    for (let i = 0; i < cmsStyle.length; i++) {
      cmsStyle[i].onClick = function () {
        return false;
      };
      cmsStyle[i].classList.add("hoverText1");
    }
  }

  async function isSideBarOpen(id) {
    if (token) {
      await updateSelectedData(id);
      // selectedData = id;
      setIsShow(true);

      console.log(isShow, selectedData, id, "show");
    }
  }

  function closeSidebar(value) {
    setIsShow(value);
    updateSelectedData("");
    // selectedData = "";
    dispatch(setSelectedData({}));
    console.log(selectedData, "show");
  }

  useEffect(() => {
    setMounted(true);
  }, [dispatch, setMounted]);
  const { children, rest } = useOverrides(props, overrides, defaultProps);
  return (
    <Section {...rest}>
      <Override
        slot="SectionContent"
        margin="0px auto 0px auto"
        align-items="center"
      />
      <Text
        margin="0px 0px 4px 0px"
        font="--subheader1"
        color="--darkGrey"
        text-align="center"
        letter-spacing="1px"
        sm-font="--description2"
        id="5596715112"
        className="hoverText"
        onClick={() => isSideBarOpen("5596715112")}
      >
        {data ? data["5596715112"] : "Enquire"}
      </Text>
      <Text
        margin="0px 0px 70px 0px"
        sm-margin="0px 0px 40px 0px"
        font="--headline2"
        color="--primary"
        text-align="center"
        id="6313589439"
        sm-font="--headline5"
        className="hoverText"
        dangerouslySetInnerHTML={{
          __html: data
            ? data["7717973199"]
            : "Enquire now and get best solution",
        }}
        onClick={() => isSideBarOpen("6313589439")}
      ></Text>
      <Box
        width="70%"
        display="flex"
        flex-direction="column"
        sm-width="100%"
        md-width="100%"
      >
        <Box
          display="flex"
          margin="0px 0px 20px 0px"
          sm-flex-direction="column"
        >
          <Box
            width="50%"
            margin="0px 22px 0px 0px"
            sm-width="100%"
            sm-margin="0px 22px 10px 0px"
          >
            <Text
              margin="0px 0px 6px 0px"
              font="--descirption3"
              color="--darkL2"
              text-align="left"
              className="hoverText"
              id="5565083155"
              onClick={() => isSideBarOpen("5565083155")}
            >
              {data ? data["5565083155"] : "Your name"}
            </Text>
            <input
              className="inputs"
              name="username"
              id="username"
              required
              value={formik.values.username}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Enter Full Name"
            />
            {formik.touched.username && formik.errors.username && (
              <ErrorComponent error={formik.errors.username} />
            )}
          </Box>
          <Box width="50%" sm-width="100%">
            <Text
              margin="0px 0px 6px 0px"
              font="--descirption3"
              color="--darkL2"
              text-align="left"
              className="hoverText"
              id="8367236209"
              onClick={() => isSideBarOpen("8367236209")}
            >
              {data ? data["8367236209"] : "Company Name"}
            </Text>
            <input
              className="inputs"
              name="companyName"
              id="companyName"
              required
              value={formik.values.companyName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Enter Company Name"
            />
            {formik.touched.companyName && formik.errors.companyName && (
              <ErrorComponent error={formik.errors.companyName} />
            )}
          </Box>
        </Box>
        <Box
          display="flex"
          margin="0px 0px 20px 0px"
          sm-flex-direction="column"
        >
          <Box
            width="50%"
            margin="0px 22px 0px 0px"
            sm-margin="0px 0px 10px 0px"
            sm-width="100%"
          >
            <Text
              margin="0px 0px 6px 0px"
              font="--descirption3"
              color="--darkL2"
              text-align="left"
              className="hoverText"
              id="4044250921"
              onClick={() => isSideBarOpen("4044250921")}
            >
              {data ? data["4044250921"] : "Phone number"}
            </Text>
            <input
              className="inputs"
              required
              placeholder="Enter Phone number"
              type="text"
              id="phoneNumber"
              name="phoneNumber"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phoneNumber}
            />
            {formik.touched.phoneNumber && formik.errors.phoneNumber && (
              <ErrorComponent error={formik.errors.phoneNumber} />
            )}
          </Box>
          <Box width="50%" sm-width="100%">
            <Text
              margin="0px 0px 6px 0px"
              font="--descirption3"
              color="--darkL2"
              text-align="left"
              id="2011993242"
              className="hoverText"
              onClick={() => isSideBarOpen("2011993242")}
            >
              {data ? data["2011993242"] : "Email"}
            </Text>
            <input
              className="inputs"
              placeholder="Enter Email ID"
              required
              type="email"
              id="email"
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />

            {formik.touched.email && formik.errors.email && (
              <ErrorComponent error={formik.errors.email} />
            )}
          </Box>
        </Box>
        <Box
          width="100%"
          display="flex"
          flex-direction="column"
          margin="0px 0px 20px 0px"
        >
          <Text
            margin="0px 0px 6px 0px"
            font="--descirption3"
            color="--darkL2"
            text-align="left"
            className="hoverText"
            id="1545735510"
            onClick={() => isSideBarOpen("1545735510")}
          >
            {data ? data["1545735510"] : "Message"}
          </Text>
          <input
            className="inputs textarea"
            placeholder="Enter Message"
            required
            type="message"
            id="message"
            name="message"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.message}
          />
          {formik.touched.message && formik.errors.message && (
            <ErrorComponent error={formik.errors.message} />
          )}
        </Box>
        <Box align-items="center" display="flex" justify-content="center">
          <Button
            font="--button1"
            color="--white"
            background="--color-primary"
            border-radius="0px"
            margin="0px 24px 0px 0px"
            padding="12px 24px 12px 24px"
            id="5262671810"
            onClick={handleSubmit}
            // onClick={() => isSideBarOpen("5262671810")}
          >
            {data ? data["5262671810"] : "Submit"}
          </Button>
        </Box>
      </Box>
      {children}
      {isShow ? (
        <ContentUpdate
          selectedElement={selectedData}
          isShow={isShow}
          closeSidebar={closeSidebar}
        />
      ) : (
        ""
      )}
    </Section>
  );
};

Object.assign(EnquireForm, { ...Section, defaultProps, overrides });
export default EnquireForm;
