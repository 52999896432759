import apis from "../../apis/portfolio";
export const getAllPortfolio = (payload) => {
  return async (dispatch) => {
    try {
      let payload2 = {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
        isDeleted: false,
      };
      //   let { data } = await apis.user.getProject(payload);
      let { data } = await apis.getAllPortfolio(payload2);

      if (data) dispatch(setPortfolio(data.data.data));
    } catch (error) {
      console.log(error);
    }
  };
};

export const setPortfolio = (data) => {
  return {
    type: "setPortfolio",
    payload: data,
  };
};

export const addSingleProduct = (data) => {
  return {
    type: "ADD_SINGLE_PORTFOLIO",
    payload: data,
  };
};
